/* eslint-disable */
import * as Yup from 'yup';
import {
  formattedCreateShippingApiReq,
  formattedUpdateShippingApiReq,
} from './selector';

// form validation
const ShippingBody = Yup.object().shape({
  CartValueFrom: Yup.string()
    .required('Cart Value From is required')
    .test(
      'CartValueFrom',
      'No special characters are allowed in Cart Value From',
      (CartValueFrom) => {
        const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        return !specialChars.test(CartValueFrom);
      }
    ),
  CartValueTo: Yup.string()
    .required('Cart Value To is required')
    .test(
      'CartValueTo',
      'No special characters are allowed in Cart Value To',
      (CartValueTo) => {
        const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        return !specialChars.test(CartValueTo);
      }
    ),
  ShippingCost: Yup.string()
    .required('Shipping Cost is required')
    .test(
      'ShippingCost',
      'No special characters are allowed in Shipping Cost',
      (ShippingCost) => {
        const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        return !specialChars.test(ShippingCost);
      }
    ),
  PaymentMethod: Yup.string().required('PaymentMethod is required'),
});

export const validateAndGetFormattedUpdateShippingParams = async (obj) => {
  await ShippingBody.validate(obj);
  return formattedUpdateShippingApiReq(obj);
};
export const validateAndGetFormattedCreateShippingParams = async (obj) => {
  await ShippingBody.validate(obj);
  return formattedCreateShippingApiReq(obj);
};
