import {
  GET_COLLECTIONS,
  GET_COLLECTIONS_ERROR,
  GET_COLLECTIONS_SUCCESS,
  GET_UPLOADED_FILES,
  GET_UPLOADED_FILES_ERROR,
  GET_UPLOADED_FILES_SUCCESS,
  SET_CONFIRM_MODAL_FIELDS,
  SET_UPLOAD_MODAL_FIELDS,
  UPLOAD_IMAGE,
  UPLOAD_IMAGE_ERROR,
  UPLOAD_IMAGE_SUCCESS,
} from 'redux/actions';

export const setConfirmModalFields = (payload) => ({
  type: SET_CONFIRM_MODAL_FIELDS,
  payload,
});

export const uploadImage = (payload) => ({
  type: UPLOAD_IMAGE,
  payload,
});

export const uploadImageSuccess = (payload) => ({
  type: UPLOAD_IMAGE_SUCCESS,
  payload,
});

export const uploadImageError = (payload) => ({
  type: UPLOAD_IMAGE_ERROR,
  payload,
});

export const getUploadedFiles = () => ({
  type: GET_UPLOADED_FILES,
});

export const getUploadedFilesSuccess = (payload) => ({
  type: GET_UPLOADED_FILES_SUCCESS,
  payload,
});

export const getUploadedFilesError = (payload) => ({
  type: GET_UPLOADED_FILES_ERROR,
  payload,
});

export const getCollections = () => ({
  type: GET_COLLECTIONS,
});

export const getCollectionsSuccess = (payload) => ({
  type: GET_COLLECTIONS_SUCCESS,
  payload,
});

export const getCollectionsError = (payload) => ({
  type: GET_COLLECTIONS_ERROR,
  payload,
});

export const setUploadModalFields = (payload) => ({
  type: SET_UPLOAD_MODAL_FIELDS,
  payload,
});
