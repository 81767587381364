import {
  CREATE_USER,
  CREATE_USER_ERROR,
  CREATE_USER_SUCCESS,
  DELETE_USER,
  DELETE_USER_ERROR,
  DELETE_USER_SUCCESS,
  SET_NEW_USER_FIELD,
  SET_USER_FIELD,
  SET_USER_MODAL,
  UPDATE_USER,
  UPDATE_USER_ERROR,
  UPDATE_USER_SUCCESS,
  USERS_GET_LIST,
  USERS_GET_LIST_ERROR,
  USERS_GET_LIST_SUCCESS,
} from 'redux/actions';

// USERS_GET_LIST
export const getUsersList = () => ({
  type: USERS_GET_LIST,
});

export const getUsersListSuccess = (payload) => ({
  type: USERS_GET_LIST_SUCCESS,
  payload,
});

export const getUsersListError = (payload) => ({
  type: USERS_GET_LIST_ERROR,
  payload,
});

// SET_USER_MODAL
export const setUserModal = (payload) => ({
  type: SET_USER_MODAL,
  payload,
});

export const setNewUserField = (payload) => ({
  type: SET_NEW_USER_FIELD,
  payload,
});

// CREATE_USER
export const createUser = (payload) => ({
  type: CREATE_USER,
  payload,
});

export const createUserSuccess = (payload) => ({
  type: CREATE_USER_SUCCESS,
  payload,
});

export const createUserError = (payload) => ({
  type: CREATE_USER_ERROR,
  payload,
});

// DELETE_USER
export const deleteUser = (payload) => ({
  type: DELETE_USER,
  payload,
});

export const deleteUserSuccess = (payload) => ({
  type: DELETE_USER_SUCCESS,
  payload,
});

export const deleteUserError = (payload) => ({
  type: DELETE_USER_ERROR,
  payload,
});

// UPDATE_USER
export const updateUser = (payload) => ({
  type: UPDATE_USER,
  payload,
});

export const updateUserSuccess = (payload) => ({
  type: UPDATE_USER_SUCCESS,
  payload,
});

export const updateUserError = (payload) => ({
  type: UPDATE_USER_ERROR,
  payload,
});

// SET_USER_FIELD
export const setUserField = (payload) => ({
  type: SET_USER_FIELD,
  payload,
});
