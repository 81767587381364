import * as Yup from 'yup';
import { getFormattedDogUpdateParams } from './selector';

const testimonialValidateParamsBody = Yup.object().shape({
  title: Yup.string()
    .required('Title is required')
    .test(
      'title',
      'Title must be less then 50 character',
      (title) => title.length <= 50
    ),
  //   subTitle: Yup.string()
  //     .required('SubTitle is required')
  //     .test(
  //       'subTitle',
  //       'Title must be less then 50 character',
  //       (title) => title.length <= 50
  //     ),
  links: Yup.array().of(
    Yup.object().shape({
      title: Yup.string().required('Title is required'),
      // .test(
      //   'title',
      //   'Title must be less then 50 character',
      //   (title) => title.length <= 50
      // )
      description: Yup.string().required('Description is reuqired'),
    })
  ),
});

// eslint-disable-next-line import/prefer-default-export
export const validateAndGetFormattedDogUpdateParams = async (obj) => {
  switch (obj.dataKey) {
    case 'RewardFAQ':
      await testimonialValidateParamsBody.validate(obj.data);
      break;

    default:
      break;
  }
  return getFormattedDogUpdateParams(obj);
};
