/* eslint-disable */
import * as Yup from 'yup';
import {
  formattedCreateUserApiReq,
  formattedUpdateUserApiReq,
} from './selector';

// form validation
const createUserBody = Yup.object().shape({
  title: Yup.string()
    .required('Title is required')
    .test('Title', 'No special characters are allowed in Title', (Title) => {
      const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
      return !specialChars.test(Title);
    }),
});

const updateUserBody = Yup.object().shape({
  title: Yup.string()
    .required('Title is required')
    .test('Title', 'No special characters are allowed in Title', (Title) => {
      const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
      return !specialChars.test(Title);
    }),
});

export const validateAndGetFormattedCreateUserParams = async (obj) => {
  await createUserBody.validate(obj);
  return formattedCreateUserApiReq(obj);
};

export const validateAndGetFormattedUpdateUserParams = async (obj) => {
  await updateUserBody.validate(obj);
  return formattedUpdateUserApiReq(obj);
};
