import {
  GET_DOG_TREAT,
  GET_DOG_TREAT_ERROR,
  GET_DOG_TREAT_SUCCESS,
  UPDATE_DOG_TREAT,
  UPDATE_DOG_TREAT_ERROR,
  UPDATE_DOG_TREAT_SUCCESS,
  PUBLISH_DOG_TREAT,
  PUBLISH_DOG_TREAT_ERROR,
  PUBLISH_DOG_TREAT_SUCCESS,
  PUBLISH_DOG_TREAT_REQUEST,
} from 'redux/actions';

// GET_DOG_TREAT
export const getDogTreat = () => ({
  type: GET_DOG_TREAT,
});

export const getDogTreatError = (payload) => ({
  type: GET_DOG_TREAT_ERROR,
  payload,
});

export const getDogTreatSuccess = (payload) => ({
  type: GET_DOG_TREAT_SUCCESS,
  payload,
});

// UPDATE_DOG_TREAT

export const updateDogTreat = (payload) => ({
  type: UPDATE_DOG_TREAT,
  payload,
});

export const updateDogTreatSuccess = (payload) => ({
  type: UPDATE_DOG_TREAT_SUCCESS,
  payload,
});

export const updateDogTreatError = (payload) => ({
  type: UPDATE_DOG_TREAT_ERROR,
  payload,
});

// PUBLISH_DOG_TREAT

export const publishDogTreat = (payload) => ({
  type: PUBLISH_DOG_TREAT,
  payload,
});

export const publishDogTreatRequest = (payload) => ({
  type: PUBLISH_DOG_TREAT_REQUEST,
  payload,
});

export const publishDogTreatSuccess = (payload) => ({
  type: PUBLISH_DOG_TREAT_SUCCESS,
  payload,
});

export const publishDogTreatError = (payload) => ({
  type: PUBLISH_DOG_TREAT_ERROR,
  payload,
});
