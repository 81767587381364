import * as Yup from 'yup';
import { getFormattedOffersUpdateParams } from './selector';

// form validation
const linkValidateParamsBody = Yup.object().shape({
  title: Yup.string()
    .required('Title is required')
    .test(
      'title',
      'Title must be less then 50 character',
      (title) => title.length <= 50
    ),
  value: Yup.string()
    .required('Value is required')
    .test(
      'value',
      'Value must be less the 50 character',
      (value) => value.length <= 50
    ),
  objectType: Yup.string().required('Object type is reuqired'),
  imageData: Yup.object()
    .required('Image cannot be empty')
    .test('imageData', 'Image must be there', (imageData) => !!imageData.id),
});

const HuftClearanceValidateParamsBody = Yup.object().shape({
  value: Yup.string()
    .required('Value is required')
    .test(
      'value',
      'Value must be less the 50 character',
      (value) => value.length <= 50
    ),
  objectType: Yup.string().required('Object type is reuqired'),
  imageData: Yup.object()
    .required('Image cannot be empty')
    .test('imageData', 'Image must be there', (imageData) => !!imageData.id),
});

const swipeValidateParamsBody = Yup.object().shape({
  title: Yup.string()
    .required('Title is required')
    .test(
      'title',
      'Title must be less then 50 character',
      (title) => title.length <= 50
    ),
  subTitle: Yup.string()
    .required('SubTitle is required')
    .test(
      'subTitle',
      'Title must be less then 50 character',
      (title) => title.length <= 50
    ),
  links: Yup.array().of(
    Yup.object().shape({
      title: Yup.string()
        .required('Title is required')
        .test(
          'title',
          'Title must be less then 50 character',
          (title) => title.length <= 50
        ),
      value: Yup.string()
        .required('Value is required')
        .test(
          'value',
          'Value must be less the 50 character',
          (value) => value.length <= 50
        ),
      objectType: Yup.string().required('Object type is reuqired'),
      imageData: Yup.object()
        .required('Image cannot be empty')
        .test(
          'imageData',
          'Image must be there',
          (imageData) => !!imageData?.id
        ),
    })
  ),
});

const swipeValidateBankOfferParamsBody = Yup.object().shape({
  links: Yup.array().of(
    Yup.object().shape({
      title: Yup.string()
        .required('Title is required')
        .test(
          'title',
          'Title must be less then 50 character',
          (title) => title.length <= 50
        ),
      value: Yup.string()
        .required('Value is required')
        .test(
          'value',
          'Value must be less the 50 character',
          (value) => value.length <= 50
        ),
      objectType: Yup.string().required('Object type is reuqired'),
      imageData: Yup.object()
        .required('Image cannot be empty')
        .test(
          'imageData',
          'Image must be there',
          (imageData) => !!imageData?.id
        ),
    })
  ),
});

const swipeValidateExclusiveParamsBody = Yup.object().shape({
  title: Yup.string()
    .required('Title is required')
    .test(
      'title',
      'Title must be less then 50 character',
      (title) => title.length <= 50
    ),
  subTitle: Yup.string()
    .required('SubTitle is required')
    .test(
      'subTitle',
      'Title must be less then 50 character',
      (title) => title.length <= 50
    ),
  links: Yup.array().of(
    Yup.object().shape({
      title: Yup.string()
        .required('Title is required')
        .test(
          'title',
          'Title must be less then 50 character',
          (title) => title.length <= 50
        ),
      value: Yup.string()
        .required('Value is required')
        .test(
          'value',
          'Value must be less the 50 character',
          (value) => value.length <= 50
        ),
      objectType: Yup.string().required('Object type is reuqired'),
      imageData: Yup.object()
        .required('Image cannot be empty')
        .test(
          'imageData',
          'Image must be there',
          (imageData) => !!imageData?.id
        ),
      subTitle: Yup.string()
        .required('SubTitle is required')
        .test(
          'subTitle',
          'Title must be less then 50 character',
          (title) => title.length <= 50
        ),
    })
  ),
});

// eslint-disable-next-line import/prefer-default-export
export const validateAndGetFormattedOfferUpdateParams = async (obj) => {
  switch (obj.dataKey) {
    case 'popularCategories':
      await swipeValidateParamsBody.validate(obj.data);
      break;

    case 'featuredBrands':
      await swipeValidateParamsBody.validate(obj.data);
      break;

    case 'exclusivelyForYou':
      await swipeValidateExclusiveParamsBody.validate(obj.data);
      break;

    case 'dealOfTheDay':
      await linkValidateParamsBody.validate(obj.data);
      break;

    case 'banner':
      await linkValidateParamsBody.validate(obj.data);
      break;

    case 'bankOffers':
      await swipeValidateBankOfferParamsBody.validate(obj.data);
      break;

    case 'huftClearance':
      await HuftClearanceValidateParamsBody.validate(obj.data);
      break;

    default:
      break;
  }
  const ans = getFormattedOffersUpdateParams(obj);
  return ans;
};
