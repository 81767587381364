// get api params
export const getSmallAnimalParams = () => ({
  params: {
    populate: 'Fish,Fish.Image,Birds,Birds.Image,Rabbits,Rabbits.Image',
  },
});

// format get api data
export const formatSmallAnimalData = (data) => ({
  vuid: {
    vuid: data.vuid,
  },
  birds: {
    id: data.Birds && data.Birds.id,
    title: data.Birds && data.Birds.Title,
    subTitle: data.Birds && data.Birds.SubTitle,
    value: data.Birds && data.Birds.Value,
    objectType: data.Birds && data.Birds.ObjectType,
    imageData: data.Birds && data.Birds.Image.data,
  },
  fishes: {
    id: data.Fish && data.Fish.id,
    title: data.Fish && data.Fish.Title,
    subTitle: data.Fish && data.Fish.SubTitle,
    value: data.Fish && data.Fish.Value,
    objectType: data.Fish && data.Fish.ObjectType,
    imageData: data.Fish && data.Fish.Image.data,
  },
  rabbits: {
    id: data.Rabbits && data.Rabbits.id,
    title: data.Rabbits && data.Rabbits.Title,
    subTitle: data.Rabbits && data.Rabbits.SubTitle,
    value: data.Rabbits && data.Rabbits.Value,
    objectType: data.Rabbits && data.Rabbits.ObjectType,
    imageData: data.Rabbits && data.Rabbits.Image.data,
  },
});

export const getFormattedSmallAnimalUpdateParams = ({ data, dataKey }) => {
  switch (dataKey) {
    case 'birds':
      return {
        Birds: {
          Id: data.id,
          Title: data.title,
          SubTitle: data.subTitle,
          Value: data.value,
          ObjectType: data.objectType,
          Image: data.imageData,
        },
      };

    case 'fishes':
      return {
        Fish: {
          Id: data.id,
          Title: data.title,
          SubTitle: data.subTitle,
          Value: data.value,
          ObjectType: data.objectType,
          Image: data.imageData,
        },
      };

    case 'rabbits':
      return {
        Rabbits: {
          Id: data.id,
          Title: data.title,
          SubTitle: data.subTitle,
          Value: data.value,
          ObjectType: data.objectType,
          Image: data.imageData,
        },
      };

    default:
      return null;
  }
};
