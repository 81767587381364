import { NotificationManager } from 'components/common/react-notifications';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import configuredAxios, {
  accessRefreshToken,
  getAuthorizationHeader,
} from 'utils/Api';
import axios from 'utils/CmsApi';
import {
  GET_CAT_DATA,
  PUBLISH_CAT_DATA,
  SAVE_CAT_VERSION,
  UPDATE_CAT_DATA,
} from '../actions';
import {
  getCatDataError,
  getCatDataSuccess,
  publishCatDataError,
  publishCatDataSuccess,
  publishCatRequest,
  updateCatDataError,
  updateCatDataSuccess,
} from './action';
import { formatCatData, getCatParams } from './selector';
import { validateAndGetFormattedCatUpdateParams } from './validator';

/**
 * Calls the API to getCatListRequest request.
 *
 * @async
 * @function
 * @returns {Promise} The response from the API call.
 */

export const getCatListRequest = async () => {
  accessRefreshToken();
  const response = await axios.get('/home-page-cat', getCatParams());
  return response;
};

/**
 * Calls the API to updateCatDataRequest request.
 *
 * @async
 * @function
 * @returns {Promise} The response from the API call.
 */
const updateCatDataRequest = async (data) => {
  accessRefreshToken();
  const body = await validateAndGetFormattedCatUpdateParams(data);
  const response = await axios.put('/home-page-cat', {
    data: body,
  });
  return response;
};

// add cat version
const addCatVersionRequest = async (data) => {
  accessRefreshToken();
  const response = await axios.post('/content-versioning/home-page-cat/save', {
    data,
  });
  return response;
};

/**
 * Calls the API to publishCatDataRequest request.
 *
 * @async
 * @function
 * @returns {Promise} The response from the API call.
 */
const publishCatDataRequest = async (data) => {
  const response = await configuredAxios.post('/pet/homePageCat', data, {
    headers: {
      Authorization: getAuthorizationHeader(),
    },
  });
  return response;
};

/**
 * Calls the API to fetchCatData request.
 *
 * @generator
 * @function
 * @returns {Promise} The response from the API call.
 */
function* fetchCatData() {
  try {
    const response = yield call(getCatListRequest);
    if (response?.data?.data) {
      const attributes = response?.data?.data?.attributes;
      yield put(getCatDataSuccess(formatCatData(attributes)));
    } else {
      yield put(getCatDataError('no data received'));
    }
  } catch (error) {
    yield put(getCatDataError(error));
  }
}

// add cat version
function* addCatVersionData(data) {
  try {
    yield call(addCatVersionRequest, data.payload);
    // TODO
  } catch (error) {
    // TODO
  }
}

/**
 * Calls the API to updateCatData request.
 *
 * @generator
 * @function
 * @returns {Promise} The response from the API call.
 */
function* updateCatData(data) {
  try {
    const response = yield call(updateCatDataRequest, data.payload);
    if (response) {
      NotificationManager.success('Data updated successfully', 'Data Updated');
      yield put(updateCatDataSuccess(response));
    } else {
      NotificationManager.error('Failed to update data', 'Data Updated');
      yield put(updateCatDataError('no response received'));
    }
  } catch (error) {
    if (error.name === 'ValidationError') {
      NotificationManager.error(error.message, 'Data Updated');
    } else {
      NotificationManager.error('Failed to update data', 'Data Updated');
    }
    yield put(updateCatDataError(error));
  }
}

/**
 * Calls the API to  publishCatData request.
 *
 * @generator
 * @function
 * @returns {Promise} The response from the API call.
 */
function* publishCatData(data) {
  yield put(publishCatRequest());
  try {
    const response = yield call(publishCatDataRequest, data.payload);
    if (response) {
      NotificationManager.success(
        'Data Published successfully',
        'Data Published'
      );
      yield put(publishCatDataSuccess(response));
    } else {
      NotificationManager.error('Failed to Publish data', 'Data Published');
      yield put(publishCatDataError('no response received'));
    }
  } catch (error) {
    if (error.name === 'ValidationError') {
      NotificationManager.error(error.message, 'Data Updated');
    } else {
      NotificationManager.error('Failed to Published data', 'Data Published');
    }
    yield put(publishCatDataError(error));
  }
}
export function* watchCatData() {
  yield takeLatest(GET_CAT_DATA, fetchCatData);
}

export function* watchUpdateCatData() {
  yield takeLatest(UPDATE_CAT_DATA, updateCatData);
}

export function* watchPublishCatData() {
  yield takeLatest(PUBLISH_CAT_DATA, publishCatData);
}

export function* watchAddCatVersion() {
  yield takeLatest(SAVE_CAT_VERSION, addCatVersionData);
}

/**
 * Root Saga that combines all sagas.
 *
 * @generator
 * @function
 */

export default function* rootSaga() {
  yield all([
    fork(watchCatData),
    fork(watchUpdateCatData),
    fork(watchAddCatVersion),
    fork(watchPublishCatData),
  ]);
}
