import moment from 'moment';

export const formatDate = (date, formatStyle) => {
  return moment(new Date(date)).format(formatStyle);
};

export const isTokenTimeExpired = (date) => {
  const dateTime = moment(date);
  const currentDate = moment();
  return dateTime.diff(currentDate, 'seconds') < 300;
};
