// get api params
export const getCatParams = () => ({
  params: {
    populate:
      'SingleVerticleBanner.Link,SingleVerticleBanner.Link.Image,CarouselBannerImages,CarouselBannerImages.Link,CarouselBannerImages.Link.Image,SwipeSectionImages.Link,SwipeSectionImages.Link.Image,DealsOfDay,DealsOfDay.Link,DealsOfDay.Link.Image,Subscriptions,Subscriptions.Link,Subscriptions.Link.Image,BannerScrollSection,BannerScrollSection.Link,BannerScrollSection.Link.Image,BankOffers,BankOffers.Link,BankOffers.Link.Image,SpaBookingBanner,SpaBookingBanner.Link,SpaBookingBanner.Link.Image,BrandCarousel,BrandCarousel.Link,BrandCarousel.Link.Image,CombosAndKits,CombosAndKits.Link,CombosAndKits.Link.Image,EcoConscious,EcoConscious.Link,EcoConscious.Link.Image,LocateStore,LocateStore.Link,LocateStore.Link.Image,FoundationBanner,FoundationBanner.Link,FoundationBanner.Link.Image,ShopByConcern,ShopByConcern.Link,ShopByConcern.Link.Image,BestFoodBrands,BestFoodBrands.Link,BestFoodBrands.Link.Image,JoinPuppyClub,JoinPuppyClub.Image,KnowAboutYourPet,KnowAboutYourPet.Link,KnowAboutYourPet.Link.Image,PetBirthday,PetBirthday.Image,GiftCards,GiftCards.Link,GiftCards.Link.Image,testimonial.Testimonial,TopPicks.TopPickComponent',
  },
});

// format get api response
export const formatCatData = (data) => {
  const versionData = {
    ...data,
    versionNumber: 16,
    vuid: '581e26a0-ef39-4374-978e-dbd566386765',
  };
  delete versionData.createdAt;
  delete versionData.updatedAt;
  delete versionData.publishedAt;
  return {
    versionData,
    vuid: {
      vuid: data?.vuid,
    },
    singleVerticleBanner:
      data?.SingleVerticleBanner?.Link !== null
        ? {
            id:
              data?.SingleVerticleBanner &&
              data?.SingleVerticleBanner?.Link?.id,
            title:
              data?.SingleVerticleBanner &&
              data?.SingleVerticleBanner?.Link?.Title,
            value:
              data?.SingleVerticleBanner &&
              data?.SingleVerticleBanner?.Link?.Value,
            objectType:
              data?.SingleVerticleBanner &&
              data?.SingleVerticleBanner?.Link?.ObjectType,
            imageData:
              data?.SingleVerticleBanner &&
              data?.SingleVerticleBanner?.Link?.Image?.data,
            ImageHeight:
              data?.SingleVerticleBanner &&
              data?.SingleVerticleBanner.ImageHeight,
            ImageWidth:
              data?.SingleVerticleBanner &&
              data?.SingleVerticleBanner.ImageWidth,
          }
        : {},
    carouselBannerImages:
      data?.CarouselBannerImages !== null
        ? {
            id: data?.CarouselBannerImages && data?.CarouselBannerImages?.id,
            title:
              data?.CarouselBannerImages && data?.CarouselBannerImages?.Title,
            ImageHeight:
              data?.CarouselBannerImages &&
              data?.CarouselBannerImages.ImageHeight,
            ImageWidth:
              data?.CarouselBannerImages &&
              data?.CarouselBannerImages.ImageWidth,
            subTitle:
              data?.CarouselBannerImages &&
              data?.CarouselBannerImages?.SubTitle,
            links:
              data?.CarouselBannerImages &&
              data?.CarouselBannerImages?.Link?.map((lin) => ({
                imageData: lin?.Image?.data,
                objectType: lin?.ObjectType,
                title: lin?.Title,
                value: lin?.Value,
                id: lin?.id,
              })),
          }
        : {},
    swipeSectionImages:
      data?.SwipeSectionImages !== null
        ? {
            id: data?.SwipeSectionImages && data?.SwipeSectionImages?.id,
            title:
              data?.SwipeSectionImages &&
              data?.SwipeSectionImages?.SectionTitle,
            subTitle:
              data?.SwipeSectionImages &&
              data?.SwipeSectionImages?.SectionSubTitle,
            ImageHeight:
              data?.SwipeSectionImages && data?.SwipeSectionImages.ImageHeight,
            ImageWidth:
              data?.SwipeSectionImages && data?.SwipeSectionImages.ImageWidth,
            links:
              data?.SwipeSectionImages &&
              data?.SwipeSectionImages?.Link?.map((lin) => ({
                imageData: lin?.Image?.data,
                objectType: lin?.ObjectType,
                title: lin?.Title,
                value: lin?.Value,
                id: lin?.id,
              })),
          }
        : {},
    dealsOfDay:
      data?.DealsOfDay !== null
        ? {
            id: data?.DealsOfDay && data?.DealsOfDay?.id,
            title: data?.DealsOfDay && data?.DealsOfDay?.SectionTitle,
            subTitle: data?.DealsOfDay && data?.DealsOfDay?.SectionSubTitle,
            ImageHeight: data?.DealsOfDay && data?.DealsOfDay.ImageHeight,
            ImageWidth: data?.DealsOfDay && data?.DealsOfDay.ImageWidth,
            links:
              data?.DealsOfDay &&
              data?.DealsOfDay?.Link?.map((lin) => ({
                imageData: lin?.Image?.data,
                objectType: lin?.ObjectType,
                title: lin?.Title,
                value: lin?.Value,
                id: lin?.id,
              })),
          }
        : {},
    testimonial:
      data?.testimonial !== null
        ? {
            id: data?.testimonial && data?.testimonial?.id,
            title: data?.testimonial && data?.testimonial?.Title,
            subTitle: data?.testimonial && data?.testimonial?.SubTitle,
            links:
              data?.testimonial &&
              data?.testimonial?.Testimonial?.map((lin) => ({
                id: lin?.id,
                description: lin?.Description,
              })),
          }
        : {},
    subscriptions:
      data?.Subscriptions !== null
        ? {
            id: data?.Subscriptions && data?.Subscriptions?.id,
            title: data?.Subscriptions && data?.Subscriptions?.SectionTitle,
            subTitle:
              data?.Subscriptions && data?.Subscriptions?.SectionSubTitle,
            ImageHeight: data?.Subscriptions && data?.Subscriptions.ImageHeight,
            ImageWidth: data?.Subscriptions && data?.Subscriptions.ImageWidth,
            links:
              data?.Subscriptions &&
              data?.Subscriptions?.Link?.map((lin) => ({
                imageData: lin?.Image?.data,
                objectType: lin?.ObjectType,
                title: lin?.Title,
                value: lin?.Value,
                id: lin?.id,
              })),
          }
        : {},
    bannerScrollSection:
      data?.BannerScrollSection !== null
        ? {
            id: data?.BannerScrollSection && data?.BannerScrollSection?.id,
            title:
              data?.BannerScrollSection &&
              data?.BannerScrollSection?.SectionTitle,
            subTitle:
              data?.BannerScrollSection &&
              data?.BannerScrollSection?.SectionSubTitle,
            ImageHeight:
              data?.BannerScrollSection &&
              data?.BannerScrollSection.ImageHeight,
            ImageWidth:
              data?.BannerScrollSection && data?.BannerScrollSection.ImageWidth,
            links:
              data?.BannerScrollSection &&
              data?.BannerScrollSection?.Link?.map((lin) => ({
                imageData: lin?.Image?.data,
                objectType: lin?.ObjectType,
                title: lin?.Title,
                value: lin?.Value,
                id: lin?.id,
              })),
          }
        : {},
    bankOffers:
      data?.BankOffers !== null
        ? {
            id: data?.BankOffers && data?.BankOffers?.id,
            title: data?.BankOffers && data?.BankOffers?.Title,
            subTitle: data?.BankOffers && data?.BankOffers?.SubTitle,
            ImageHeight: data?.BankOffers && data?.BankOffers.ImageHeight,
            ImageWidth: data?.BankOffers && data?.BankOffers.ImageWidth,
            links:
              data?.BankOffers &&
              data?.BankOffers?.Link?.map((lin) => ({
                imageData: lin?.Image?.data,
                objectType: lin?.ObjectType,
                title: lin?.Title,
                value: lin?.Value,
                id: lin?.id,
              })),
          }
        : {},
    spaBookingBanner:
      data?.SpaBookingBanner !== null
        ? {
            id: data?.SpaBookingBanner?.id,
            title: data?.SpaBookingBanner?.Title,
            ImageHeight:
              data?.SpaBookingBanner && data?.SpaBookingBanner.ImageHeight,
            ImageWidth:
              data?.SpaBookingBanner && data?.SpaBookingBanner.ImageWidth,
            subTitle: data?.SpaBookingBanner?.SubTitle,
            links: data?.SpaBookingBanner?.Link?.map((lin) => ({
              imageData: lin?.Image?.data,
              objectType: lin?.ObjectType,
              title: lin?.Title,
              value: lin?.Value,
              id: lin?.id,
            })),
          }
        : {},
    brandCarousel:
      data?.BrandCarousel !== null
        ? {
            id: data?.BrandCarousel && data?.BrandCarousel?.id,
            title: data?.BrandCarousel && data?.BrandCarousel?.Title,
            subTitle: data?.BrandCarousel && data?.BrandCarousel?.SubTitle,
            ImageHeight: data?.BrandCarousel && data?.BrandCarousel.ImageHeight,
            ImageWidth: data?.BrandCarousel && data?.BrandCarousel.ImageWidth,
            links:
              data?.BrandCarousel &&
              data?.BrandCarousel?.Link?.map((lin) => ({
                imageData: lin?.Image?.data,
                objectType: lin?.ObjectType,
                title: lin?.Title,
                value: lin?.Value,
                id: lin?.id,
              })),
          }
        : {},
    combosAndKits:
      data?.CombosAndKits !== null
        ? {
            id: data?.CombosAndKits && data?.CombosAndKits?.id,
            title: data?.CombosAndKits && data?.CombosAndKits?.Title,
            subTitle: data?.CombosAndKits && data?.CombosAndKits?.SubTitle,
            ImageHeight: data?.CombosAndKits && data?.CombosAndKits.ImageHeight,
            ImageWidth: data?.CombosAndKits && data?.CombosAndKits.ImageWidth,
            links:
              data?.CombosAndKits &&
              data?.CombosAndKits?.Link?.map((lin) => ({
                imageData: lin?.Image?.data,
                objectType: lin?.ObjectType,
                title: lin?.Title,
                value: lin?.Value,
                id: lin?.id,
              })),
          }
        : {},
    ecoConscious:
      data?.EcoConscious !== null
        ? {
            id: data?.EcoConscious && data?.EcoConscious?.id,
            title: data?.EcoConscious && data?.EcoConscious?.Title,
            subTitle: data?.EcoConscious && data?.EcoConscious?.SubTitle,
            ImageHeight: data?.EcoConscious && data?.EcoConscious.ImageHeight,
            ImageWidth: data?.EcoConscious && data?.EcoConscious.ImageWidth,
            links:
              data?.EcoConscious &&
              data?.EcoConscious?.Link?.map((lin) => ({
                imageData: lin?.Image?.data,
                objectType: lin?.ObjectType,
                title: lin?.Title,
                value: lin?.Value,
                id: lin?.id,
              })),
          }
        : {},
    locateStore:
      data?.LocateStore !== null
        ? {
            id: data?.LocateStore && data?.LocateStore?.id,
            title: data?.LocateStore && data?.LocateStore?.Title,
            subTitle: data?.LocateStore && data?.LocateStore?.SubTitle,
            ImageHeight: data?.LocateStore && data?.LocateStore.ImageHeight,
            ImageWidth: data?.LocateStore && data?.LocateStore.ImageWidth,
            links:
              data?.LocateStore &&
              data?.LocateStore?.Link?.map((lin) => ({
                imageData: lin?.Image?.data,
                objectType: lin?.ObjectType,
                title: lin?.Title,
                value: lin?.Value,
                id: lin?.id,
              })),
          }
        : {},
    foundationBanner:
      data?.FoundationBanner !== null
        ? {
            id: data?.FoundationBanner && data?.FoundationBanner?.id,
            title: data?.FoundationBanner && data?.FoundationBanner?.Title,
            subTitle:
              data?.FoundationBanner && data?.FoundationBanner?.SubTitle,
            ImageHeight:
              data?.FoundationBanner && data?.FoundationBanner.ImageHeight,
            ImageWidth:
              data?.FoundationBanner && data?.FoundationBanner.ImageWidth,
            links:
              data?.FoundationBanner &&
              data?.FoundationBanner?.Link?.map((lin) => ({
                imageData: lin?.Image?.data,
                objectType: lin?.ObjectType,
                title: lin?.Title,
                value: lin?.Value,
                id: lin?.id,
              })),
          }
        : {},
    shopByConcern:
      data?.ShopByConcern !== null
        ? {
            id: data?.ShopByConcern && data?.ShopByConcern?.id,
            title: data?.ShopByConcern && data?.ShopByConcern?.Title,
            subTitle: data?.ShopByConcern && data?.ShopByConcern?.SubTitle,
            ImageHeight: data?.ShopByConcern && data?.ShopByConcern.ImageHeight,
            ImageWidth: data?.ShopByConcern && data?.ShopByConcern.ImageWidth,
            links:
              data?.ShopByConcern &&
              data?.ShopByConcern?.Link?.map((lin) => ({
                imageData: lin?.Image?.data,
                objectType: lin?.ObjectType,
                title: lin?.Title,
                value: lin?.Value,
                id: lin?.id,
              })),
          }
        : {},
    bestFoodBrands:
      data?.BestFoodBrands !== null
        ? {
            id: data?.BestFoodBrands && data?.BestFoodBrands?.id,
            title: data?.BestFoodBrands && data?.BestFoodBrands?.Title,
            subTitle: data?.BestFoodBrands && data?.BestFoodBrands?.SubTitle,
            ImageHeight:
              data?.BestFoodBrands && data?.BestFoodBrands.ImageHeight,
            ImageWidth: data?.BestFoodBrands && data?.BestFoodBrands.ImageWidth,
            links:
              data?.BestFoodBrands &&
              data?.BestFoodBrands?.Link?.map((lin) => ({
                imageData: lin?.Image?.data,
                objectType: lin?.ObjectType,
                title: lin?.Title,
                value: lin?.Value,
                id: lin?.id,
              })),
          }
        : {},
    joinPuppyClub:
      data?.JoinPuppyClub !== null
        ? {
            id: data?.JoinPuppyClub && data?.JoinPuppyClub?.id,
            title: data?.JoinPuppyClub && data?.JoinPuppyClub?.Title,
            value: data?.JoinPuppyClub && data?.JoinPuppyClub?.Value,
            objectType: data?.JoinPuppyClub && data?.JoinPuppyClub?.ObjectType,
            imageData: data?.JoinPuppyClub && data?.JoinPuppyClub?.Image?.data,
          }
        : {},
    knowAboutYourPet:
      data?.KnowAboutYourPet !== null
        ? {
            id: data?.KnowAboutYourPet && data?.KnowAboutYourPet?.id,
            title: data?.KnowAboutYourPet && data?.KnowAboutYourPet?.Title,
            subTitle:
              data?.KnowAboutYourPet && data?.KnowAboutYourPet?.SubTitle,
            ImageHeight:
              data?.KnowAboutYourPet && data?.KnowAboutYourPet.ImageHeight,
            ImageWidth:
              data?.KnowAboutYourPet && data?.KnowAboutYourPet.ImageWidth,
            links:
              data?.KnowAboutYourPet &&
              data?.KnowAboutYourPet?.Link?.map((lin) => ({
                imageData: lin?.Image?.data,
                objectType: lin?.ObjectType,
                title: lin?.Title,
                value: lin?.Value,
                id: lin?.id,
              })),
          }
        : {},
    petBirthday:
      data?.PetBirthday !== null
        ? {
            id: data?.PetBirthday && data?.PetBirthday?.id,
            title: data?.PetBirthday && data?.PetBirthday?.Title,
            value: data?.PetBirthday && data?.PetBirthday?.Value,
            objectType: data?.PetBirthday && data?.PetBirthday?.ObjectType,
            imageData: data?.PetBirthday && data?.PetBirthday?.Image?.data,
          }
        : {},
    giftCards:
      data?.GiftCards !== null
        ? {
            id: data?.GiftCards && data?.GiftCards?.id,
            title: data?.GiftCards && data?.GiftCards?.Title,
            subTitle: data?.GiftCards && data?.GiftCards?.SubTitle,
            ImageHeight: data?.GiftCards && data?.GiftCards.ImageHeight,
            ImageWidth: data?.GiftCards && data?.GiftCards.ImageWidth,
            links:
              data?.GiftCards &&
              data?.GiftCards?.Link?.map((lin) => ({
                imageData: lin?.Image?.data,
                objectType: lin?.ObjectType,
                title: lin?.Title,
                value: lin?.Value,
                id: lin?.id,
              })),
          }
        : {},
  };
};

export const getFormattedCatUpdateParams = ({ data, dataKey }) => {
  switch (dataKey) {
    case 'singleVerticleBanner':
      return {
        SingleVerticleBanner: {
          ImageHeight: data.ImageHeight,
          ImageWidth: data.ImageWidth,
          Link: {
            Id: data.id,
            Title: data.title,
            Value: data.value,
            ObjectType: data.objectType,
            Image: data.imageData,
          },
        },
      };

    case 'carouselBannerImages':
      return {
        CarouselBannerImages: {
          Id: data.id,
          Title: data.title,
          ImageHeight: data.ImageHeight,
          ImageWidth: data.ImageWidth,
          SubTitle: data.subTitle,
          Link: data.links.map((lin) => ({
            Image: lin.imageData,
            ObjectType: lin.objectType,
            Title: lin.title,
            Value: lin.value,
            Id: lin.id,
          })),
        },
      };

    case 'swipeSectionImages':
      return {
        SwipeSectionImages: {
          Id: data.id,
          SectionTitle: data.title,
          SectionSubTitle: data.subTitle,
          ImageHeight: data.ImageHeight,
          ImageWidth: data.ImageWidth,
          Link: data.links.map((lin) => ({
            Image: lin.imageData,
            ObjectType: lin.objectType,
            Title: lin.title,
            Value: lin.value,
            Id: lin.id,
          })),
        },
      };

    case 'dealsOfDay':
      return {
        DealsOfDay: {
          Id: data.id,
          SectionTitle: data.title,
          SectionSubTitle: data.subTitle,
          ImageHeight: data.ImageHeight,
          ImageWidth: data.ImageWidth,
          Link: data.links.map((lin) => ({
            Image: lin.imageData,
            ObjectType: lin.objectType,
            Title: lin.title,
            Value: lin.value,
            Id: lin.id,
          })),
        },
      };

    case 'testimonial':
      return {
        testimonial: {
          Id: data.id,
          Title: data.title,
          SubTitle: data.subTitle,
          Testimonial: data.links.map((lin) => ({
            Id: lin.id,
            Description: lin.description,
          })),
        },
      };

    case 'subscriptions':
      return {
        Subscriptions: {
          Id: data.id,
          SectionTitle: data.title,
          SectionSubTitle: data.subTitle,
          ImageHeight: data.ImageHeight,
          ImageWidth: data.ImageWidth,
          Link: data.links.map((lin) => ({
            Image: lin.imageData,
            ObjectType: lin.objectType,
            Title: lin.title,
            Value: lin.value,
            Id: lin.id,
          })),
        },
      };

    case 'bannerScrollSection':
      return {
        BannerScrollSection: {
          Id: data.id,
          SectionTitle: data.title,
          ImageHeight: data.ImageHeight,
          ImageWidth: data.ImageWidth,
          SectionSubTitle: data.subTitle,
          Link: data.links.map((lin) => ({
            Image: lin.imageData,
            ObjectType: lin.objectType,
            Title: lin.title,
            Value: lin.value,
            Id: lin.id,
          })),
        },
      };

    case 'bankOffers':
      return {
        BankOffers: {
          Id: data.id,
          Title: data.title,
          ImageHeight: data.ImageHeight,
          ImageWidth: data.ImageWidth,
          SubTitle: data.subTitle,
          Link: data.links.map((lin) => ({
            Image: lin.imageData,
            ObjectType: lin.objectType,
            Title: lin.title,
            Value: lin.value,
            Id: lin.id,
          })),
        },
      };

    case 'spaBookingBanner':
      return {
        SpaBookingBanner: {
          Id: data.id,
          Title: data.title,
          SubTitle: data.subTitle,
          ImageHeight: data.ImageHeight,
          ImageWidth: data.ImageWidth,
          Link: data.links.map((lin) => ({
            Image: lin.imageData,
            ObjectType: lin.objectType,
            Title: lin.title,
            Value: lin.value,
            Id: lin.id,
          })),
        },
      };

    case 'brandCarousel':
      return {
        BrandCarousel: {
          Id: data.id,
          Title: data.title,
          ImageHeight: data.ImageHeight,
          ImageWidth: data.ImageWidth,
          SubTitle: data.subTitle,
          Link: data.links.map((lin) => ({
            Image: lin.imageData,
            ObjectType: lin.objectType,
            Title: lin.title,
            Value: lin.value,
            Id: lin.id,
          })),
        },
      };

    case 'combosAndKits':
      return {
        CombosAndKits: {
          Id: data.id,
          Title: data.title,
          ImageHeight: data.ImageHeight,
          ImageWidth: data.ImageWidth,
          SubTitle: data.subTitle,
          Link: data.links.map((lin) => ({
            Image: lin.imageData,
            ObjectType: lin.objectType,
            Title: lin.title,
            Value: lin.value,
            Id: lin.id,
          })),
        },
      };

    case 'ecoConscious':
      return {
        EcoConscious: {
          Id: data.id,
          Title: data.title,
          ImageHeight: data.ImageHeight,
          ImageWidth: data.ImageWidth,
          SubTitle: data.subTitle,
          Link: data.links.map((lin) => ({
            Image: lin.imageData,
            ObjectType: lin.objectType,
            Title: lin.title,
            Value: lin.value,
            Id: lin.id,
          })),
        },
      };

    case 'locateStore':
      return {
        LocateStore: {
          Id: data.id,
          Title: data.title,
          SubTitle: data.subTitle,
          ImageHeight: data.ImageHeight,
          ImageWidth: data.ImageWidth,
          Link: data.links.map((lin) => ({
            Image: lin.imageData,
            ObjectType: lin.objectType,
            Title: lin.title,
            Value: lin.value,
            Id: lin.id,
          })),
        },
      };

    case 'foundationBanner':
      return {
        FoundationBanner: {
          Id: data.id,
          Title: data.title,
          ImageHeight: data.ImageHeight,
          ImageWidth: data.ImageWidth,
          SubTitle: data.subTitle,
          Link: data.links.map((lin) => ({
            Image: lin.imageData,
            ObjectType: lin.objectType,
            Title: lin.title,
            Value: lin.value,
            Id: lin.id,
          })),
        },
      };

    case 'shopByConcern':
      return {
        ShopByConcern: {
          Id: data.id,
          Title: data.title,
          ImageHeight: data.ImageHeight,
          ImageWidth: data.ImageWidth,
          SubTitle: data.subTitle,
          Link: data.links.map((lin) => ({
            Image: lin.imageData,
            ObjectType: lin.objectType,
            Title: lin.title,
            Value: lin.value,
            Id: lin.id,
          })),
        },
      };

    case 'bestFoodBrands':
      return {
        BestFoodBrands: {
          Id: data.id,
          Title: data.title,
          ImageHeight: data.ImageHeight,
          ImageWidth: data.ImageWidth,
          SubTitle: data.subTitle,
          Link: data.links.map((lin) => ({
            Image: lin.imageData,
            ObjectType: lin.objectType,
            Title: lin.title,
            Value: lin.value,
            Id: lin.id,
          })),
        },
      };

    case 'joinPuppyClub':
      return {
        JoinPuppyClub: {
          Id: data.id,
          Title: data.title,
          Value: data.value,
          ObjectType: data.objectType,
          Image: data.imageData,
        },
      };

    case 'knowAboutYourPet':
      return {
        KnowAboutYourPet: {
          Id: data.id,
          Title: data.title,
          ImageHeight: data.ImageHeight,
          ImageWidth: data.ImageWidth,
          SubTitle: data.subTitle,
          Link: data.links.map((lin) => ({
            Image: lin.imageData,
            ObjectType: lin.objectType,
            Title: lin.title,
            Value: lin.value,
            Id: lin.id,
          })),
        },
      };

    case 'petBirthday':
      return {
        PetBirthday: {
          Id: data.id,
          Title: data.title,
          Value: data.value,
          ObjectType: data.objectType,
          Image: data.imageData,
        },
      };

    case 'giftCards':
      return {
        GiftCards: {
          Id: data.id,
          Title: data.title,
          ImageHeight: data.ImageHeight,
          ImageWidth: data.ImageWidth,
          SubTitle: data.subTitle,
          Link: data.links.map((lin) => ({
            Image: lin.imageData,
            ObjectType: lin.objectType,
            Title: lin.title,
            Value: lin.value,
            Id: lin.id,
          })),
        },
      };

    default:
      return null;
  }
};
