// get api params

export const getDogTreatParams = () => ({
  params: {
    populate:
      'SingleBanner.SingleBannerLinks.Image,DogTreatBanner.DogTreatLink.Image,AllDogTreats.ItemLink.Image,SpotLightSlider.Link.Image.,SummerEssentialBanner.Banner.Image,SummerEssentialSlider.Link.Image,GroomingEssential,ShopAdded.ShopByLink.Image,Benefit.BenefitLink,TrainingTreatBanner.TrainingTreat.Image,TrainingTreatSlider.Link.Image,PuppyTreatBanner.PuppyTreatSingleBanner.Image,PuppyTreat.Link.Image,CombosBanner.CombosImage.Image,CombosSlider.CombosLink.Image,DidYouKnow.Image.Image,DealCorner.DealCornerGif.Image,PointReward.PointLink.Image,ShopHuft.ShopHuftLink.Image,WatchLearn.Link.Image',
  },
});

// format all get api data

export const formatDogData = (data) => {
  return {
    vuid: {
      vuid: data.vuid,
    },

    SingleBanner: {
      id: data.SingleBanner && data.SingleBanner.SingleBannerLinks.id,
      title: data.SingleBanner && data.SingleBanner.SingleBannerLinks.Title,
      value: data.SingleBanner && data.SingleBanner.SingleBannerLinks.Value,
      ImageHeight: data.SingleBanner && data.SingleBanner.ImageHeight,
      ImageWidth: data.SingleBanner && data.SingleBanner.ImageWidth,
      objectType:
        data.SingleBanner && data.SingleBanner.SingleBannerLinks.ObjectType,
      imageData:
        data.SingleBanner && data.SingleBanner.SingleBannerLinks.Image.data,
    },

    SummerEssentialBanner: {
      id: data.SummerEssentialBanner && data.SummerEssentialBanner.Banner.id,
      title:
        data.SummerEssentialBanner && data.SummerEssentialBanner.Banner.Title,
      value:
        data.SummerEssentialBanner && data.SummerEssentialBanner.Banner.Value,
      ImageHeight:
        data.SummerEssentialBanner && data.SummerEssentialBanner.ImageHeight,
      ImageWidth:
        data.SummerEssentialBanner && data.SummerEssentialBanner.ImageWidth,
      objectType:
        data.SummerEssentialBanner &&
        data.SummerEssentialBanner.Banner.ObjectType,
      imageData:
        data.SummerEssentialBanner.Banner.Image.data &&
        data.SummerEssentialBanner.Banner.Image.data[0],
    },

    DogTreatBanner: {
      id: data.DogTreatBanner && data.DogTreatBanner.id,
      title: data.DogTreatBanner && data.DogTreatBanner.Title,
      subTitle: data.DogTreatBanner && data.DogTreatBanner.SubTitle,
      links:
        data?.DogTreatBanner?.DogTreatLink &&
        data?.DogTreatBanner?.DogTreatLink.map((lin) => ({
          imageData: lin?.Image?.data && lin?.Image?.data[0],
          objectType: lin.ObjectType,
          title: lin.Title,
          value: lin.Value,
          id: lin.id,
        })),
    },
    AllDogTreats: {
      id: data.AllDogTreats && data.AllDogTreats.id,
      title: data.AllDogTreats && data.AllDogTreats.Title,
      subTitle: data.AllDogTreats && data.AllDogTreats.SubTitle,
      links:
        data.AllDogTreats.ItemLink &&
        data.AllDogTreats.ItemLink.map((lin) => ({
          imageData: lin?.Image?.data && lin.Image.data[0],
          objectType: lin.ObjectType,
          title: lin.Title,
          value: lin.Value,
          id: lin.id,
        })),
    },
    SpotLightSlider: {
      id: data.SpotLightSlider && data.SpotLightSlider.id,
      title: data.SpotLightSlider && data.SpotLightSlider.Title,
      subTitle: data.SpotLightSlider && data.SpotLightSlider.SubTitle,
      ImageHeight: data.SpotLightSlider && data.SpotLightSlider.ImageHeight,
      ImageWidth: data.SpotLightSlider && data.SpotLightSlider.ImageWidth,
      links:
        data.SpotLightSlider &&
        data.SpotLightSlider.Link.map((lin) => ({
          imageData: lin?.Image?.data && lin.Image.data[0],
          objectType: lin.ObjectType,
          title: lin.Title,
          value: lin.Value,
          id: lin.id,
        })),
    },
    SummerEssentialSlider: {
      id: data.SummerEssentialSlider && data.SummerEssentialSlider.id,
      title: data.SummerEssentialSlider && data.SummerEssentialSlider.Title,
      subTitle:
        data.SummerEssentialSlider && data.SummerEssentialSlider.SubTitle,
      ImageHeight:
        data.SummerEssentialSlider && data.SummerEssentialSlider.ImageHeight,
      ImageWidth:
        data.SummerEssentialSlider && data.SummerEssentialSlider.ImageWidth,
      links:
        data.SummerEssentialSlider &&
        data.SummerEssentialSlider.Link.map((lin) => ({
          imageData: lin.Image.data,
          objectType: lin.ObjectType,
          title: lin.Title,
          value: lin.Value,
          id: lin.id,
        })),
    },
    ShopAdded: {
      id: data.ShopAdded && data.ShopAdded.id,
      title: data.ShopAdded && data.ShopAdded.ShopByLink.Title,
      imageData:
        data.ShopAdded.ShopByLink.Image.data &&
        data.ShopAdded.ShopByLink.Image.data[0],
      objectType: data.ShopAdded && data.ShopAdded.ShopByLink.ObjectType,
      value: data.ShopAdded && data.ShopAdded.ShopByLink.Value,
      ImageHeight: data.ShopAdded && data.ShopAdded.ImageHeight,
      ImageWidth: data.ShopAdded && data.ShopAdded.ImageWidth,
    },
    Benefit: {
      id: data.Benefit && data.Benefit.id,
      title: data.Benefit && data.Benefit.Title,
      subTitle: data.Benefit && data.Benefit.SubTitle,
      links:
        data.Benefit &&
        data.Benefit.BenefitLink.map((lin) => ({
          // imageData: lin.Image.data[0],
          description: lin.Description,
          title: lin.Title,
          id: lin.id,
        })),
    },
    TrainingTreatBanner: {
      id: data.TrainingTreatBanner && data.TrainingTreatBanner.TrainingTreat.id,
      title:
        data.TrainingTreatBanner &&
        data.TrainingTreatBanner.TrainingTreat.Title,

      value:
        data.TrainingTreatBanner &&
        data.TrainingTreatBanner.TrainingTreat.Value,
      objectType:
        data.TrainingTreatBanner &&
        data.TrainingTreatBanner.TrainingTreat.ObjectType,
      ImageHeight:
        data.TrainingTreatBanner && data.TrainingTreatBanner.ImageHeight,
      ImageWidth:
        data.TrainingTreatBanner && data.TrainingTreatBanner.ImageWidth,
      imageData:
        data.TrainingTreatBanner.TrainingTreat.Image.data &&
        data.TrainingTreatBanner.TrainingTreat.Image.data[0],
    },
    TrainingTreatSlider: {
      id: data.TrainingTreatSlider && data.TrainingTreatSlider.Link.id,
      title: data.TrainingTreatSlider && data.TrainingTreatSlider.Link.Title,
      value: data.TrainingTreatSlider && data.TrainingTreatSlider.Link.Value,
      imageData:
        data.TrainingTreatSlider.Link.Image.data &&
        data.TrainingTreatSlider.Link.Image.data[0],
      ImageHeight:
        data.TrainingTreatSlider && data.TrainingTreatSlider.ImageHeight,
      ImageWidth:
        data.TrainingTreatSlider && data.TrainingTreatSlider.ImageWidth,
      objectType:
        data.TrainingTreatSlider && data.TrainingTreatSlider.Link.ObjectType,
    },
    PuppyTreatBanner: {
      id:
        data.PuppyTreatBanner &&
        data.PuppyTreatBanner.PuppyTreatSingleBanner.id,
      title:
        data.PuppyTreatBanner &&
        data.PuppyTreatBanner.PuppyTreatSingleBanner.Title,
      value:
        data.PuppyTreatBanner &&
        data.PuppyTreatBanner.PuppyTreatSingleBanner.Value,
      ImageHeight: data.PuppyTreatBanner && data.PuppyTreatBanner.ImageHeight,
      ImageWidth: data.PuppyTreatBanner && data.PuppyTreatBanner.ImageWidth,
      objectType:
        data.PuppyTreatBanner &&
        data.PuppyTreatBanner.PuppyTreatSingleBanner.ObjectType,

      imageData:
        data.PuppyTreatBanner.PuppyTreatSingleBanner.Image.data &&
        data.PuppyTreatBanner.PuppyTreatSingleBanner.Image.data[0],
    },
    PuppyTreat: {
      id: data.PuppyTreat && data.PuppyTreat.id,
      title: data.PuppyTreat && data.PuppyTreat.Title,
      subTitle: data.PuppyTreat && data.PuppyTreat.SubTitle,
      ImageHeight: data.PuppyTreat && data.PuppyTreat.ImageHeight,
      ImageWidth: data.PuppyTreat && data.PuppyTreat.ImageWidth,
      links:
        data.PuppyTreat &&
        data.PuppyTreat.Link.map((lin) => ({
          imageData: lin.Image.data && lin.Image.data[0],
          objectType: lin.ObjectType,
          title: lin.Title,
          value: lin.Value,
          id: lin.id,
        })),
    },
    CombosBanner: {
      id: data.CombosBanner && data.CombosBanner.id,
      title:
        data.CombosBanner.CombosImage && data.CombosBanner.CombosImage.Title,
      value:
        data.CombosBanner.CombosImage && data.CombosBanner.CombosImage.Value,
      objectType:
        data.CombosBanner.CombosImage &&
        data.CombosBanner.CombosImage.ObjectType,
      ImageHeight:
        data.CombosBanner.CombosImage &&
        data.CombosBanner.CombosImage.ImageHeight,
      ImageWidth:
        data.CombosBanner.CombosImage &&
        data.CombosBanner.CombosImage.ImageWidth,
      imageData:
        data.CombosBanner.CombosImage.Image.data &&
        data.CombosBanner.CombosImage.Image.data[0],
    },

    CombosSlider: {
      id: data.CombosSlider && data.CombosSlider.id,
      title: data.CombosSlider && data.CombosSlider.Title,
      subTitle: data.CombosSlider && data.CombosSlider.SubTitle,
      ImageHeight: data.CombosSlider && data.CombosSlider.ImageHeight,
      ImageWidth: data.CombosSlider && data.CombosSlider.ImageWidth,
      links:
        data.CombosSlider.CombosLink &&
        data.CombosSlider.CombosLink.map((lin) => ({
          imageData: lin.Image.data && lin.Image.data[0],
          objectType: lin.ObjectType,
          title: lin.Title,
          value: lin.Value,
          id: lin.id,
        })),
    },

    DidYouKnow: {
      id: data.DidYouKnow.Image && data.DidYouKnow.Image.id,
      title: data.DidYouKnow.Image && data.DidYouKnow.Image.Title,
      value: data.DidYouKnow.Image && data.DidYouKnow.Image.Value,
      objectType: data.DidYouKnow.Image && data.DidYouKnow.Image.ObjectType,
      ImageHeight: data.DidYouKnow && data.DidYouKnow.ImageHeight,
      ImageWidth: data.DidYouKnow && data.DidYouKnow.ImageWidth,
      imageData:
        data.DidYouKnow.Image.Image.data && data.DidYouKnow.Image.Image.data[0],
    },
    DealCorner: {
      id: data.DealCorner.DealCornerGif && data.DealCorner.DealCornerGif.id,
      title:
        data.DealCorner.DealCornerGif && data.DealCorner.DealCornerGif.Title,
      value:
        data.DealCorner.DealCornerGif && data.DealCorner.DealCornerGif.Value,
      objectType:
        data.DealCorner.DealCornerGif &&
        data.DealCorner.DealCornerGif.ObjectType,
      ImageWidth:
        data.DealCorner.DealCornerGif &&
        data.DealCorner.DealCornerGif.ImageWidth,
      imageData:
        data.DealCorner.DealCornerGif.Image.data &&
        data.DealCorner.DealCornerGif.Image.data[0],
    },
    PointReward: {
      id: data.PointReward && data.PointReward.id,
      title: data.PointReward && data.PointReward.Title,

      subTitle: data.PointReward && data.PointReward.SubTitle,

      links:
        data.PointReward &&
        data.PointReward.PointLink.map((lin) => ({
          imageData: lin.Image.data,
          objectType: lin.ObjectType,
          title: lin.Title,
          value: lin.Value,
          id: lin.id,
        })),
    },
    ShopHuft: {
      id: data.ShopHuft && data.ShopHuft.id,
      title: data.ShopHuft && data.ShopHuft.Title,
      subTitle: data.ShopHuft && data.ShopHuft.SubTitle,
      ImageHeight: data.ShopHuft && data.ShopHuft.ImageHeight,
      ImageWidth: data.ShopHuft && data.ShopHuft.ImageWidth,
      links:
        data.ShopHuft &&
        data.ShopHuft.ShopHuftLink.map((lin) => ({
          imageData: lin.Image.data && lin.Image.data[0],
          objectType: lin.ObjectType,
          title: lin.Title,
          value: lin.Value,
          id: lin.id,
        })),
    },
    WatchLearn: {
      id: data.WatchLearn && data.WatchLearn.id,
      title: data.WatchLearn && data.WatchLearn.Title,
      subTitle: data.WatchLearn && data.WatchLearn.SubTitle,
      links:
        data.WatchLearn &&
        data.WatchLearn.Link.map((lin) => ({
          imageData: lin.Image.data,
          objectType: lin.ObjectType,
          videoUrl: lin.VideoUrl,
          title: lin.Title,
          value: lin.Value,
          id: lin.id,
        })),
    },
  };
};
// format all update api data
export const getFormattedDogUpdateParams = ({ data, dataKey }) => {
  switch (dataKey) {
    case 'SingleBanner':
      return {
        SingleBanner: {
          ImageHeight: data.ImageHeight,
          ImageWidth: data.ImageWidth,
          SingleBannerLinks: {
            Id: data.id,
            Title: data.title,
            Value: data.value,
            ObjectType: data.objectType,
            Image: data.imageData,
          },
        },
      };

    case 'SummerEssentialBanner':
      return {
        SummerEssentialBanner: {
          ImageHeight: data.ImageHeight,
          ImageWidth: data.ImageWidth,
          Banner: {
            Id: data.id,
            Title: data.title,
            Value: data.value,
            ObjectType: data.objectType,
            Image: data.imageData,
          },
        },
      };

    case 'DogTreatBanner':
      return {
        DogTreatBanner: {
          Id: data.id,
          Title: data.title,
          SubTitle: data.subTitle,
          DogTreatLink: data.links.map((lin) => ({
            ObjectType: lin.objectType,
            Title: lin.title,
            Value: lin.value,
            Id: lin.id,
            Image: lin.imageData,
          })),
        },
      };

    case 'AllDogTreats':
      return {
        AllDogTreats: {
          Id: data.id,
          Title: data.title,
          SubTitle: data.subTitle,
          ItemLink: data.links.map((lin) => ({
            ObjectType: lin.objectType,
            Title: lin.title,
            Value: lin.value,
            Id: lin.id,
            Image: lin.imageData,
          })),
        },
      };

    case 'SpotLightSlider':
      return {
        SpotLightSlider: {
          Id: data.id,
          Title: data.title,
          ImageHeight: data.ImageHeight,
          ImageWidth: data.ImageWidth,
          SubTitle: data.subTitle,
          Link: data.links.map((lin) => ({
            Image: lin.imageData,
            ObjectType: lin.objectType,
            Title: lin.title,
            Value: lin.value,
            Id: lin.id,
          })),
        },
      };

    case 'SummerEssentialSlider':
      return {
        SummerEssentialSlider: {
          Id: data.id,
          SectionTitle: data.title,
          SectionSubTitle: data.subTitle,
          ImageHeight: data.ImageHeight,
          ImageWidth: data.ImageWidth,
          Link: data.links.map((lin) => ({
            Image: lin.imageData,
            ObjectType: lin.objectType,
            Title: lin.title,
            Value: lin.value,
            Id: lin.id,
          })),
        },
      };

    case 'ShopAdded':
      return {
        ShopAdded: {
          Id: data.id,
          ImageHeight: data.ImageHeight,
          ImageWidth: data.ImageWidth,
          ShopByLink: {
            Id: data.id,
            Title: data.title,
            Value: data.value,
            ObjectType: data.objectType,
            Image: data.imageData,
          },
        },
      };

    case 'Benefit':
      return {
        Benefit: {
          Id: data.id,
          Title: data.title,
          SubTitle: data.subTitle,
          BenefitLink: data.links.map((lin) => ({
            Description: lin.description,
            Title: lin.title,
            Id: lin.id,
          })),
        },
      };

    case 'TrainingTreatBanner':
      return {
        TrainingTreatBanner: {
          Id: data.id,
          ImageHeight: data.ImageHeight,
          ImageWidth: data.ImageWidth,
          TrainingTreat: {
            Id: data.id,
            Title: data.title,
            Value: data.value,
            ObjectType: data.objectType,
            Image: data.imageData,
          },
        },
      };

    case 'TrainingTreatSlider':
      return {
        TrainingTreatSlider: {
          Id: data.id,
          ImageHeight: data.ImageHeight,
          ImageWidth: data.ImageWidth,
          TrainingTreat: {
            Id: data.id,
            Title: data.title,
            Value: data.value,
            ObjectType: data.objectType,
            Image: data.imageData,
          },
        },
      };

    case 'PuppyTreatBanner':
      return {
        PuppyTreatBanner: {
          Id: data.id,
          Title: data.title,
          ImageHeight: data.ImageHeight,
          ImageWidth: data.ImageWidth,
          SubTitle: data.subTitle,
          PuppyTreatSingleBanner: {
            Id: data.id,
            Title: data.title,
            Value: data.value,
            ObjectType: data.objectType,
            Image: data.imageData,
          },
        },
      };

    case 'PuppyTreat':
      return {
        PuppyTreat: {
          Id: data.id,
          Title: data.title,
          ImageHeight: data.ImageHeight,
          ImageWidth: data.ImageWidth,
          SubTitle: data.subTitle,
          Link: data.links.map((lin) => ({
            Image: lin.imageData,
            ObjectType: lin.objectType,
            Title: lin.title,
            Value: lin.value,
            Id: lin.id,
          })),
        },
      };

    case 'CombosBanner':
      return {
        CombosBanner: {
          Id: data.id,
          Title: data.title,
          ImageHeight: data.ImageHeight,
          ImageWidth: data.ImageWidth,
          SubTitle: data.subTitle,
          Link: data.links.map((lin) => ({
            Image: lin.imageData,
            ObjectType: lin.objectType,
            Title: lin.title,
            Value: lin.value,
            Id: lin.id,
          })),
        },
      };

    case 'CombosSlider':
      return {
        CombosSlider: {
          Id: data.id,
          Title: data.title,
          ImageHeight: data.ImageHeight,
          ImageWidth: data.ImageWidth,
          SubTitle: data.subTitle,
          CombosLink: data.links.map((lin) => ({
            Image: lin.imageData,
            ObjectType: lin.objectType,
            Title: lin.title,
            Value: lin.value,
            Id: lin.id,
          })),
        },
      };

    case 'DidYouKnow':
      return {
        DidYouKnow: {
          Id: data.id,
          ImageHeight: data.ImageHeight,
          ImageWidth: data.ImageWidth,
          Image: {
            Id: data.id,
            Title: data.title,
            Value: data.value,
            ObjectType: data.objectType,
            Image: data.imageData,
          },
        },
      };

    case 'DealCorner':
      return {
        DealCorner: {
          Id: data.id,
          Title: data.title,
          ImageHeight: data.ImageHeight,
          ImageWidth: data.ImageWidth,
          SubTitle: data.subTitle,
          Link: data.links.map((lin) => ({
            Image: lin.imageData,
            ObjectType: lin.objectType,
            Title: lin.title,
            Value: lin.value,
            Id: lin.id,
          })),
        },
      };

    case 'PointReward':
      return {
        PointReward: {
          Id: data.id,
          Title: data.title,
          ImageHeight: data.ImageHeight,
          ImageWidth: data.ImageWidth,
          SubTitle: data.subTitle,
          PointLink: data.links.map((lin) => ({
            Image: lin.imageData,
            ObjectType: lin.objectType,
            Title: lin.title,
            Value: lin.value,
            Id: lin.id,
          })),
        },
      };

    case 'ShopHuft':
      return {
        ShopHuft: {
          Id: data.id,
          Title: data.title,
          ImageHeight: data.ImageHeight,
          ImageWidth: data.ImageWidth,
          SubTitle: data.subTitle,
          ShopHuftLink: data.links.map((lin) => ({
            Image: lin.imageData,
            ObjectType: lin.objectType,
            Title: lin.title,
            Value: lin.value,
            Id: lin.id,
          })),
        },
      };
    case 'WatchLearn':
      return {
        WatchLearn: {
          Id: data.id,
          Title: data.title,
          SubTitle: data.subTitle,
          // ImageHeight: data.ImageHeight,
          // ImageWidth: data.ImageWidth,
          Link: data.links.map((lin) => ({
            Image: lin.imageData,
            ObjectType: lin.objectType,
            VideoUrl: lin.videoUrl,
            Title: lin.title,
            Value: lin.value,
            Id: lin.id,
          })),
        },
      };

    default:
      return null;
  }
};
