import {
  GET_DOG_GROOMING,
  GET_DOG_GROOMING_ERROR,
  GET_DOG_GROOMING_SUCCESS,
  UPDATE_DOG_GROOMING,
  UPDATE_DOG_GROOMING_ERROR,
  UPDATE_DOG_GROOMING_SUCCESS,
  PUBLISH_DOG_GROOMING_REQUEST,
  PUBLISH_DOG_GROOMING_SUCCESS,
  PUBLISH_DOG_GROOMING_ERROR,
} from 'redux/actions';

const INIT_STATE = {
  Banner: {},
  // eslint-disable-next-line no-shadow
  DogGrooming: {},
  DogGroomingSlider: {},
  Shampoos: {},
  GroomingEssentialBanner: {},
  GroomingSlider: {},
  BestSeller: {},
  ShopByConcernBanner: {},
  ShopByConcern: {},
  MostLovedBanner: {},
  MostLovedBrand: {},
  ProductsInFocus: {},
  loading: false,
  isPublishing: false,
  error: false,
  updateSuccess: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    // GET_DOG_GROOMING
    case GET_DOG_GROOMING:
      return { ...state, loading: true, updateSuccess: false };

    case GET_DOG_GROOMING_ERROR:
      return { ...state, loading: false, error: action.payload };

    case GET_DOG_GROOMING_SUCCESS:
      return { ...state, ...action.payload };

    // UPDATE_DOG_GROOMING
    case UPDATE_DOG_GROOMING:
      return { ...state, loading: true, updateSuccess: false };

    case UPDATE_DOG_GROOMING_SUCCESS:
      return { ...state, loading: false, error: false, updateSuccess: true };

    case UPDATE_DOG_GROOMING_ERROR:
      return { ...state, loading: false, error: true, updateSuccess: false };

    // PUBLISH_DOG_GROOMING_REQUEST
    case PUBLISH_DOG_GROOMING_REQUEST:
      return {
        ...state,
        isPublishing: true,
        error: false,
      };

    case PUBLISH_DOG_GROOMING_SUCCESS:
      return {
        ...state,
        isPublishing: false,
        error: false,
      };

    case PUBLISH_DOG_GROOMING_ERROR:
      return {
        ...state,
        isPublishing: false,
        error: true,
      };

    default:
      return { ...state };
  }
};
