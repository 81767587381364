import {
  GET_CAT_DATA,
  GET_CAT_DATA_ERROR,
  GET_CAT_DATA_SUCCESS,
  PUBLISH_CAT_DATA,
  PUBLISH_CAT_REQUEST,
  PUBLISH_CAT_DATA_ERROR,
  PUBLISH_CAT_DATA_SUCCESS,
  SAVE_CAT_VERSION,
  UPDATE_CAT_DATA,
  UPDATE_CAT_DATA_ERROR,
  UPDATE_CAT_DATA_SUCCESS,
} from 'redux/actions';

// GET_CAT_DATA
export const getCatData = () => ({
  type: GET_CAT_DATA,
});

export const getCatDataError = (payload) => ({
  type: GET_CAT_DATA_ERROR,
  payload,
});

export const getCatDataSuccess = (payload) => ({
  type: GET_CAT_DATA_SUCCESS,
  payload,
});

// UPDATE_CAT_DATA
export const updateCatData = (payload) => ({
  type: UPDATE_CAT_DATA,
  payload,
});

export const updateCatDataSuccess = (payload) => ({
  type: UPDATE_CAT_DATA_SUCCESS,
  payload,
});

export const updateCatDataError = (payload) => ({
  type: UPDATE_CAT_DATA_ERROR,
  payload,
});

// SAVE_CAT_VERSION
export const addCatVersion = (payload) => ({
  type: SAVE_CAT_VERSION,
  payload,
});

// PUBLISH_CAT_DATA
export const publishCatData = (payload) => ({
  type: PUBLISH_CAT_DATA,
  payload,
});

export const publishCatRequest = (payload) => ({
  type: PUBLISH_CAT_REQUEST,
  payload,
});

export const publishCatDataSuccess = (payload) => ({
  type: PUBLISH_CAT_DATA_SUCCESS,
  payload,
});

export const publishCatDataError = (payload) => ({
  type: PUBLISH_CAT_DATA_ERROR,
  payload,
});
