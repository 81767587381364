import {
  GET_DOG_TREAT,
  GET_DOG_TREAT_ERROR,
  GET_DOG_TREAT_SUCCESS,
  UPDATE_DOG_TREAT,
  UPDATE_DOG_TREAT_ERROR,
  UPDATE_DOG_TREAT_SUCCESS,
  PUBLISH_DOG_TREAT_REQUEST,
  PUBLISH_DOG_TREAT_SUCCESS,
  PUBLISH_DOG_TREAT_ERROR,
} from 'redux/actions';

const INIT_STATE = {
  SingleBanner: {},
  DogTreatBanner: {},
  SummerEssentialBanner: {},
  AllDogTreats: {},
  SpotLightSlider: {},
  SummerEssentialSlider: {},
  ShopAdded: {},
  Benefit: {},
  TrainingTreatBanner: {},
  TrainingTreatSlider: {},
  PuppyTreatBanner: {},
  PuppyTreat: {},
  CombosBanner: {},
  CombosSlider: {},
  DidYouKnow: {},
  DealCorner: {},
  PointReward: {},
  ShopHuft: {},
  loading: false,
  isPublishing: false,
  error: false,
  updateSuccess: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    // GET_DOG_TREAT
    case GET_DOG_TREAT:
      return { ...state, loading: true, updateSuccess: false };

    case GET_DOG_TREAT_ERROR:
      return { ...state, loading: false, error: action.payload };

    case GET_DOG_TREAT_SUCCESS:
      return { ...state, ...action.payload };

    // UPDATE_DOG_TREAT
    case UPDATE_DOG_TREAT:
      return { ...state, loading: true, updateSuccess: false };

    case UPDATE_DOG_TREAT_SUCCESS:
      return { ...state, loading: false, error: false, updateSuccess: true };

    case UPDATE_DOG_TREAT_ERROR:
      return { ...state, loading: false, error: true, updateSuccess: false };

    // PUBLISH_DOG_TREAT_REQUEST
    case PUBLISH_DOG_TREAT_REQUEST:
      return {
        ...state,
        isPublishing: true,
        error: false,
      };

    case PUBLISH_DOG_TREAT_SUCCESS:
      return {
        ...state,
        isPublishing: false,
        error: false,
      };

    case PUBLISH_DOG_TREAT_ERROR:
      return {
        ...state,
        isPublishing: false,
        error: true,
      };

    default:
      return { ...state };
  }
};
