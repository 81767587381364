// get api params
export const getSaraWholesomeParams = () => ({
  params: {
    populate:
      'BannerImage.Link.Image,ClassicBanner.ClassicLink.Gif,Classic.ClassicSlider.Image,GrainFreeBanner.Links.Image,GrainFree.GrainFreeLink.Image,WhatYouSee.AllLink.Image,WhatYouGet.VideoLink.Video,SingleImage.Image.Image,FAQ.FaqLinks.Image',
  },
});

// format all get api data
export const formatSaraWholesome = (data) => {
  return {
    vuid: {
      vuid: data.vuid,
    },

    BannerImage: {
      id: data.BannerImage && data.BannerImage.id,
      title: data.BannerImage && data.BannerImage.Link.Title,
      value: data.BannerImage && data.BannerImage.Link.Value,
      ImageHeight: data.BannerImage && data.BannerImage.ImageHeight,
      ImageWidth: data.BannerImage && data.BannerImage.ImageWidth,
      objectType: data.BannerImage && data.BannerImage.Link.ObjectType,
      imageData:
        data.BannerImage.Link.Image.data && data.BannerImage.Link.Image.data[0],
    },

    ClassicBanner: {
      id: data.ClassicBanner && data.ClassicBanner.id,
      title: data.ClassicBanner && data.ClassicBanner.ClassicLink.Title,
      value: data.ClassicBanner && data.ClassicBanner.ClassicLink.Value,
      objectType:
        data.ClassicBanner && data.ClassicBanner.ClassicLink.ObjectType,
      imageData: data.ClassicBanner && data.ClassicBanner.ClassicLink.Gif.data,
      ImageHeight: data.ClassicBanner && data.ClassicBanner.ImageHeight,
      ImageWidth: data.ClassicBanner && data.ClassicBanner.ImageWidth,
    },
    Classic: {
      id: data.Classic && data.Classic.id,
      title: data.Classic && data.Classic.Title,
      subTitle: data.Classic && data.Classic.SubTitle,
      links:
        data.Classic.ClassicSlider &&
        data.Classic.ClassicSlider.map((lin) => ({
          imageData: lin.Image.data && lin.Image.data[0],
          objectType: lin.ObjectType,
          title: lin.Title,
          value: lin.Value,
          id: lin.id,
        })),
    },

    GrainFreeBanner: {
      id: data.GrainFreeBanner && data.GrainFreeBanner.id,
      title: data.GrainFreeBanner && data.GrainFreeBanner.Links.Title,
      subTitle: data.GrainFreeBanner && data.GrainFreeBanner.Links.SubTitle,
      ImageHeight: data.GrainFreeBanner && data.GrainFreeBanner.ImageHeight,
      ImageWidth: data.GrainFreeBanner && data.GrainFreeBanner.ImageWidth,
      value: data.GrainFreeBanner && data.GrainFreeBanner.Links.Value,
      objectType: data.GrainFreeBanner && data.GrainFreeBanner.Links.ObjectType,
      imageData:
        data.GrainFreeBanner.Links.Image.data &&
        data.GrainFreeBanner.Links.Image.data[0],
    },
    GrainFree: {
      id: data.GrainFree && data.GrainFree.id,
      title: data.GrainFree && data.GrainFree.Title,
      subTitle: data.GrainFree && data.GrainFree.SubTitle,
      links:
        data.GrainFree.GrainFreeLink &&
        data.GrainFree.GrainFreeLink.map((lin) => ({
          imageData: lin.Image.data && lin.Image.data[0],
          objectType: lin.ObjectType,
          title: lin.Title,
          value: lin.Value,
          id: lin.id,
        })),
    },
    WhatYouSee: {
      id: data.WhatYouSee && data.WhatYouSee.id,
      title: data.WhatYouSee && data.WhatYouSee.AllLink.Title,
      value: data.WhatYouSee && data.WhatYouSee.AllLink.Value,
      ImageHeight: data.WhatYouSee && data.WhatYouSee.ImageHeight,
      ImageWidth: data.WhatYouSee && data.WhatYouSee.ImageWidth,
      objectType: data.WhatYouSee && data.WhatYouSee.AllLink.ObjectType,
      imageData:
        data.WhatYouSee.AllLink.Image.data &&
        data.WhatYouSee.AllLink.Image.data[0],
    },
    WhatYouGet: {
      id: data.WhatYouGet && data.WhatYouGet.id,
      title: data.WhatYouGet && data.WhatYouGet.VideoLink.Title,
      value: data.WhatYouGet && data.WhatYouGet.VideoLink.Value,
      objectType: data.WhatYouGet && data.WhatYouGet.VideoLink.ObjectType,
      imageData:
        data.WhatYouGet.VideoLink.Video.data &&
        data.WhatYouGet.VideoLink.Video.data[0],
      ImageHeight: data.WhatYouGet && data.WhatYouGet.ImageHeight,
      ImageWidth: data.WhatYouGet && data.WhatYouGet.ImageWidth,
    },
    SingleImage: {
      id: data.SingleImage && data.SingleImage.id,
      title: data.SingleImage && data.SingleImage.Image.Title,
      value: data.SingleImage && data.SingleImage.Image.Value,
      ImageHeight: data.SingleImage && data.SingleImage.ImageHeight,
      ImageWidth: data.SingleImage && data.SingleImage.ImageWidth,
      objectType: data.SingleImage && data.SingleImage.Image.ObjectType,
      imageData:
        data.SingleImage.Image.Image.data &&
        data.SingleImage.Image.Image.data[0],
    },

    FAQ: {
      id: data.FAQ && data.FAQ.id,
      title: data.FAQ && data.FAQ.Title,
      subTitle: data.FAQ && data.FAQ.SubTitle,
      links:
        data.FAQ &&
        data.FAQ.FaqLinks.map((lin) => ({
          title: lin.Title,
          description: lin.Description,
          id: lin.id,
        })),
    },
  };
};

export const getFormattedDogUpdateParams = ({ data, dataKey }) => {
  switch (dataKey) {
    case 'BannerImage':
      return {
        BannerImage: {
          id: data.id,
          ImageHeight: data.ImageHeight,
          ImageWidth: data.ImageWidth,
          Link: {
            Id: data.id,
            Title: data.title,
            Value: data.value,
            ObjectType: data.objectType,
            Image: data.imageData,
          },
        },
      };

    case 'ClassicBanner':
      return {
        ClassicBanner: {
          id: data.id,
          ImageHeight: data.ImageHeight,
          ImageWidth: data.ImageWidth,
          ClassicLink: {
            Id: data.id,
            Title: data.title,
            Value: data.value,
            ObjectType: data.objectType,
            Image: data.imageData,
          },
          // ImageHeight: data.ImageHeight,
          // ImageWidth: data.ImageWidth,
        },
      };

    case 'Classic':
      return {
        Classic: {
          Id: data.id,
          Title: data.title,
          SubTitle: data.subTitle,
          ClassicSlider: data.links.map((lin) => ({
            ObjectType: lin.objectType,
            Title: lin.title,
            Value: lin.value,
            Id: lin.id,
            Image: lin.imageData,
          })),
        },
      };

    case 'GrainFreeBanner':
      return {
        GrainFreeBanner: {
          id: data.id,
          ImageHeight: data.ImageHeight,
          ImageWidth: data.ImageWidth,
          Links: {
            Id: data.id,
            Title: data.title,
            Value: data.value,
            ObjectType: data.objectType,
            Image: data.imageData,
          },
        },
      };

    case 'GrainFree':
      return {
        GrainFree: {
          Id: data.id,
          Title: data.title,
          ImageHeight: data.ImageHeight,
          ImageWidth: data.ImageWidth,
          SubTitle: data.subTitle,
          GrainFreeLink: data.links.map((lin) => ({
            Image: lin.imageData,
            ObjectType: lin.objectType,
            Title: lin.title,
            Value: lin.value,
            Id: lin.id,
          })),
        },
      };

    case 'WhatYouSee':
      return {
        WhatYouSee: {
          Id: data.id,
          ImageHeight: data.ImageHeight,
          ImageWidth: data.ImageWidth,
          AllLink: {
            Id: data.id,
            Title: data.title,
            Value: data.value,
            ObjectType: data.objectType,
            Image: data.imageData,
          },
        },
      };

    case 'WhatYouGet':
      return {
        WhatYouGet: {
          Id: data.id,
          ImageHeight: data.ImageHeight,
          ImageWidth: data.ImageWidth,
          VideoLink: {
            Id: data.id,
            Title: data.title,
            Value: data.value,
            ObjectType: data.objectType,
            Image: data.imageData,
          },
        },
      };

    case 'SingleImage':
      return {
        SingleImage: {
          Id: data.id,
          ImageHeight: data.ImageHeight,
          ImageWidth: data.ImageWidth,
          Image: {
            Id: data.id,
            Title: data.title,
            Value: data.value,
            ObjectType: data.objectType,
            Image: data.imageData,
          },
        },
      };

    case 'FAQ':
      return {
        FAQ: {
          Id: data.id,
          Title: data.title,
          SubTitle: data.subTitle,
          FaqLinks: data.links.map((lin) => ({
            Description: lin.description,
            Title: lin.title,
            Id: lin.id,
          })),
        },
      };

    default:
      return null;
  }
};
