import { NotificationManager } from 'components/common/react-notifications';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import configuredAxios, {
  accessRefreshToken,
  getAuthorizationHeader,
} from 'utils/Api';
import axios from 'utils/CmsApi';
import {
  GET_REWARD_LEVEL,
  PUBLISH_REWARD_LEVEL,
  UPDATE_REWARD_LEVEL,
} from '../actions';
import {
  getRewardLevelError,
  getRewardLevelSuccess,
  publishRewardLevelRequest,
  publishRewardLevelSuccess,
  publishRewardLevelError,
  updateRewardLevelError,
  updateRewardLevelSuccess,
} from './action';
import { formatRewardDataForTable, getDogTreatParams } from './selector';
import { validateAndGetFormattedDogUpdateParams } from './validator';

/**
 * Calls the API to get reward level request.
 *
 * @async
 * @function
 * @returns {Promise} The response from the API call.
 */
export const getRewardLevelRequest = async () => {
  accessRefreshToken();
  const response = await axios.get('/reward-level', getDogTreatParams());
  return response;
};

/**
 * Calls the API to update reward level request.
 *
 * @async
 * @function
 * @param {Object} data - The data to be updated.
 * @returns {Promise} The response from the API call.
 */
const updateRewardLevelRequest = async (data) => {
  accessRefreshToken();
  const body = await validateAndGetFormattedDogUpdateParams(data);
  const response = await axios.put('/reward-level', {
    data: body,
  });
  return response;
};

/**
 * Saga function to publish reward data.
 *
 * @generator
 * @function
 * @param {Object} data - The data to be published.
 */
const publishRewardRequest = async (data) => {
  const response = await configuredAxios.post('/pet/rewardLevels', data, {
    headers: {
      Authorization: getAuthorizationHeader(),
    },
  });
  return response;
};

/**
 * Saga function to fetch reward level data.
 *
 * @async
 * @function
 * @returns {Promise} The response from the API call.
 */
function* fetchRewardLevel() {
  try {
    const response = yield call(getRewardLevelRequest);
    if (response.data.data) {
      yield put(
        getRewardLevelSuccess(
          formatRewardDataForTable(response.data.data.attributes)
        )
      );
    } else {
      yield put(getRewardLevelError('no data received'));
    }
  } catch (error) {
    yield put(getRewardLevelError(error));
  }
}

/**
 * Calls the API to update reward level request.
 *
 * @async
 * @function
 * @param {Object} data - The data to be updated.
 * @returns {Promise} The response from the API call.
 */
function* publishRewardLevel(data) {
  yield put(publishRewardLevelRequest());
  try {
    const response = yield call(publishRewardRequest, data.payload);
    if (response) {
      NotificationManager.success(
        'Data Published successfully',
        'Data Published'
      );
      yield put(publishRewardLevelSuccess(response));
    } else {
      NotificationManager.error('Failed to Publish data', 'Data Published');
      yield put(publishRewardLevelError('no response received'));
    }
  } catch (error) {
    if (error.name === 'ValidationError') {
      NotificationManager.error(error.message, 'Data Updated');
    } else {
      NotificationManager.error('Failed to Published data', 'Data Published');
    }
    yield put(publishRewardLevelError(error));
  }
}

/**
 * Saga function to update reward level data.
 *
 * @generator
 * @function
 * @param {Object} data - The data to be updated.
 */
function* updateRewardLevel(data) {
  try {
    const response = yield call(updateRewardLevelRequest, data.payload);
    if (response) {
      NotificationManager.success('Data updated successfully', 'Data Updated');
      yield put(updateRewardLevelSuccess(response));
    } else {
      NotificationManager.error('Failed to update data', 'Data Updated');
      yield put(updateRewardLevelError('no response received'));
    }
  } catch (error) {
    if (error.name === 'ValidationError') {
      NotificationManager.error(error.message, 'Data Updated');
    } else {
      NotificationManager.error('Failed to update data', 'Data Updated');
    }
    yield put(updateRewardLevelError(error));
  }
}

export function* watchRewardLevel() {
  yield takeLatest(GET_REWARD_LEVEL, fetchRewardLevel);
}

export function* watchUpdateRewardLevel() {
  yield takeLatest(UPDATE_REWARD_LEVEL, updateRewardLevel);
}

export function* watchPublishRewardLevel() {
  yield takeLatest(PUBLISH_REWARD_LEVEL, publishRewardLevel);
}

/**
 * Root Saga that combines all sagas.
 *
 * @generator
 * @function
 */
export default function* rootSaga() {
  yield all([
    fork(watchRewardLevel),
    fork(watchUpdateRewardLevel),
    fork(watchPublishRewardLevel),
  ]);
}
