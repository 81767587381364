import {
  GET_SMALL_ANIMAL_DATA,
  GET_SMALL_ANIMAL_DATA_ERROR,
  GET_SMALL_ANIMAL_DATA_SUCCESS,
  UPDATE_SMALL_ANIMAL_DATA,
  UPDATE_SMALL_ANIMAL_DATA_ERROR,
  UPDATE_SMALL_ANIMAL_DATA_SUCCESS,
  PUBLISH_SMALL_ANIMAL_REQUEST,
  PUBLISH_SMALL_ANIMAL_DATA_SUCCESS,
  PUBLISH_SMALL_ANIMAL_DATA_ERROR,
} from '../actions';

const INIT_STATE = {
  birds: {},
  fishes: {},
  rabbits: {},
  singleVerticleBanner: {},
  loading: false,
  error: '',
  updateSuccess: false,
  isPublishing: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    // GET_SMALL_ANIMAL_DATA
    case GET_SMALL_ANIMAL_DATA:
      return { ...state, loading: true, updateSuccess: false };

    case GET_SMALL_ANIMAL_DATA_ERROR:
      return { ...state, loading: false, error: action.payload };

    case GET_SMALL_ANIMAL_DATA_SUCCESS:
      return { ...state, ...action.payload };

    // UPDATE_SMALL_ANIMAL_DATA
    case UPDATE_SMALL_ANIMAL_DATA:
      return { ...state, loading: true, updateSuccess: false };

    case UPDATE_SMALL_ANIMAL_DATA_SUCCESS:
      return { ...state, loading: false, error: false, updateSuccess: true };

    case UPDATE_SMALL_ANIMAL_DATA_ERROR:
      return { ...state, loading: false, error: true, updateSuccess: false };

    // PUBLISH_SMALL_ANIMAL_REQUEST
    case PUBLISH_SMALL_ANIMAL_REQUEST:
      return {
        ...state,
        isPublishing: true,
        error: false,
      };

    case PUBLISH_SMALL_ANIMAL_DATA_SUCCESS:
      return {
        ...state,
        isPublishing: false,
        error: false,
      };

    case PUBLISH_SMALL_ANIMAL_DATA_ERROR:
      return {
        ...state,
        isPublishing: false,
        error: true,
      };

    default:
      return { ...state };
  }
};
