import {
  GET_DOG_DATA,
  GET_DOG_DATA_ERROR,
  GET_DOG_DATA_SUCCESS,
  UPDATE_DOG_DATA,
  UPDATE_DOG_DATA_ERROR,
  UPDATE_DOG_DATA_SUCCESS,
  PUBLISH_DOG_DATA,
  PUBLISH_DOG_DATA_ERROR,
  PUBLISH_DOG_DATA_SUCCESS,
  PUBLISH_DOG_REQUEST,
} from 'redux/actions';

// GET_DOG_DATA
export const getDogData = () => ({
  type: GET_DOG_DATA,
});

export const getDogDataError = (payload) => ({
  type: GET_DOG_DATA_ERROR,
  payload,
});

export const getDogDataSuccess = (payload) => ({
  type: GET_DOG_DATA_SUCCESS,
  payload,
});

// UPDATE_DOG_DATA
export const updateDogData = (payload) => ({
  type: UPDATE_DOG_DATA,
  payload,
});

export const updateDogDataSuccess = (payload) => ({
  type: UPDATE_DOG_DATA_SUCCESS,
  payload,
});

export const updateDogDataError = (payload) => ({
  type: UPDATE_DOG_DATA_ERROR,
  payload,
});

// PUBLISH_DOG_DATA
export const publishDogData = (payload) => ({
  type: PUBLISH_DOG_DATA,
  payload,
});

export const publishDogRequest = (payload) => ({
  type: PUBLISH_DOG_REQUEST,
  payload,
});

export const publishDogDataSuccess = (payload) => ({
  type: PUBLISH_DOG_DATA_SUCCESS,
  payload,
});

export const publishDogDataError = (payload) => ({
  type: PUBLISH_DOG_DATA_ERROR,
  payload,
});
