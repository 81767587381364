// call get api params
export const getSearchParams = () => ({
  params: {
    populate:
      'FeaturedBrands,FeaturedBrands.Link,FeaturedBrands.Link.Image,ExploreTopOffers',
  },
});

// format get api data
export const formatSearchData = (data) => {
  const versionData = {
    ...data,
    versionNumber: 16,
    vuid: '581e26a0-ef39-4374-978e-dbd566386765',
  };
  delete versionData.createdAt;
  delete versionData.updatedAt;
  delete versionData.publishedAt;
  const ans = {
    versionData,
    vuid: {
      vuid: data.vuid,
    },
    featuredBrands: {
      id: data.FeaturedBrands && data.FeaturedBrands.id,
      title: data.FeaturedBrands && data.FeaturedBrands.Title,
      subTitle: data.FeaturedBrands && data.FeaturedBrands.SubTitle,
      ImageHeight: data.FeaturedBrands && data.FeaturedBrands.ImageHeight,
      ImageWidth: data.FeaturedBrands && data.FeaturedBrands.ImageWidth,
      links:
        data.FeaturedBrands &&
        data.FeaturedBrands.Link.map((lin) => ({
          imageData: lin.Image.data,
          objectType: lin.ObjectType,
          title: lin.Title,
          value: lin.Value,
          id: lin.id,
        })),
    },
    exploreTopOffers: {
      id: data.ExploreTopOffers && data.ExploreTopOffers.id,
      title: data.ExploreTopOffers && data.ExploreTopOffers.Title,
      collectionHandle:
        data.ExploreTopOffers && data.ExploreTopOffers.CollectionHandle,
    },
  };
  return ans;
};

export const getFormattedSearchUpdateParams = ({ data, dataKey }) => {
  switch (dataKey) {
    case 'featuredBrands':
      return {
        FeaturedBrands: {
          Id: data.id,
          Title: data.title,
          SubTitle: data.subTitle,
          ImageHeight: data.ImageHeight,
          ImageWidth: data.ImageWidth,
          Link: data.links.map((lin) => ({
            Image: lin.imageData,
            ObjectType: lin.objectType,
            Title: lin.title,
            Value: lin.value,
            Id: lin.id,
          })),
        },
      };
    case 'exploreTopOffers':
      return {
        ExploreTopOffers: {
          Id: data.id,
          Title: data.title,
          CollectionHandle: data.collectionHandle,
        },
      };

    default:
      return null;
  }
};
