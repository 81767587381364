import axios from 'axios';

const { REACT_APP_CMS_API_APP_URL, REACT_APP_CMS_API_TOKEN } = process.env;

const configuredAxios = axios.create({
  /**
   * The base URL for API requests.
   * @type {string}
   */
  baseURL: REACT_APP_CMS_API_APP_URL,
  /**
   * The headers to be included in the API requests.
   * @type {Object}
   */
  headers: {
    Authorization: `Bearer  ${REACT_APP_CMS_API_TOKEN}`,
  },
});

export default configuredAxios;
