import {
  GET_REWARD_DATA,
  GET_REWARD_DATA_ERROR,
  GET_REWARD_DATA_SUCCESS,
  UPDATE_REWARD_DATA,
  UPDATE_REWARD_DATA_ERROR,
  UPDATE_REWARD_DATA_SUCCESS,
  PUBLISH_REWARD_DATA_REQUEST,
  PUBLISH_REWARD_DATA_SUCCESS,
  PUBLISH_REWARD_DATA_ERROR,
} from '../actions';

const INIT_STATE = {
  users: {},
  rewardDataForTable: [],
  rewardLevels: [],
  rewardLandingPage: [],
  loading: false,
  isPublishing: false,
  error: false,
  updateSuccess: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_REWARD_DATA:
      return { ...state, loading: true, updateSuccess: false };

    case GET_REWARD_DATA_ERROR:
      return { ...state, loading: false, error: action.payload };

    case GET_REWARD_DATA_SUCCESS:
      return { ...state, ...action.payload };

    // UPDATE_REWARD_DATA
    case UPDATE_REWARD_DATA:
      return { ...state, loading: true, updateSuccess: false };

    case UPDATE_REWARD_DATA_SUCCESS:
      return { ...state, loading: false, error: false, updateSuccess: true };

    case UPDATE_REWARD_DATA_ERROR:
      return { ...state, loading: false, error: true, updateSuccess: false };

    // PUBLISH_REWARD_DATA_REQUEST
    case PUBLISH_REWARD_DATA_REQUEST:
      return {
        ...state,
        isPublishing: true,
        error: false,
      };

    case PUBLISH_REWARD_DATA_SUCCESS:
      return {
        ...state,
        isPublishing: false,
        error: false,
      };

    case PUBLISH_REWARD_DATA_ERROR:
      return {
        ...state,
        isPublishing: false,
        error: true,
      };

    default:
      return { ...state };
  }
};
