import {
  GET_DOG_DATA,
  GET_DOG_DATA_ERROR,
  GET_DOG_DATA_SUCCESS,
  UPDATE_DOG_DATA,
  UPDATE_DOG_DATA_ERROR,
  UPDATE_DOG_DATA_SUCCESS,
  PUBLISH_DOG_REQUEST,
  PUBLISH_DOG_DATA_SUCCESS,
  PUBLISH_DOG_DATA_ERROR,
} from 'redux/actions';

const INIT_STATE = {
  dogs: {},
  topPicks: {},
  singleVerticleBanner: {},
  carouselBannerImages: {},
  swipeSectionImages: {},
  dealsOfDay: {},
  subscriptions: {},
  bannerScrollSection: {},
  bankOffers: {},
  spaBookingBanner: {},
  brandCarousel: {},
  combosAndKits: {},
  ecoConscious: {},
  locateStore: {},
  foundationBanner: {},
  shopByConcern: {},
  bestFoodBrands: {},
  joinPuppyClub: {},
  knowAboutYourPet: {},
  petBirthday: {},
  giftCards: {},
  loading: false,
  isPublishing: false,
  error: false,
  updateSuccess: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    // GET_DOG_DATA
    case GET_DOG_DATA:
      return { ...state, loading: true, updateSuccess: false };

    case GET_DOG_DATA_ERROR:
      return { ...state, loading: false, error: action.payload };

    case GET_DOG_DATA_SUCCESS:
      return { ...state, ...action.payload };

    // UPDATE_DOG_DATA
    case UPDATE_DOG_DATA:
      return { ...state, loading: true, updateSuccess: false };

    case UPDATE_DOG_DATA_SUCCESS:
      return { ...state, loading: false, error: false, updateSuccess: true };

    case UPDATE_DOG_DATA_ERROR:
      return { ...state, loading: false, error: true, updateSuccess: false };

    // PUBLISH_DOG_REQUEST
    case PUBLISH_DOG_REQUEST:
      return {
        ...state,
        isPublishing: true,
        error: false,
      };

    case PUBLISH_DOG_DATA_SUCCESS:
      return {
        ...state,
        isPublishing: false,
        error: false,
      };

    case PUBLISH_DOG_DATA_ERROR:
      return {
        ...state,
        isPublishing: false,
        error: true,
      };

    default:
      return { ...state };
  }
};
