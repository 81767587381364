import * as Yup from 'yup';
import { getFormattedDogUpdateParams } from './selector';

const testimonialValidateParamsBody = Yup.object().shape({
  title: Yup.string()
    .required('Title is required')
    .test(
      'title',
      'Title must be less then 50 character',
      (title) => title.length <= 50
    ),
  description: Yup.string().required('Description is required'),

  imageData: Yup.object()
    .required('Image cannot be empty')
    .test('imageData', 'Image must be there', (imageData) => !!imageData?.id),

  buttonTitle: Yup.string()
    .required('ButtonTitle is required')
    .test(
      'ButtonTitle',
      'ButtonTitle must be less then 50 character',
      (buttonTitle) => buttonTitle.length <= 50
    ),
});

const howItWorksValidateParamsBody = Yup.object().shape({
  title: Yup.string()
    .required('Title is required')
    .test(
      'title',
      'Title must be less then 50 character',
      (title) => title.length <= 50
    ),
  //   subTitle: Yup.string()
  //     .required('SubTitle is required')
  //     .test(
  //       'subTitle',
  //       'Title must be less then 50 character',
  //       (title) => title.length <= 50
  //     ),
  links: Yup.array().of(
    Yup.object().shape({
      title: Yup.string()
        .required('Title is required')
        .test(
          'title',
          'Title must be less then 50 character',
          (title) => title.length <= 50
        ),
      description: Yup.string().required('Description is required'),

      imageData: Yup.object()
        .required('Image cannot be empty')
        .test(
          'imageData',
          'Image must be there',
          (imageData) => !!imageData?.id
        ),
    })
  ),
});

const rewardTypesValidateParamsBody = Yup.object().shape({
  title: Yup.string()
    .required('Title is required')
    .test(
      'title',
      'Title must be less then 50 character',
      (title) => title.length <= 50
    ),
  silverImage: Yup.object()
    .required('Image cannot be empty')
    .test(
      'imageData',
      'silverImage must be there',
      (silverImage) => !!silverImage?.id
    ),

  bronzeImage: Yup.object()
    .required('Image cannot be empty')
    .test(
      'imageData',
      'bronzeImage must be there',
      (bronzeImage) => !!bronzeImage?.id
    ),

  goldImage: Yup.object()
    .required('Image cannot be empty')
    .test(
      'imageData',
      'goldImage must be there',
      (goldImage) => !!goldImage?.id
    ),
});

// eslint-disable-next-line import/prefer-default-export
export const validateAndGetFormattedDogUpdateParams = async (obj) => {
  switch (obj.dataKey) {
    case 'HUFTReward':
      await testimonialValidateParamsBody.validate(obj.data);
      break;
    case 'HowItWorks':
      await howItWorksValidateParamsBody.validate(obj.data);
      break;
    case 'RewardTypes':
      await rewardTypesValidateParamsBody.validate(obj.data);
      break;

    default:
      break;
  }
  return getFormattedDogUpdateParams(obj);
};
