import * as Yup from 'yup';
import { getFormattedDogUpdateParams } from './selector';

const goldValidateParamsBody = Yup.object().shape({
  title: Yup.string()
    .required('Title is required')
    .test(
      'subTitle',
      'Title must be less then 50 character',
      (title) => title.length <= 50
    ),

  frontImage: Yup.object().required('Image cannot be empty'),

  backImage: Yup.object().required('Image cannot be empty'),
  progressBarImage: Yup.object().required('Image cannot be empty'),
  links: Yup.array().of(
    Yup.object().shape({
      title: Yup.string()
        .required('Title is required')
        .test(
          'title',
          'Title must be less then 50 character',
          (title) => title.length <= 50
        ),
      description: Yup.string().required('Description is required'),

      imageData: Yup.object()
        .required('Image cannot be empty')
        .test(
          'imageData',
          'Image must be there',
          (imageData) => !!imageData?.id
        ),
    })
  ),
});

const additionalInfoValidateParamsBody = Yup.object().shape({
  buttonText: Yup.string().required('buttonText is required'),

  title: Yup.string()
    .required('Title is required')
    .test(
      'subTitle',
      'Title must be less then 50 character',
      (title) => title.length <= 50
    ),

  links: Yup.array().of(
    Yup.object().shape({
      title: Yup.string()
        .required('Title is required')
        .test(
          'title',
          'Title must be less then 50 character',
          (title) => title.length <= 50
        ),
    })
  ),
});

// eslint-disable-next-line import/prefer-default-export
export const validateAndGetFormattedDogUpdateParams = async (obj) => {
  switch (obj.dataKey) {
    case 'Silver':
      await goldValidateParamsBody.validate(obj.data);
      break;
    case 'Bronze':
      await goldValidateParamsBody.validate(obj.data);
      break;
    case 'Gold':
      await goldValidateParamsBody.validate(obj.data);
      break;

    case 'AdditionalInfo':
      await additionalInfoValidateParamsBody.validate(obj.data);
      break;

    default:
      break;
  }
  return getFormattedDogUpdateParams(obj);
};
