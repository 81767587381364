import {
  DELETE_SHIPPING_DATA,
  DELETE_SHIPPING_DATA_ERROR,
  DELETE_SHIPPING_DATA_SUCCESS,
  GET_SHIPPING_DATA,
  GET_SHIPPING_DATA_ERROR,
  GET_SHIPPING_DATA_SUCCESS,
  UPDATE_SHIPPING_DATA,
  UPDATE_SHIPPING_DATA_ERROR,
  UPDATE_SHIPPING_DATA_SUCCESS,
  SET_CONFIRM_MODAL_FIELDS,
  SET_SHIPPING_MODAL,
  PUBLISH_SHIPPING_DATA,
  PUBLISH_SHIPPING_REQUEST,
  PUBLISH_SHIPPING_DATA_SUCCESS,
  PUBLISH_SHIPPING_DATA_ERROR,
  SET_SHIPPING_FIELD,
  SET_NEW_SHIPPING_FIELD,
  CREATE_SHIPPING,
  CREATE_SHIPPING_SUCCESS,
  CREATE_SHIPPING_ERROR,
} from 'redux/actions';

// GET_SHIPPING_DATA
export const getShippingData = () => {
  return {
    type: GET_SHIPPING_DATA,
  };
};

export const getShippingDataError = (payload) => ({
  type: GET_SHIPPING_DATA_ERROR,
  payload,
});

export const getShippingDataSuccess = (payload) => {
  return {
    type: GET_SHIPPING_DATA_SUCCESS,
    payload,
  };
};

// DELETE_SHIPPING_DATA
export const deleteShipping = (payload) => ({
  type: DELETE_SHIPPING_DATA,
  payload,
});

export const deleteShippingSuccess = (payload) => ({
  type: DELETE_SHIPPING_DATA_SUCCESS,
  payload,
});

export const deleteShippingError = (payload) => ({
  type: DELETE_SHIPPING_DATA_ERROR,
  payload,
});

// UPDATE_SHIPPING_DATA
export const updateShippingData = (payload) => ({
  type: UPDATE_SHIPPING_DATA,
  payload,
});

export const updateShippingDataSuccess = (payload) => ({
  type: UPDATE_SHIPPING_DATA_SUCCESS,
  payload,
});

export const updateShippingDataError = (payload) => ({
  type: UPDATE_SHIPPING_DATA_ERROR,
  payload,
});

// CONFIRM_MODAL_DATA
export const setConfirmModalFields = (payload) => ({
  type: SET_CONFIRM_MODAL_FIELDS,
  payload,
});

export const setShippingModal = (payload) => ({
  type: SET_SHIPPING_MODAL,
  payload,
});

// PUBLISH_SHIPPING_DATA
export const publishShippingData = (payload) => ({
  type: PUBLISH_SHIPPING_DATA,
  payload,
});

export const publishShippingRequest = (payload) => ({
  type: PUBLISH_SHIPPING_REQUEST,
  payload,
});

export const publishShippingDataSuccess = (payload) => ({
  type: PUBLISH_SHIPPING_DATA_SUCCESS,
  payload,
});

export const publishShippingDataError = (payload) => ({
  type: PUBLISH_SHIPPING_DATA_ERROR,
  payload,
});

// SET_SHIPPING_FIELD
export const setShippingField = (payload) => ({
  type: SET_SHIPPING_FIELD,
  payload,
});

export const setNewShippingField = (payload) => ({
  type: SET_NEW_SHIPPING_FIELD,
  payload,
});

// CREATE_SHIPPING
export const createShipping = (payload) => ({
  type: CREATE_SHIPPING,
  payload,
});

export const createShippingSuccess = (payload) => ({
  type: CREATE_SHIPPING_SUCCESS,
  payload,
});

export const createShippingError = (payload) => ({
  type: CREATE_SHIPPING_ERROR,
  payload,
});
