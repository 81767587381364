import {
  DELETE_COURIER,
  DELETE_COURIER_ERROR,
  DELETE_COURIER_SUCCESS,
  SET_NEW_COURIER_FIELD,
  SET_COURIER_FIELD,
  SET_COURIER_MODAL,
  UPDATE_COURIER,
  UPDATE_COURIER_ERROR,
  UPDATE_COURIER_SUCCESS,
  COURIER_GET_LIST,
  COURIER_GET_LIST_ERROR,
  COURIER_GET_LIST_SUCCESS,
  PUBLISH_COURIER_DATA,
  PUBLISH_COURIER_DATA_SUCCESS,
  PUBLISH_COURIER_DATA_ERROR,
  PUBLISH_COURIER_REQUEST,
  CREATE_COURIER,
  CREATE_COURIER_ERROR,
  CREATE_COURIER_SUCCESS,
} from 'redux/actions';

// COURIER_GET_LIST
export const getCourierList = () => ({
  type: COURIER_GET_LIST,
});

export const getCourierListSuccess = (payload) => {
  return {
    type: COURIER_GET_LIST_SUCCESS,
    payload,
  };
};

export const getCourierListError = (payload) => {
  return {
    type: COURIER_GET_LIST_ERROR,
    payload,
  };
};

// Open courier modal
export const setCourierModal = (payload) => ({
  type: SET_COURIER_MODAL,
  payload,
});

export const setNewCourierField = (payload) => ({
  type: SET_NEW_COURIER_FIELD,
  payload,
});

// delete courier
export const deleteCourier = (payload) => ({
  type: DELETE_COURIER,
  payload,
});

export const deleteCourierSuccess = (payload) => ({
  type: DELETE_COURIER_SUCCESS,
  payload,
});

export const deleteCourierError = (payload) => ({
  type: DELETE_COURIER_ERROR,
  payload,
});

// update courier
export const updateCourier = (payload) => ({
  type: UPDATE_COURIER,
  payload,
});

export const updateCourierSuccess = (payload) => ({
  type: UPDATE_COURIER_SUCCESS,
  payload,
});

export const updateCourierError = (payload) => ({
  type: UPDATE_COURIER_ERROR,
  payload,
});

// set courier
export const setCourierField = (payload) => ({
  type: SET_COURIER_FIELD,
  payload,
});

// publish courier data
export const publishCourierRequest = (payload) => ({
  type: PUBLISH_COURIER_REQUEST,
  payload,
});

export const publishCourierData = (payload) => ({
  type: PUBLISH_COURIER_DATA,
  payload,
});

export const publishCourierDataSuccess = (payload) => ({
  type: PUBLISH_COURIER_DATA_SUCCESS,
  payload,
});

export const publishCourierDataError = (payload) => ({
  type: PUBLISH_COURIER_DATA_ERROR,
  payload,
});

// create courier
export const createCourier = (payload) => ({
  type: CREATE_COURIER,
  payload,
});

export const createCourierSuccess = (payload) => ({
  type: CREATE_COURIER_SUCCESS,
  payload,
});

export const createCourierError = (payload) => ({
  type: CREATE_COURIER_ERROR,
  payload,
});
