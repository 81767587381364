import {
  GET_SARA_WHOLESOME,
  GET_SARA_WHOLESOME_ERROR,
  GET_SARA_WHOLESOME_SUCCESS,
  UPDATE_SARA_WHOLESOME,
  UPDATE_SARA_WHOLESOME_ERROR,
  UPDATE_SARA_WHOLESOME_SUCCESS,
  PUBLISH_SARA_WHOLESOME,
  PUBLISH_SARA_WHOLESOME_ERROR,
  PUBLISH_SARA_WHOLESOME_SUCCESS,
  PUBLISH_SARA_WHOLESOME_REQUEST,
} from 'redux/actions';

// GET_SARA_WHOLESOME
export const getSaraWholesome = () => ({
  type: GET_SARA_WHOLESOME,
});

export const getSaraWholesomeError = (payload) => ({
  type: GET_SARA_WHOLESOME_ERROR,
  payload,
});

export const getSaraWholesomeSuccess = (payload) => ({
  type: GET_SARA_WHOLESOME_SUCCESS,
  payload,
});

// UPDATE_SARA_WHOLESOME
export const updateSaraWholesome = (payload) => ({
  type: UPDATE_SARA_WHOLESOME,
  payload,
});

export const updateSaraWholesomeSuccess = (payload) => ({
  type: UPDATE_SARA_WHOLESOME_SUCCESS,
  payload,
});

export const updateSaraWholesomeError = (payload) => ({
  type: UPDATE_SARA_WHOLESOME_ERROR,
  payload,
});

// PUBLISH_SARA_WHOLESOME
export const publishSaraWholesome = (payload) => ({
  type: PUBLISH_SARA_WHOLESOME,
  payload,
});

export const publishSaraWholesomeRequest = (payload) => ({
  type: PUBLISH_SARA_WHOLESOME_REQUEST,
  payload,
});

export const publishSaraWholesomeSuccess = (payload) => ({
  type: PUBLISH_SARA_WHOLESOME_SUCCESS,
  payload,
});

export const publishSaraWholesomeError = (payload) => ({
  type: PUBLISH_SARA_WHOLESOME_ERROR,
  payload,
});
