import {
  CREATE_USER,
  CREATE_USER_ERROR,
  CREATE_USER_SUCCESS,
  DELETE_USER,
  DELETE_USER_ERROR,
  DELETE_USER_SUCCESS,
  SET_NEW_USER_FIELD,
  SET_USER_FIELD,
  SET_USER_MODAL,
  UPDATE_USER,
  UPDATE_USER_ERROR,
  UPDATE_USER_SUCCESS,
  USERS_GET_LIST,
  USERS_GET_LIST_ERROR,
  USERS_GET_LIST_SUCCESS,
} from '../actions';

const INIT_STATE = {
  users: [],
  loading: false,
  showUserModal: false,
  newUser: {
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    role: '',
  },
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    // USERS_GET_LIST
    case USERS_GET_LIST:
      return { ...state, loading: true };

    case USERS_GET_LIST_SUCCESS:
      return { ...state, loading: false, users: action.payload };

    case USERS_GET_LIST_ERROR:
      return { ...state, loading: false, error: action.payload };

    // SET_USER_MODAL
    case SET_USER_MODAL:
      return { ...state, showUserModal: action.payload };

    case SET_NEW_USER_FIELD:
      return { ...state, newUser: { ...state.newUser, ...action.payload } };

    case SET_USER_FIELD:
      return { ...state, ...action.payload };

    // CREATE_USER
    case CREATE_USER:
      return { ...state, loading: true };

    case CREATE_USER_SUCCESS:
      return { ...state, newUser: {}, loading: false };

    case CREATE_USER_ERROR:
      return { ...state, loading: false, error: action.payload };

    // DELETE_USER
    case DELETE_USER:
      return { ...state, loading: true };

    case DELETE_USER_SUCCESS:
      return { ...state, loading: false };

    case DELETE_USER_ERROR:
      return { ...state, loading: false, error: action.payload };

    // UPDATE_USER
    case UPDATE_USER:
      return { ...state, loading: true };

    case UPDATE_USER_SUCCESS:
      return { ...state, loading: false, newUser: {} };

    case UPDATE_USER_ERROR:
      return { ...state, loading: false, error: action.payload };

    default:
      return { ...state };
  }
};
