import {
  GET_REWARD_LEVEL,
  GET_REWARD_LEVEL_ERROR,
  GET_REWARD_LEVEL_SUCCESS,
  UPDATE_REWARD_LEVEL,
  UPDATE_REWARD_LEVEL_SUCCESS,
  UPDATE_REWARD_LEVEL_ERROR,
  PUBLISH_REWARD_LEVEL,
  PUBLISH_REWARD_LEVEL_REQUEST,
  PUBLISH_REWARD_LEVEL_SUCCESS,
  PUBLISH_REWARD_LEVEL_ERROR,
} from 'redux/actions';

export const getRewardLevel = () => ({
  type: GET_REWARD_LEVEL,
});

export const getRewardLevelError = (payload) => ({
  type: GET_REWARD_LEVEL_ERROR,
  payload,
});

export const getRewardLevelSuccess = (payload) => ({
  type: GET_REWARD_LEVEL_SUCCESS,
  payload,
});

// UPDATE_DOG_TREAT

export const updateRewardLevel = (payload) => ({
  type: UPDATE_REWARD_LEVEL,
  payload,
});

export const updateRewardLevelSuccess = (payload) => ({
  type: UPDATE_REWARD_LEVEL_SUCCESS,
  payload,
});

export const updateRewardLevelError = (payload) => ({
  type: UPDATE_REWARD_LEVEL_ERROR,
  payload,
});

// PUBLISH_DOG_TREAT

export const publishRewardLevel = (payload) => ({
  type: PUBLISH_REWARD_LEVEL,
  payload,
});

export const publishRewardLevelRequest = (payload) => ({
  type: PUBLISH_REWARD_LEVEL_REQUEST,
  payload,
});

export const publishRewardLevelSuccess = (payload) => ({
  type: PUBLISH_REWARD_LEVEL_SUCCESS,
  payload,
});

export const publishRewardLevelError = (payload) => ({
  type: PUBLISH_REWARD_LEVEL_ERROR,
  payload,
});
