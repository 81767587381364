// format user list data

export const formatRewardConditionData = (data = []) => {
  return {
    data: data?.map((d) => ({
      id: d.id,
      title: d.attributes.Title,
      createDate: d.attributes.createdAt,
      updateDate: d.attributes.updatedAt,
      publishedAt: d.attributes.publishedAt, // not sure for this value, in future we'll check & change it
    })),
  };
};

// format create user data
export const formattedCreateUserApiReq = (data) => ({
  data: {
    id: data.id,
    Title: data.title,
  },
});

// format update user data
export const formattedUpdateUserApiReq = (data) => ({
  data: {
    id: data.id,
    Title: data.title,
  },
});
