// get api params

export const getDogTreatParams = () => ({
  params: {
    populate: 'RewardFAQ.Link',
  },
});
export const formatRewardDataForTable = (data = []) => {
  return {
    vuid: {
      vuid: data.vuid,
    },

    RewardFAQ: {
      id: data.RewardFAQ && data.RewardFAQ.id,
      title: data.RewardFAQ && data.RewardFAQ.Title,
      // subTitle: data.RewardFAQ && data.RewardFAQ.SubTitle,
      links:
        data?.RewardFAQ?.Link &&
        data?.RewardFAQ?.Link.map((d) => ({
          id: d.id,
          title: d.Title,
          description: d.Description,
        })),
    },
  };
};

export const getFormattedDogUpdateParams = ({ data, dataKey }) => {
  switch (dataKey) {
    case 'RewardFAQ':
      return {
        rewardFAQ: {
          Id: data.id,
          // Title: data.title,
          // SubTitle: data.subTitle,
          Link: data.links.map((lin) => ({
            Description: lin.description,
            Title: lin.title,
            Id: lin.id,
          })),
        },
      };

    default:
      return null;
  }
};
