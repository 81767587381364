import {
  GET_SMALL_ANIMAL_DATA,
  GET_SMALL_ANIMAL_DATA_ERROR,
  GET_SMALL_ANIMAL_DATA_SUCCESS,
  PUBLISH_SMALL_ANIMAL_DATA,
  PUBLISH_SMALL_ANIMAL_REQUEST,
  PUBLISH_SMALL_ANIMAL_DATA_ERROR,
  PUBLISH_SMALL_ANIMAL_DATA_SUCCESS,
  UPDATE_SMALL_ANIMAL_DATA,
  UPDATE_SMALL_ANIMAL_DATA_ERROR,
  UPDATE_SMALL_ANIMAL_DATA_SUCCESS,
} from 'redux/actions';

// GET_SMALL_ANIMAL_DATA
export const getSmallAnimalData = () => ({
  type: GET_SMALL_ANIMAL_DATA,
});

export const getSmallAnimalDataError = (payload) => ({
  type: GET_SMALL_ANIMAL_DATA_ERROR,
  payload,
});

export const getSmallAnimalDataSuccess = (payload) => ({
  type: GET_SMALL_ANIMAL_DATA_SUCCESS,
  payload,
});

// UPDATE_SMALL_ANIMAL_DATA
export const updateSmallAnimalData = (payload) => ({
  type: UPDATE_SMALL_ANIMAL_DATA,
  payload,
});

export const updateSmallAnimalDataSuccess = (payload) => ({
  type: UPDATE_SMALL_ANIMAL_DATA_SUCCESS,
  payload,
});

export const updateSmallAnimalDataError = (payload) => ({
  type: UPDATE_SMALL_ANIMAL_DATA_ERROR,
  payload,
});

export const publishSmallAnimalData = (payload) => ({
  type: PUBLISH_SMALL_ANIMAL_DATA,
  payload,
});

// PUBLISH_SMALL_ANIMAL_DATA
export const publishSmallAnimalRequest = (payload) => ({
  type: PUBLISH_SMALL_ANIMAL_REQUEST,
  payload,
});

export const publishSmallAnimalDataSuccess = (payload) => ({
  type: PUBLISH_SMALL_ANIMAL_DATA_SUCCESS,
  payload,
});

export const publishSmallAnimalDataError = (payload) => ({
  type: PUBLISH_SMALL_ANIMAL_DATA_ERROR,
  payload,
});
