import * as Yup from 'yup';
import { getFormattedDogUpdateParams } from './selector';

// form validation
const linkValidateParamsBody = Yup.object().shape({
  title: Yup.string()
    .required('Title is required')
    .test(
      'title',
      'Title must be less then 50 character',
      (title) => title.length <= 50
    ),
  value: Yup.string()
    .required('Value is required')
    .test(
      'value',
      'Value must be less the 50 character',
      (value) => value.length <= 50
    ),
  objectType: Yup.string().required('Object type is reuqired'),
  imageData: Yup.object()
    .required('Image cannot be empty')
    .test('imageData', 'Image must be there', (imageData) => !!imageData.id),
});

// const testimonialValidateParamsBody = Yup.object().shape({
//   title: Yup.string()
//     .required('Title is required')
//     .test(
//       'title',
//       'Title must be less then 50 character',
//       (title) => title.length <= 50
//     ),
//   subTitle: Yup.string()
//     .required('SubTitle is required')
//     .test(
//       'subTitle',
//       'Title must be less then 50 character',
//       (title) => title.length <= 50
//     ),
//   links: Yup.array().of(
//     Yup.object().shape({
//       description: Yup.string().required('Description is reuqired'),
//     })
//   ),
// });

const swipeValidateParamsBody = Yup.object().shape({
  title: Yup.string()
    .required('Title is required')
    .test(
      'title',
      'Title must be less then 50 character',
      (title) => title.length <= 50
    ),
  subTitle: Yup.string()
    .required('SubTitle is required')
    .test(
      'subTitle',
      'Title must be less then 50 character',
      (title) => title.length <= 50
    ),
  links: Yup.array().of(
    Yup.object().shape({
      title: Yup.string()
        .required('Title is required')
        .test(
          'title',
          'Title must be less then 50 character',
          (title) => title.length <= 50
        ),
      value: Yup.string()
        .required('Value is required')
        .test(
          'value',
          'Value must be less the 50 character',
          (value) => value.length <= 50
        ),
      objectType: Yup.string().required('Object type is reuqired'),
      imageData: Yup.object()
        .required('Image cannot be empty')
        .test(
          'imageData',
          'Image must be there',
          (imageData) => !!imageData?.id
        ),
    })
  ),
});

const ShopByAddedValidateParamsBody = Yup.object().shape({
  title: Yup.string()
    .required('Title is required')
    .test(
      'title',
      'Title must be less then 50 character',
      (title) => title.length <= 50
    ),
  subTitle: Yup.string()
    .required('SubTitle is required')
    .test(
      'subTitle',
      'SubTitle must be less then 50 character',
      (title) => title.length <= 50
    ),
  links: Yup.array().of(
    Yup.object().shape({
      title: Yup.string()
        .required('Title is required')
        .test(
          'title',
          'Title must be less then 50 character',
          (title) => title.length <= 50
        ),
      description: Yup.string()
        .required('Description is required')
        .test(
          'description',
          'Description must be less the 50 character',
          (description) => description.length <= 50
        ),
    })
  ),
});

// const swipeValidateTopPicksParamsBody = Yup.object().shape({
//   title: Yup.string()
//     .required('Title is required')
//     .test(
//       'title',
//       'Title must be less then 50 character',
//       (title) => title.length <= 50
//     ),
//   subTitle: Yup.string()
//     .required('SubTitle is required')
//     .test(
//       'subTitle',
//       'Title must be less then 50 character',
//       (title) => title.length <= 50
//     ),
//   links: Yup.array().of(
//     Yup.object().shape({
//       title: Yup.string()
//         .required('Title is required')
//         .test(
//           'title',
//           'Title must be less then 50 character',
//           (title) => title.length <= 50
//         ),
//       value: Yup.string()
//         .required('Value is required')
//         .test(
//           'value',
//           'Value must be less the 50 character',
//           (value) => value.length <= 50
//         ),
//       objectType: Yup.string().required('Object type is reuqired'),
//     })
//   ),
// });

// eslint-disable-next-line import/prefer-default-export
export const validateAndGetFormattedDogUpdateParams = async (obj) => {
  switch (obj.dataKey) {
    case 'Banner':
      await linkValidateParamsBody.validate(obj.data);
      break;

    case 'DogGrooming':
      await linkValidateParamsBody.validate(obj.data);
      break;

    case 'DogGroomingSlider':
      await swipeValidateParamsBody.validate(obj.data);
      break;

    case 'Shampoos':
      await swipeValidateParamsBody.validate(obj.data);
      break;

    case 'GroomingEssentialBanner':
      await linkValidateParamsBody.validate(obj.data);
      break;

    case 'GroomingSlider':
      await swipeValidateParamsBody.validate(obj.data);
      break;

    case 'BestSeller':
      await swipeValidateParamsBody.validate(obj.data);
      break;

    case 'ShopByConcernBanner':
      await linkValidateParamsBody.validate(obj.data);
      break;

    case 'ShopByConcern':
      await ShopByAddedValidateParamsBody.validate(obj.data);
      break;

    case 'MostLovedBanner':
      await linkValidateParamsBody.validate(obj.data);
      break;

    case 'MostLovedBrand':
      await swipeValidateParamsBody.validate(obj.data);
      break;

    case 'ProductsInFocus':
      await swipeValidateParamsBody.validate(obj.data);
      break;

    case 'DeliveryType':
      await swipeValidateParamsBody.validate(obj.data);
      break;

    default:
      break;
  }

  return getFormattedDogUpdateParams(obj);
};
