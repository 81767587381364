import { NotificationManager } from 'components/common/react-notifications';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import configuredAxios, {
  accessRefreshToken,
  getAuthorizationHeader,
} from 'utils/Api';
import axios from 'utils/CmsApi';
import {
  GET_SARA_WHOLESOME,
  PUBLISH_SARA_WHOLESOME,
  UPDATE_SARA_WHOLESOME,
} from '../actions';
import {
  getSaraWholesomeError,
  getSaraWholesomeSuccess,
  publishSaraWholesomeRequest,
  publishSaraWholesomeSuccess,
  publishSaraWholesomeError,
  updateSaraWholesomeError,
  updateSaraWholesomeSuccess,
} from './action';
import { formatSaraWholesome, getSaraWholesomeParams } from './selector';
import { validateAndGetFormattedDogUpdateParams } from './validator';

/**
 * Calls the API to getSaraWholesomeRequest.
 *
 * @async
 * @function
 * @returns {Promise} The response from the API call.
 */
export const getSaraWholesomeRequest = async () => {
  accessRefreshToken();
  const response = await axios.get('/sara-wholesome', getSaraWholesomeParams());
  return response;
};

/**
 * Calls the API to updateSaraWholesomeRequest.
 *
 * @async
 * @function
 * @returns {Promise} The response from the API call.
 */
const updateSaraWholesomeRequest = async (data) => {
  accessRefreshToken();
  const body = await validateAndGetFormattedDogUpdateParams(data);
  const response = await axios.put('/sara-wholesome', {
    data: body,
  });
  return response;
};

/**
 * Calls the API to publishSaraWholesomeDataRequest.
 *
 * @async
 * @function
 * @returns {Promise} The response from the API call.
 */
const publishSaraWholesomeDataRequest = async (data) => {
  const response = await configuredAxios.post('/pet/SaraWholesome', data, {
    headers: {
      Authorization: getAuthorizationHeader(),
    },
  });
  return response;
};

/**
 * Calls the API to fetchSaraWholesome request.
 *
 * @generator
 * @function
 * @returns {Promise} The response from the API call.
 */
function* fetchSaraWholesome() {
  try {
    const response = yield call(getSaraWholesomeRequest);
    if (response.data.data) {
      yield put(
        getSaraWholesomeSuccess(
          formatSaraWholesome(response.data.data.attributes)
        )
      );
    } else {
      yield put(getSaraWholesomeError('no data received'));
    }
  } catch (error) {
    yield put(getSaraWholesomeError(error));
  }
}

/**
 * Calls the API to publishSaraWholesome request.
 *
 * @generator
 * @function
 * @returns {Promise} The response from the API call.
 */
function* publishSaraWholesome(data) {
  yield put(publishSaraWholesomeRequest());
  try {
    const response = yield call(publishSaraWholesomeDataRequest, data.payload);
    if (response) {
      NotificationManager.success(
        'Data Published successfully',
        'Data Published'
      );
      yield put(publishSaraWholesomeSuccess(response));
    } else {
      NotificationManager.error('Failed to Publish data', 'Data Published');
      yield put(publishSaraWholesomeError('no response received'));
    }
  } catch (error) {
    if (error.name === 'ValidationError') {
      NotificationManager.error(error.message, 'Data Updated');
    } else {
      NotificationManager.error('Failed to Published data', 'Data Published');
    }
    yield put(publishSaraWholesomeError(error));
  }
}

/**
 * Calls the API to updateSaraWholesome request.
 *
 * @generator
 * @function
 * @returns {Promise} The response from the API call.
 */

function* updateSaraWholesome(data) {
  try {
    const response = yield call(updateSaraWholesomeRequest, data.payload);
    if (response) {
      NotificationManager.success('Data updated successfully', 'Data Updated');
      yield put(updateSaraWholesomeSuccess(response));
    } else {
      NotificationManager.error('Failed to update data', 'Data Updated');
      yield put(updateSaraWholesomeError('no response received'));
    }
  } catch (error) {
    if (error.name === 'ValidationError') {
      NotificationManager.error(error.message, 'Data Updated');
    } else {
      NotificationManager.error('Failed to update data', 'Data Updated');
    }
    yield put(updateSaraWholesomeError(error));
  }
}

export function* watchSaraWholeSome() {
  yield takeLatest(GET_SARA_WHOLESOME, fetchSaraWholesome);
}

export function* watchUpdateSaraWholeSome() {
  yield takeLatest(UPDATE_SARA_WHOLESOME, updateSaraWholesome);
}

export function* watchPublishSaraWholeSome() {
  yield takeLatest(PUBLISH_SARA_WHOLESOME, publishSaraWholesome);
}

/**
 * Root Saga that combines all sagas.
 *
 * @generator
 * @function
 */

export default function* rootSaga() {
  yield all([
    fork(watchSaraWholeSome),
    fork(watchUpdateSaraWholeSome),
    fork(watchPublishSaraWholeSome),
  ]);
}
