import {
  CREATE_REWARD_CONDITION,
  CREATE_REWARD_CONDITION_ERROR,
  CREATE_REWARD_CONDITION_SUCCESS,
  DELETE_REWARD_CONDITION,
  DELETE_REWARD_CONDITION_ERROR,
  DELETE_REWARD_CONDITION_SUCCESS,
  SET_NEW_REWARD_CONDITION_FIELD,
  SET_REWARD_CONDITION_FIELD,
  SET_REWARD_CONDITION_MODAL,
  UPDATE_REWARD_CONDITION,
  UPDATE_REWARD_CONDITION_ERROR,
  UPDATE_REWARD_CONDITION_SUCCESS,
  GET_REWARD_CONDITION,
  GET_REWARD_CONDITION_ERROR,
  GET_REWARD_CONDITION_SUCCESS,
} from 'redux/actions';

// USERS_GET_LIST
export const getRewardCondition = () => ({
  type: GET_REWARD_CONDITION,
});

export const getRewardConditionSuccess = (payload) => ({
  type: GET_REWARD_CONDITION_SUCCESS,
  payload,
});

export const getRewardConditionError = (payload) => ({
  type: GET_REWARD_CONDITION_ERROR,
  payload,
});

// SET_REWARD_CONDITION_MODAL
export const setRewardConditionModal = (payload) => ({
  type: SET_REWARD_CONDITION_MODAL,
  payload,
});

export const setNewRewardConditionField = (payload) => ({
  type: SET_NEW_REWARD_CONDITION_FIELD,
  payload,
});

// CREATE_USER
export const createRewardCondition = (payload) => ({
  type: CREATE_REWARD_CONDITION,
  payload,
});

export const createRewardConditionSuccess = (payload) => ({
  type: CREATE_REWARD_CONDITION_SUCCESS,
  payload,
});

export const createRewardConditionError = (payload) => ({
  type: CREATE_REWARD_CONDITION_ERROR,
  payload,
});

// DELETE_USER
export const deleteRewardCondition = (payload) => ({
  type: DELETE_REWARD_CONDITION,
  payload,
});

export const deleteRewardConditionSuccess = (payload) => ({
  type: DELETE_REWARD_CONDITION_SUCCESS,
  payload,
});

export const deleteRewardConditionError = (payload) => ({
  type: DELETE_REWARD_CONDITION_ERROR,
  payload,
});

// UPDATE_USER
export const updateRewardCondition = (payload) => ({
  type: UPDATE_REWARD_CONDITION,
  payload,
});

export const updateRewardConditionSuccess = (payload) => ({
  type: UPDATE_REWARD_CONDITION_SUCCESS,
  payload,
});

export const updateRewardConditionError = (payload) => ({
  type: UPDATE_REWARD_CONDITION_ERROR,
  payload,
});

// SET_USER_FIELD
export const setRewardConditionField = (payload) => ({
  type: SET_REWARD_CONDITION_FIELD,
  payload,
});
