import { NotificationManager } from 'components/common/react-notifications';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import configuredAxios, {
  accessRefreshToken,
  getAuthorizationHeader,
} from 'utils/Api';
import axios from 'utils/CmsApi';
import {
  GET_SEARCH_DATA,
  PUBLISH_SEARCH_DATA,
  SAVE_SEARCH_VERSION,
  UPDATE_SEARCH_DATA,
} from '../actions';
import {
  getSearchDataError,
  getSearchDataSuccess,
  publishSearchDataError,
  publishSearchDataSuccess,
  updateSearchDataError,
  updateSearchDataSuccess,
} from './action';
import { formatSearchData, getSearchParams } from './selector';
import { validateAndGetFormattedSearchUpdateParams } from './validator';

/**
 * Calls the API to get Search request.
 *
 * @async
 * @function
 * @returns {Promise} The response from the API call.
 */
export const getSearchListRequest = async () => {
  accessRefreshToken();
  const response = await axios.get('/search', getSearchParams());
  return response;
};

/**
 * Calls the API to update Search request.
 *
 * @async
 * @function
 * @returns {Promise} The response from the API call.
 */
const updateSearchDataRequest = async (data) => {
  accessRefreshToken();
  const body = await validateAndGetFormattedSearchUpdateParams(data);
  const response = await axios.put('/search', {
    data: body,
  });
  return response;
};

/**
 * Calls the API to post addSearchVersionRequest.
 *
 * @async
 * @function
 * @returns {Promise} The response from the API call.
 */
const addSearchVersionRequest = async (data) => {
  accessRefreshToken();
  const response = await axios.post(
    '/content-versioning/home-page-search/save',
    {
      data,
    }
  );
  return response;
};

// call publish search api url
/**
 * Calls the API to publishSearchDataRequest.
 *
 * @async
 * @function
 * @returns {Promise} The response from the API call.
 */
const publishSearchDataRequest = async (data) => {
  const response = await configuredAxios.post('/pet/search', data, {
    headers: {
      Authorization: getAuthorizationHeader(),
    },
  });
  return response;
};

// call get api function
/**
 * Calls the API to get fetchSearchData request.
 *
 * @generator
 * @function
 * @returns {Promise} The response from the API call.
 */
function* fetchSearchData() {
  try {
    const response = yield call(getSearchListRequest);
    if (response.data.data) {
      yield put(
        getSearchDataSuccess(formatSearchData(response.data.data.attributes))
      );
    } else {
      yield put(getSearchDataError('no data received'));
    }
  } catch (error) {
    yield put(getSearchDataError(error));
  }
}

// call add search function

function* addSearchVersionData(data) {
  try {
    yield call(addSearchVersionRequest, data.payload);
    // TODO
  } catch (error) {
    // TODO
  }
}

// call update api function
/**
 * Calls the API to get updateSearchData request.
 *
 * @generator
 * @function
 * @returns {Promise} The response from the API call.
 */
function* updateSearchData(data) {
  try {
    const response = yield call(updateSearchDataRequest, data.payload);
    if (response) {
      NotificationManager.success('Data updated successfully', 'Data Updated');
      yield put(updateSearchDataSuccess(response));
    } else {
      NotificationManager.error('Failed to update data', 'Data Updated');
      yield put(updateSearchDataError('no response received'));
    }
  } catch (error) {
    if (error.name === 'ValidationError') {
      NotificationManager.error(error.message, 'Data Updated');
    } else {
      NotificationManager.error('Failed to update data', 'Data Updated');
    }
    yield put(updateSearchDataError(error));
  }
}

// call publish api function
/**
 * Calls the API to publishSearchData request.
 *
 * @generator
 * @function
 * @returns {Promise} The response from the API call.
 */
function* publishSearchData(data) {
  try {
    const response = yield call(publishSearchDataRequest, data.payload);
    if (response) {
      NotificationManager.success(
        'Data Published successfully',
        'Data Published'
      );
      yield put(publishSearchDataSuccess(response));
    } else {
      NotificationManager.error('Failed to Publish data', 'Data Published');
      yield put(publishSearchDataError('no response received'));
    }
  } catch (error) {
    if (error.name === 'ValidationError') {
      NotificationManager.error(error.message, 'Data Updated');
    } else {
      NotificationManager.error('Failed to Published data', 'Data Published');
    }
    yield put(publishSearchDataError(error));
  }
}
export function* watchSearchData() {
  yield takeLatest(GET_SEARCH_DATA, fetchSearchData);
}

export function* watchUpdateSearchData() {
  yield takeLatest(UPDATE_SEARCH_DATA, updateSearchData);
}

export function* watchPublishSearchData() {
  yield takeLatest(PUBLISH_SEARCH_DATA, publishSearchData);
}

export function* watchAddSearchVersion() {
  yield takeLatest(SAVE_SEARCH_VERSION, addSearchVersionData);
}

/**
 * Root Saga that combines all sagas.
 *
 * @generator
 * @function
 */

export default function* rootSaga() {
  yield all([
    fork(watchSearchData),
    fork(watchUpdateSearchData),
    fork(watchAddSearchVersion),
    fork(watchPublishSearchData),
  ]);
}
