import { NotificationManager } from 'components/common/react-notifications';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
// import { setToken } from 'helpers/Utils';
import {
  DELETE_COURIER,
  UPDATE_COURIER,
  COURIER_GET_LIST,
  PUBLISH_COURIER_DATA,
  CREATE_COURIER,
} from 'redux/actions';
import axios from 'utils/CmsApi';
import configuredAxios, { getAuthorizationHeader } from 'utils/Api';
import {
  createCourierError,
  createCourierSuccess,
  deleteCourierError,
  deleteCourierSuccess,
  getCourierList,
  getCourierListError,
  getCourierListSuccess,
  setCourierModal,
  updateCourierError,
  updateCourierSuccess,
  publishCourierDataError,
  publishCourierDataSuccess,
  publishCourierRequest,
} from './actions';
import { formatCourierListData } from './selector';
import {
  validateAndGetFormattedUpdateCourierParams,
  validateAndGetFormattedCreateCourierParams,
} from './validator';
import { accessRefreshToken } from '../../utils/Api';

/**
 * Calls the API to getCourierListRequest request.
 *
 * @async
 * @function
 * @returns {Promise} The response from the API call.
 */
export const getCourierListRequest = async () => {
  accessRefreshToken();
  const response = await axios.get('/courier-details');
  return response;
};

// call delete api url

/**
 * Calls the API to deleteCourierRequest.
 *
 * @async
 * @function
 * @returns {Promise} The response from the API call.
 */
const deleteCourierRequest = async (userId) => {
  accessRefreshToken();
  const response = await axios.delete(`/courier-details/${userId}`);
  return response;
};

/**
 * Calls the API to createCourierRequest.
 *
 * @async
 * @function
 * @returns {Promise} The response from the API call.
 */
const createCourierRequest = async (data) => {
  accessRefreshToken();
  const body = await validateAndGetFormattedCreateCourierParams(data);
  const response = await axios.post('courier-details', body);
  return response;
};

/**
 * Calls the API to updateCourierRequest.
 *
 * @async
 * @function
 * @returns {Promise} The response from the API call.
 */
const updateCourierRequest = async (data) => {
  accessRefreshToken();
  const body = await validateAndGetFormattedUpdateCourierParams(data);
  const response = await axios.put(`/courier-details/${data.id}`, body);
  return response;
};

/**
 * Calls the API to publishCourierDataRequest.
 *
 * @async
 * @function
 * @returns {Promise} The response from the API call.
 */
const publishCourierDataRequest = async (data) => {
  const response = await configuredAxios.post('/pet/courierDetail', data, {
    headers: {
      Authorization: getAuthorizationHeader(),
    },
  });
  return response;
};

/**
 * Calls the API to publishCourierData request.
 *
 * @generator
 * @function
 * @returns {Promise} The response from the API call.
 */

function* publishCourierData(data) {
  yield put(publishCourierRequest());
  try {
    const response = yield call(publishCourierDataRequest, data.payload);
    if (response) {
      NotificationManager.success(
        'Data Published successfully',
        'Data Published'
      );
      yield put(publishCourierDataSuccess(response));
    } else {
      NotificationManager.error('Failed to Publish data', 'Data Published');
      yield put(publishCourierDataError('no response received'));
    }
  } catch (error) {
    if (error.name === 'ValidationError') {
      NotificationManager.error(error.message, 'Data Updated');
    } else {
      NotificationManager.error('Failed to Published data', 'Data Published');
    }
    yield put(publishCourierDataError(error));
  }
}

/**
 * Calls the API to getCourierLists request.
 *
 * @generator
 * @function
 * @returns {Promise} The response from the API call.
 */
function* getCourierLists() {
  try {
    const response = yield call(getCourierListRequest);
    if (response.data) {
      yield put(
        getCourierListSuccess(formatCourierListData(response.data.data))
      );
    } else {
      yield put(getCourierListError('no data received'));
    }
  } catch (error) {
    yield put(getCourierListError(error));
  }
}

/**
 * Calls the API to deleteCourier request.
 *
 * @generator
 * @function
 * @returns {Promise} The response from the API call.
 */
function* deleteCourier(data) {
  try {
    const response = yield call(deleteCourierRequest, data.payload);
    if (response.status === 200) {
      yield put(getCourierList());
      yield put(deleteCourierSuccess());
      NotificationManager.success(
        'Courier deleted successfully',
        'Delete Courier',
        3000,
        null,
        null,
        ''
      );
    }
  } catch (error) {
    NotificationManager.error(
      error.data.message,
      'Delete Courier',
      3000,
      null,
      null,
      ''
    );
    yield put(deleteCourierError(error));
  }
}

/**
 * Calls the API to updateCourier request.
 *
 * @generator
 * @function
 * @returns {Promise} The response from the API call.
 */

function* updateCourier(data) {
  try {
    const response = yield call(updateCourierRequest, data.payload);
    if (response.status === 200) {
      NotificationManager.success(
        'Courier updated successfully',
        'Update Courier',
        3000,
        null,
        null,
        ''
      );
      yield put(getCourierList());
      yield put(setCourierModal(false));
      yield put(updateCourierSuccess(response));
    }
  } catch (error) {
    NotificationManager.error(
      error.message,
      'Update Courier',
      3000,
      null,
      null,
      ''
    );
    yield put(updateCourierError(error));
  }
}
/**
 * Calls the API to createCourier request.
 *
 * @generator
 * @function
 * @returns {Promise} The response from the API call.
 */
function* createCourier(data) {
  try {
    const response = yield call(createCourierRequest, data.payload);
    if (response.status === 200) {
      NotificationManager.success(
        'Courier created successfully',
        'Create Courier',
        3000,
        null,
        null,
        ''
      );
      yield put(getCourierList());
      yield put(setCourierModal(false));
      yield put(createCourierSuccess(response));
    }
  } catch (error) {
    if (error.name === 'ValidationError') {
      NotificationManager.error(error.message, 'Create Courier');
    } else if (error.data.message) {
      NotificationManager.error(
        error.data.message,
        'Create Courier',
        3000,
        null,
        null,
        ''
      );
    }
    yield put(createCourierError(error));
  }
}

export function* watchGetCourierList() {
  yield takeLatest(COURIER_GET_LIST, getCourierLists);
}
export function* watchCreateCourier() {
  yield takeLatest(CREATE_COURIER, createCourier);
}

export function* watchDeleteCourier() {
  yield takeLatest(DELETE_COURIER, deleteCourier);
}

export function* watchUpdateCourier() {
  yield takeLatest(UPDATE_COURIER, updateCourier);
}

export function* watchPublishCourierData() {
  yield takeLatest(PUBLISH_COURIER_DATA, publishCourierData);
}

/**
 * Root Saga that combines all sagas.
 *
 * @generator
 * @function
 */
export default function* rootSaga() {
  yield all([
    fork(watchGetCourierList),
    fork(watchCreateCourier),
    fork(watchDeleteCourier),
    fork(watchUpdateCourier),
    fork(watchPublishCourierData),
  ]);
}
