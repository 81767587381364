import { NotificationManager } from 'components/common/react-notifications';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import configuredAxios, {
  accessRefreshToken,
  getAuthorizationHeader,
} from 'utils/Api';
import axios from 'utils/CmsApi';
import {
  GET_REWARD_DATA,
  PUBLISH_REWARD_DATA,
  UPDATE_REWARD_DATA,
} from '../actions';
import {
  getRewardDataError,
  getRewardDataSuccess,
  publishRewardDataRequest,
  publishRewardDataSuccess,
  publishRewardDataError,
  updateRewardDataError,
  updateRewardDataSuccess,
} from './action';
import { formatRewardDataForTable, getDogTreatParams } from './selector';
import { validateAndGetFormattedDogUpdateParams } from './validator';

/**
 * Calls the API to get reward faq request.
 *
 * @async
 * @function
 * @returns {Promise} The response from the API call.
 */
export const getRewardDataRequest = async () => {
  accessRefreshToken();
  const response = await axios.get('/reward-faq', getDogTreatParams());
  return response;
};

/**
 * Calls the API to update reward faq request.
 *
 * @async
 * @function
 * @param {Object} data - The data to be updated.
 * @returns {Promise} The response from the API call.
 */
const updateRewardDataRequest = async (data) => {
  accessRefreshToken();
  const body = await validateAndGetFormattedDogUpdateParams(data);
  const response = await axios.put('/reward-faq', {
    data: body,
  });
  return response;
};

/**
 * Saga function to publish reward faq.
 *
 * @generator
 * @function
 * @param {Object} data - The data to be published.
 */

const publishRewardRequest = async (data) => {
  const response = await configuredAxios.post('/pet/rewardFaqs', data, {
    headers: {
      Authorization: getAuthorizationHeader(),
    },
  });
  return response;
};

/**
 * Calls the API to get reward faq request.
 *
 * @async
 * @function
 * @returns {Promise} The response from the API call.
 */

function* fetchRewardData() {
  try {
    const response = yield call(getRewardDataRequest);
    if (response.data.data) {
      yield put(
        getRewardDataSuccess(
          formatRewardDataForTable(response.data.data.attributes)
        )
      );
    } else {
      yield put(getRewardDataError('no data received'));
    }
  } catch (error) {
    yield put(getRewardDataError(error));
  }
}

/**
 * Saga function to publish reward data.
 *
 * @generator
 * @function
 * @param {Object} data - The data to be published.
 */
function* publishRewardData(data) {
  yield put(publishRewardDataRequest());
  try {
    const response = yield call(publishRewardRequest, data.payload);
    if (response) {
      NotificationManager.success(
        'Data Published successfully',
        'Data Published'
      );
      yield put(publishRewardDataSuccess(response));
    } else {
      NotificationManager.error('Failed to Publish data', 'Data Published');
      yield put(publishRewardDataError('no response received'));
    }
  } catch (error) {
    if (error.name === 'ValidationError') {
      NotificationManager.error(error.message, 'Data Updated');
    } else {
      NotificationManager.error('Failed to Published data', 'Data Published');
    }
    yield put(publishRewardDataError(error));
  }
}

/**
 * Saga function to update reward faq data.
 *
 * @generator
 * @function
 * @param {Object} data - The data to be updated.
 */
function* updateRewardData(data) {
  try {
    const response = yield call(updateRewardDataRequest, data.payload);
    if (response) {
      NotificationManager.success('Data updated successfully', 'Data Updated');
      yield put(updateRewardDataSuccess(response));
    } else {
      NotificationManager.error('Failed to update data', 'Data Updated');
      yield put(updateRewardDataError('no response received'));
    }
  } catch (error) {
    if (error.name === 'ValidationError') {
      NotificationManager.error(error.message, 'Data Updated');
    } else {
      NotificationManager.error('Failed to update data', 'Data Updated');
    }
    yield put(updateRewardDataError(error));
  }
}

export function* watchRewardData() {
  yield takeLatest(GET_REWARD_DATA, fetchRewardData);
}

export function* watchUpdateRewardData() {
  yield takeLatest(UPDATE_REWARD_DATA, updateRewardData);
}

export function* watchPublishRewardData() {
  yield takeLatest(PUBLISH_REWARD_DATA, publishRewardData);
}

/**
 * Root Saga that combines all sagas.
 *
 * @generator
 * @function
 */
export default function* rootSaga() {
  yield all([
    fork(watchRewardData),
    fork(watchUpdateRewardData),
    fork(watchPublishRewardData),
  ]);
}
