import { NotificationManager } from 'components/common/react-notifications';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  CREATE_USER,
  DELETE_USER,
  UPDATE_USER,
  USERS_GET_LIST,
} from 'redux/actions';
import axios, { accessRefreshToken } from 'utils/Api';
import {
  createUserError,
  createUserSuccess,
  deleteUserError,
  deleteUserSuccess,
  getUsersList,
  getUsersListError,
  getUsersListSuccess,
  setUserModal,
  updateUserError,
  updateUserSuccess,
} from './actions';
import { formatUserListData } from './selector';
import {
  validateAndGetFormattedCreateUserParams,
  validateAndGetFormattedUpdateUserParams,
} from './validator';

// call get api using axios
const getUsersListRequest = async () => {
  accessRefreshToken();
  const response = await axios.get('/auth/users');
  return response;
};

// create new user
const createUserRequest = async (data) => {
  accessRefreshToken();
  const body = await validateAndGetFormattedCreateUserParams(data);
  const response = await axios.post('/auth/user', body);
  return response;
};

// delete existing user
const deleteUserRequest = async (userId) => {
  accessRefreshToken();
  const response = await axios.delete(`/auth/user/${userId}`);
  return response;
};

// update user using axios
const updateUserRequest = async (data) => {
  accessRefreshToken();
  const body = await validateAndGetFormattedUpdateUserParams(data);
  const response = await axios.put(`/auth/user/${data.id}`, body);
  return response;
};

// call get api
function* getUsersLists() {
  try {
    const response = yield call(getUsersListRequest);
    if (response.data) {
      yield put(getUsersListSuccess(formatUserListData(response.data)));
    } else {
      yield put(getUsersListError('no data received'));
    }
  } catch (error) {
    yield put(getUsersListError(error));
  }
}

// call create user
function* createUser(data) {
  try {
    const response = yield call(createUserRequest, data.payload);
    if (response.code === 201) {
      NotificationManager.success(
        'User created successfully',
        'Create User',
        3000,
        null,
        null,
        ''
      );
      yield put(getUsersList());
      yield put(setUserModal(false));
      yield put(createUserSuccess(response));
    }
  } catch (error) {
    if (error.name === 'ValidationError') {
      NotificationManager.error(error.message, 'Create User');
    } else if (error.data.message) {
      NotificationManager.error(
        error.data.message,
        'Create User',
        3000,
        null,
        null,
        ''
      );
    }
    yield put(createUserError(error));
  }
}

// call delete api
function* deleteUser(data) {
  try {
    const response = yield call(deleteUserRequest, data.payload);
    if (response.code === 200) {
      yield put(getUsersList());
      yield put(deleteUserSuccess());
      NotificationManager.success(
        'User deleted successfully',
        'Delete User',
        3000,
        null,
        null,
        ''
      );
    }
  } catch (error) {
    NotificationManager.error(
      error.data.message,
      'Delete User',
      3000,
      null,
      null,
      ''
    );
    yield put(deleteUserError(error));
  }
}

// update user using api
function* updateUser(data) {
  try {
    const response = yield call(updateUserRequest, data.payload);
    if (response.code === 200) {
      NotificationManager.success(
        'User updated successfully',
        'Update User',
        3000,
        null,
        null,
        ''
      );
      yield put(getUsersList());
      yield put(setUserModal(false));
      yield put(updateUserSuccess(response));
    }
  } catch (error) {
    if (error.name === 'ValidationError') {
      NotificationManager.error(error.message, 'Create User');
    } else {
      NotificationManager.error(
        error.data.message,
        'Update User',
        3000,
        null,
        null,
        ''
      );
    }
    yield put(updateUserError(error));
  }
}

export function* watchGetUsersList() {
  yield takeLatest(USERS_GET_LIST, getUsersLists);
}

export function* watchCreateUser() {
  yield takeLatest(CREATE_USER, createUser);
}

export function* watchDeleteUser() {
  yield takeLatest(DELETE_USER, deleteUser);
}

export function* watchUpdateUser() {
  yield takeLatest(UPDATE_USER, updateUser);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetUsersList),
    fork(watchCreateUser),
    fork(watchDeleteUser),
    fork(watchUpdateUser),
  ]);
}
