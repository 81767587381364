import { NotificationManager } from 'components/common/react-notifications';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import configuredAxios, {
  accessRefreshToken,
  getAuthorizationHeader,
} from 'utils/Api';
import axios from 'utils/CmsApi';
import {
  CREATE_SHIPPING,
  DELETE_SHIPPING_DATA,
  GET_SHIPPING_DATA,
  PUBLISH_SHIPPING_DATA,
  UPDATE_SHIPPING_DATA,
} from '../actions';
import {
  getShippingDataSuccess,
  getShippingDataError,
  deleteShippingSuccess,
  deleteShippingError,
  updateShippingDataError,
  updateShippingDataSuccess,
  setShippingModal,
  getShippingData,
  publishShippingRequest,
  publishShippingDataSuccess,
  publishShippingDataError,
  createShippingSuccess,
  createShippingError,
} from './action';
import { formatShippingData } from './selector';
import {
  validateAndGetFormattedCreateShippingParams,
  validateAndGetFormattedUpdateShippingParams,
} from './validator';

/**
 * Calls the API to getShippingListRequest.
 *
 * @async
 * @function
 * @returns {Promise} The response from the API call.
 */
export const getShippingListRequest = async () => {
  accessRefreshToken();
  const response = await axios.get('/shipping-rate-tiers');
  return response;
};

/**
 * Calls the post API to createShippingRequest.
 *
 * @async
 * @function
 * @returns {Promise} The response from the API call.
 */
const createShippingRequest = async (data) => {
  accessRefreshToken();
  const body = await validateAndGetFormattedCreateShippingParams(data);
  const response = await axios.post('/shipping-rate-tiers', body);
  return response;
};

/**
 * Calls the delete API to deleteShippingRequest.
 *
 * @async
 * @function
 * @returns {Promise} The response from the API call.
 */
const deleteShippingRequest = async (userId) => {
  accessRefreshToken();
  const response = await axios.delete(`/shipping-rate-tiers/${userId}`);
  return response;
};

/**
 * Calls the put API to updateShippingDataRequest.
 *
 * @async
 * @function
 * @returns {Promise} The response from the API call.
 */
const updateShippingDataRequest = async (data) => {
  accessRefreshToken();
  const FormatedData = await validateAndGetFormattedUpdateShippingParams(data);
  const response = await axios.put(
    `/shipping-rate-tiers/${data.id}`,
    FormatedData
  );
  return response;
};

/**
 * Calls the post API to publishShippingDataRequest.
 *
 * @async
 * @function
 * @returns {Promise} The response from the API call.
 */
const publishShippingDataRequest = async (data) => {
  const response = await configuredAxios.post('/pet/shippingRateTier', data, {
    headers: {
      Authorization: getAuthorizationHeader(),
    },
  });
  return response;
};

/**
 * Calls the API to fetchShippingData.
 *
 * @generator
 * @function
 * @returns {Promise} The response from the API call.
 */
function* fetchShippingData() {
  try {
    const response = yield call(getShippingListRequest);
    if (response.data.data) {
      yield put(getShippingDataSuccess(formatShippingData(response.data.data)));
    } else {
      yield put(getShippingDataError('no data received'));
    }
  } catch (error) {
    yield put(getShippingDataError(error));
  }
}

/**
 * Calls the API to createShippingData.
 *
 * @generator
 * @function
 * @returns {Promise} The response from the API call.
 */
function* createShippingData(data) {
  try {
    const response = yield call(createShippingRequest, data.payload);
    if (response.status === 200) {
      NotificationManager.success(
        'Shipping created successfully',
        'Create Shipping',
        3000,
        null,
        null,
        ''
      );
      yield put(getShippingData());
      yield put(setShippingModal(false));
      yield put(createShippingSuccess(response));
    }
  } catch (error) {
    if (error.name === 'ValidationError') {
      NotificationManager.error(error.message, 'Create Shipping');
    } else if (error.data.message) {
      NotificationManager.error(
        error.data.message,
        'Create Shipping',
        3000,
        null,
        null,
        ''
      );
    }
    yield put(createShippingError(error));
  }
}

/**
 * Calls the API to deleteShippingData.
 *
 * @generator
 * @function
 * @returns {Promise} The response from the API call.
 */
function* deleteShippingData(data) {
  try {
    const response = yield call(deleteShippingRequest, data.payload);
    if (response.status === 200) {
      yield put(getShippingData());
      yield put(deleteShippingSuccess());
      NotificationManager.success(
        'Shipping data deleted successfully',
        'Delete Shipping Data',
        3000,
        null,
        null,
        ''
      );
    }
  } catch (error) {
    NotificationManager.error(
      error.data.message,
      'Delete User',
      3000,
      null,
      null,
      ''
    );
    yield put(deleteShippingError(error));
  }
}

/**
 * Calls the API to updateShipping.
 *
 * @generator
 * @function
 * @returns {Promise} The response from the API call.
 */
function* updateShipping(data) {
  try {
    const response = yield call(updateShippingDataRequest, data.payload);
    if (response.status === 200) {
      NotificationManager.success(
        'Shipping updated successfully',
        'Update shipping',
        3000,
        null,
        null,
        ''
      );
      yield put(getShippingData());
      yield put(setShippingModal(false));
      yield put(updateShippingDataSuccess(response));
    }
  } catch (error) {
    NotificationManager.error(
      error.message,
      'Update shipping',
      3000,
      null,
      null,
      ''
    );
    yield put(updateShippingDataError(error));
  }
}

/**
 * Calls the API to publishShippingData.
 *
 * @generator
 * @function
 * @returns {Promise} The response from the API call.
 */
function* publishShippingData(data) {
  yield put(publishShippingRequest());
  try {
    const response = yield call(publishShippingDataRequest, data.payload);
    if (response?.code === 200) {
      NotificationManager.success(
        'Data Published successfully',
        'Data Published'
      );
      yield put(publishShippingDataSuccess(response));
    } else {
      NotificationManager.error('Failed to Publish data', 'Data Published');
      yield put(publishShippingDataError('no response received'));
    }
  } catch (error) {
    if (error.name === 'ValidationError') {
      NotificationManager.error(error.message, 'Data Updated');
    } else {
      NotificationManager.error('Failed to Published data', 'Data Published');
    }
    yield put(publishShippingDataError(error));
  }
}

export function* watchShippingData() {
  yield takeLatest(GET_SHIPPING_DATA, fetchShippingData);
}

export function* watchCreateShippingData() {
  yield takeLatest(CREATE_SHIPPING, createShippingData);
}

export function* watchDeleteShippingData() {
  yield takeLatest(DELETE_SHIPPING_DATA, deleteShippingData);
}

export function* watchUpdateShippingData() {
  yield takeLatest(UPDATE_SHIPPING_DATA, updateShipping);
}

export function* watchPublishShippingData() {
  yield takeLatest(PUBLISH_SHIPPING_DATA, publishShippingData);
}

/**
 * Root Saga that combines all sagas.
 *
 * @generator
 * @function
 */

export default function* rootSaga() {
  yield all([
    fork(watchShippingData),
    fork(watchDeleteShippingData),
    fork(watchCreateShippingData),
    fork(watchUpdateShippingData),
    fork(watchPublishShippingData),
  ]);
}
