import {
  GET_REWARD_LANDING,
  GET_REWARD_LANDING_ERROR,
  GET_REWARD_LANDING_SUCCESS,
  UPDATE_REWARD_LANDING,
  UPDATE_REWARD_LANDING_ERROR,
  UPDATE_REWARD_LANDING_SUCCESS,
  PUBLISH_REWARD_LANDING_REQUEST,
  PUBLISH_REWARD_LANDING_SUCCESS,
  PUBLISH_REWARD_LANDING_ERROR,
  // GET_REWARD_CONDITIONS_DATA,
  // GET_REWARD_CONDITIONS_DATA_ERROR,
  // GET_REWARD_CONDITIONS_DATA_SUCCESS,
  // SET_REWARD_DATA_FOR_TABLE,
  // GET_REWARD_LEVELS_SUCCESS,
  // GET_REWARD_LANDINGPAGE_DATA_SUCCESS,
} from '../actions';

const INIT_STATE = {
  users: {},
  rewardDataForTable: [],
  rewardLevels: [],
  rewardLandingPage: [],
  loading: false,
  isPublishing: false,
  error: false,
  updateSuccess: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_REWARD_LANDING:
      return { ...state, loading: true, updateSuccess: false };

    case GET_REWARD_LANDING_ERROR:
      return { ...state, loading: false, error: action.payload };

    case GET_REWARD_LANDING_SUCCESS:
      return { ...state, ...action.payload };

    // UPDATE_REWARD_LANDING
    case UPDATE_REWARD_LANDING:
      return { ...state, loading: true, updateSuccess: false };

    case UPDATE_REWARD_LANDING_SUCCESS:
      return { ...state, loading: false, error: false, updateSuccess: true };

    case UPDATE_REWARD_LANDING_ERROR:
      return { ...state, loading: false, error: true, updateSuccess: false };

    // PUBLISH_REWARD_LANDING_REQUEST
    case PUBLISH_REWARD_LANDING_REQUEST:
      return {
        ...state,
        isPublishing: true,
        error: false,
      };

    case PUBLISH_REWARD_LANDING_SUCCESS:
      return {
        ...state,
        isPublishing: false,
        error: false,
      };

    case PUBLISH_REWARD_LANDING_ERROR:
      return {
        ...state,
        isPublishing: false,
        error: true,
      };

    // case GET_REWARD_CONDITIONS_DATA:
    //   return { ...state, loading: true };

    // case GET_REWARD_CONDITIONS_DATA_ERROR:
    //   return { ...state, loading: false, error: action.payload };

    // case GET_REWARD_CONDITIONS_DATA_SUCCESS:
    //   return { ...state, loading: false, users: action.payload };
    // case SET_REWARD_DATA_FOR_TABLE:
    //   return { ...state, rewardDataForTable: action.payload };
    // case GET_REWARD_LEVELS_SUCCESS:
    //   return { ...state, rewardLevels: action.payload };
    // case GET_REWARD_LANDINGPAGE_DATA_SUCCESS:
    //   return { ...state, rewardLandingPage: action.payload };
    default:
      return { ...state };
  }
};
