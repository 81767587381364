// format shipping data
export const formatShippingData = (data = []) => {
  return {
    data: data.map((d) => ({
      id: d.id,
      CartValueFrom: d.attributes.CartValueFrom,
      CartValueTo: d.attributes.CartValueTo,
      ShippingCost: d.attributes.ShippingCost,
      PaymentMethod: d.attributes.PaymentMethod,
    })),
  };
};
// format create shipping data

export const formattedCreateShippingApiReq = (data) => ({
  data: {
    id: data.id,
    CartValueFrom: data.CartValueFrom,
    CartValueTo: data.CartValueTo,
    ShippingCost: data.ShippingCost,
    PaymentMethod: data.PaymentMethod,
  },
});

// format update shipping data

export const formattedUpdateShippingApiReq = (data) => ({
  data: {
    id: data.id,
    CartValueFrom: data.CartValueFrom,
    CartValueTo: data.CartValueTo,
    ShippingCost: data.ShippingCost,
    PaymentMethod: data.PaymentMethod,
  },
});
