// format user list data
export const formatUserListData = (data = []) =>
  data.map((d) => ({
    firstName: d.firstName,
    lastName: d.lastName,
    name: d.name || `${d.firstName} ${d.lastName}`,
    email: d.email,
    id: d.id,
    mobile: d.mobile,
    role: d.role,
    password: d.password,
  }));

// format create user data
export const formattedCreateUserApiReq = (data) => ({
  ...data,
});

// format update user data
export const formattedUpdateUserApiReq = (data) => ({
  firstName: data.firstName,
  lastName: data.lastName,
  mobile: data.mobile,
  role: data.role,
});
