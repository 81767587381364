export const LOGIN_PATH = '/login';
export const DASHBOARD_PATH = '/dashboard';
export const DEFAULT_PATH = '/';
export const CMS_PATH = '/cms';
export const DOG_HOME = '/dog';
export const CAT_HOME = '/cat';
export const SMALL_ANIMAL_HOME = '/small-animal';
export const OFFERS_HOME = '/offers';
export const SEARCH_HOME = '/search';
export const SETTINGS = '/settings';
export const USER_PATH = '/user';
export const NOT_FOUND_PATH = '/not-found';
export const FORGOT_PASSWORD_PATH = '/forgot-password';
export const RESET_PASSWORD_PATH = '/reset-password/:token';
export const COURIER_DETAILS = '/courier-details';
export const SHIPPING_RATE = '/shipping-rate';
export const REVIEW_AND_RATING = '/review';
export const ALL_DOG_TREAT = '/all-dog-treat';
export const DOG_GROOMING = '/dog-grooming';
export const SARA_WHOLESOME = '/sara-wholesome';
export const REWARD_FAQ = '/reward';
export const REWARD_CONDITONS = '/RewardConditions';
export const REWARD_LEVEL = '/rewardlevel';
export const REWARDLANDINGPAGE = '/rewardlandingpage';
