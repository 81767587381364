import {
  GET_SARA_WHOLESOME,
  GET_SARA_WHOLESOME_ERROR,
  GET_SARA_WHOLESOME_SUCCESS,
  UPDATE_SARA_WHOLESOME,
  UPDATE_SARA_WHOLESOME_ERROR,
  UPDATE_SARA_WHOLESOME_SUCCESS,
  PUBLISH_SARA_WHOLESOME_REQUEST,
  PUBLISH_SARA_WHOLESOME_SUCCESS,
  PUBLISH_SARA_WHOLESOME_ERROR,
} from 'redux/actions';

const INIT_STATE = {
  BannerImage: {},
  ClassicBanner: {},
  singleVerticleBanner: {},
  // testimonial: {},
  Classic: {},
  GrainFreeBanner: {},
  GrainFree: {},
  WhatYouSee: {},
  WhatYouGet: {},
  SingleImage: {},
  FAQ: {},
  loading: false,
  isPublishing: false,
  error: false,
  updateSuccess: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    // GET_SARA_WHOLESOME

    case GET_SARA_WHOLESOME:
      return { ...state, loading: true, updateSuccess: false };

    case GET_SARA_WHOLESOME_ERROR:
      return { ...state, loading: false, error: action.payload };

    case GET_SARA_WHOLESOME_SUCCESS:
      return { ...state, ...action.payload };

    // UPDATE_SARA_WHOLESOME

    case UPDATE_SARA_WHOLESOME:
      return { ...state, loading: true, updateSuccess: false };

    case UPDATE_SARA_WHOLESOME_SUCCESS:
      return { ...state, loading: false, error: false, updateSuccess: true };

    case UPDATE_SARA_WHOLESOME_ERROR:
      return { ...state, loading: false, error: true, updateSuccess: false };

    // PUBLISH_SARA_WHOLESOME_REQUEST

    case PUBLISH_SARA_WHOLESOME_REQUEST:
      return {
        ...state,
        isPublishing: true,
        error: false,
      };

    case PUBLISH_SARA_WHOLESOME_SUCCESS:
      return {
        ...state,
        isPublishing: false,
        error: false,
      };

    case PUBLISH_SARA_WHOLESOME_ERROR:
      return {
        ...state,
        isPublishing: false,
        error: true,
      };

    default:
      return { ...state };
  }
};
