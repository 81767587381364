export const imageFormat = (data) => [...data];

export const getFormattedCollectionsData = (data) => {
  const {
    collections: { edges = [] },
  } = data;
  return edges
    .map((val) => ({
      id: val.node.id,
      title: val.node.title,
      handle: val.node.handle,
    }))
    .sort((a, b) => (a.handle > b.handle ? 1 : -1));
};
