import { NotificationManager } from 'components/common/react-notifications';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import configuredAxios, {
  accessRefreshToken,
  getAuthorizationHeader,
} from 'utils/Api';
import axios from 'utils/CmsApi';
import {
  GET_OFFERS_DATA,
  PUBLISH_OFFERS_DATA,
  UPDATE_OFFERS_DATA,
} from '../actions';
import {
  getOffersDataError,
  getOffersDataSuccess,
  publishOffersDataError,
  publishOffersDataSuccess,
  updateOffersDataError,
  updateOffersDataSuccess,
  publishOffersRequest,
} from './action';
import { formatOffersData, getOffersParams } from './selector';
import { validateAndGetFormattedOfferUpdateParams } from './validator';
// import { validateAndGetFormattedOfferUpdateParams } from './validator';

/**
 * Calls the API to get offers request.
 *
 * @async
 * @function
 * @returns {Promise} The response from the API call.
 */
export const getOffersListRequest = async () => {
  accessRefreshToken();
  const response = await axios.get('/offer', getOffersParams());
  return response;
};

/**
 * Calls the API to publish offers request.
 *
 * @async
 * @function
 * @returns {Promise} The response from the API call.
 */
const publishOffersDataRequest = async (data) => {
  const response = await configuredAxios.post('/pet/offer', data, {
    headers: {
      Authorization: getAuthorizationHeader(),
    },
  });
  return response;
};

// call get api
/**
 * Calls the API to fetchOffersData request.
 *
 * @generator
 * @function
 * @returns {Promise} The response from the API call.
 */

function* fetchOffersData() {
  try {
    const response = yield call(getOffersListRequest);
    if (response.data.data) {
      yield put(
        getOffersDataSuccess(formatOffersData(response.data.data.attributes))
      );
    } else {
      yield put(getOffersDataError('no data received'));
    }
  } catch (error) {
    yield put(getOffersDataError(error));
  }
}

// call publish api
/**
 * Calls the API to publishOffersData request.
 *
 * @generator
 * @function
 * @returns {Promise} The response from the API call.
 */
function* publishOffersData(data) {
  yield put(publishOffersRequest());
  try {
    const response = yield call(publishOffersDataRequest, data.payload);
    if (response) {
      NotificationManager.success(
        'Data Published successfully',
        'Data Published'
      );
      yield put(publishOffersDataSuccess(response));
    } else {
      NotificationManager.error('Failed to Publish data', 'Data Published');
      yield put(publishOffersDataError('no response received'));
    }
  } catch (error) {
    if (error.name === 'ValidationError') {
      NotificationManager.error(error.message, 'Data Updated');
    } else {
      NotificationManager.error('Failed to Published data', 'Data Published');
    }
    yield put(publishOffersDataError(error));
  }
}

/**
 * Calls the API to updateOffersDataRequest request.
 *
 * @generator
 * @function
 * @returns {Promise} The response from the API call.
 */
const updateOffersDataRequest = async (data) => {
  const body = await validateAndGetFormattedOfferUpdateParams(data);
  const response = await axios.put('/offer', {
    data: body,
  });
  return response;
};

// call update api
/**
 * Calls the API to updateOffersData request.
 *
 * @generator
 * @function
 * @returns {Promise} The response from the API call.
 */
function* updateOffersData(data) {
  try {
    const response = yield call(updateOffersDataRequest, data.payload);
    if (response) {
      NotificationManager.success('Data updated successfully', 'Data Updated');
      yield put(updateOffersDataSuccess(response));
    } else {
      NotificationManager.error('Failed to update data', 'Data Updated');
      yield put(updateOffersDataError('no response received'));
    }
  } catch (error) {
    if (error.name === 'ValidationError') {
      NotificationManager.error(error.message, 'Data Updated');
    } else {
      NotificationManager.error('Failed to update data', 'Data Updated');
    }
    yield put(updateOffersDataError(error));
  }
}

export function* watchOffersData() {
  yield takeLatest(GET_OFFERS_DATA, fetchOffersData);
}

export function* watchPublishOffersData() {
  yield takeLatest(PUBLISH_OFFERS_DATA, publishOffersData);
}

export function* watchUpdateOffersData() {
  yield takeLatest(UPDATE_OFFERS_DATA, updateOffersData);
}

/**
 * Root Saga that combines all sagas.
 *
 * @generator
 * @function
 */
export default function* rootSaga() {
  yield all([
    fork(watchOffersData),
    fork(watchUpdateOffersData),
    fork(watchPublishOffersData),
  ]);
}
