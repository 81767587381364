import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import todoSagas from './todo/saga';
import chatSagas from './chat/saga';
import surveyListSagas from './surveyList/saga';
import surveyDetailSagas from './surveyDetail/saga';
import userSaga from './user/saga';
import catSaga from './cat/saga';
import dogSaga from './dog/saga';
import dogGrooming from './DogGrooming/saga';
import allDogTreatSaga from './AllDogTreat/saga';
import offersSaga from './offers/saga';
import smallAnimalSaga from './smallAnimal/saga';
import searchSaga from './search/saga';
import commonSaga from './common/saga';
import shppingSaga from './shippingRate/saga';
import courierDetails from './courierDetails/saga';
import saraWholeSome from './saraWholesome/saga';
import rewardDetails from './reward/saga';
import rewardLevels from './rewardLevel/saga';
import rewardLanding from './rewardLanding/saga';
import rewardCondition from './rewardCondition/saga';

export default function* rootSaga() {
  yield all([
    authSagas(),
    todoSagas(),
    chatSagas(),
    surveyListSagas(),
    surveyDetailSagas(),
    userSaga(),
    catSaga(),
    dogSaga(),
    offersSaga(),
    searchSaga(),
    smallAnimalSaga(),
    commonSaga(),
    shppingSaga(),
    courierDetails(),
    allDogTreatSaga(),
    dogGrooming(),
    saraWholeSome(),
    rewardDetails(),
    rewardLevels(),
    rewardLanding(),
    rewardCondition(),
  ]);
}
