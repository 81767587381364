import * as Yup from 'yup';
import { getFormattedSmallAnimalUpdateParams } from './selector';

// form validation
const linkValidateParamsBody = Yup.object().shape({
  title: Yup.string()
    .required('Title is required')
    .test(
      'title',
      'Title must be less then 50 character',
      (title) => title.length <= 50
    ),
  value: Yup.string()
    .required('Value is required')
    .test(
      'value',
      'Value must be less the 50 character',
      (value) => value.length <= 50
    ),
  objectType: Yup.string().required('Object type is reuqired'),
  imageData: Yup.object()
    .required('Image cannot be empty')
    .test('imageData', 'Image must be there', (imageData) => !!imageData?.id),
});

// eslint-disable-next-line import/prefer-default-export
export const validateAndGetFormattedSmallAnimalsUpdateParams = async (obj) => {
  await linkValidateParamsBody.validate(obj.data);
  return getFormattedSmallAnimalUpdateParams(obj);
};
