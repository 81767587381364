// get api params

export const getDogTreatParams = () => ({
  params: {
    populate:
      'HUFTReward.Link,HUFTReward.Image,HowItWorks.Link.Image,RewardTypes.SilverImage,RewardTypes.BronzeImage,RewardTypes.GoldImage',
  },
});
export const formatRewardDataForTable = (data = {}) => {
  return {
    vuid: {
      vuid: data.vuid,
    },

    HUFTReward: {
      id: data.HUFTReward && data.HUFTReward.id,
      buttonTitle: data.HUFTReward && data.HUFTReward.ButtonTitle,
      description: data.HUFTReward && data.HUFTReward.Description,
      title: data.HUFTReward && data.HUFTReward.Title,
      imageData: data.HUFTReward && data.HUFTReward.Image.data,
    },
    HowItWorks: {
      id: data.HowItWorks && data.HowItWorks.id,
      title: data.HowItWorks && data.HowItWorks.Title,
      links:
        data.HowItWorks &&
        data.HowItWorks.Link.map((d) => ({
          id: d.id,
          title: d.Title,
          description: d.Description,
          imageData: d?.Image?.data,
        })),
    },
    RewardTypes: {
      id: data.RewardTypes && data.RewardTypes.id,
      title: data.RewardTypes && data.RewardTypes.Title,
      bronzeImage:
        data.RewardTypes.BronzeImage.data &&
        data.RewardTypes.BronzeImage.data.attributes,
      goldImage:
        data.RewardTypes.GoldImage.data &&
        data.RewardTypes.GoldImage.data.attributes,
      silverImage:
        data.RewardTypes.SilverImage.data &&
        data.RewardTypes.SilverImage.data.attributes,
    },
  };
};

export const getFormattedDogUpdateParams = ({ data, dataKey }) => {
  switch (dataKey) {
    case 'HUFTReward':
      return {
        HUFTReward: {
          Id: data.id,
          Title: data.title,
          Description: data.description,
          ButtonTitle: data.buttonTitle,
          Image: data.imageData,
        },
      };

    case 'HowItWorks':
      return {
        HowItWorks: {
          Id: data.id,
          Title: data.title,
          Link: data.links.map((lin) => ({
            Id: lin.id,
            Title: lin.title,
            Description: lin.description,
            Image: lin.imageData,
          })),
        },
      };
    case 'RewardTypes':
      return {
        RewardTypes: {
          Id: data.id,
          Title: data.title,
          BronzeImage: data.bronzeImage,
          GoldImage: data.goldImage,
          SilverImage: data.silverImage,
        },
      };

    default:
      return null;
  }
};
