/* eslint-disable */
import * as Yup from 'yup';
import {
  formattedUpdateCourierApiReq,
  formattedCreateCourierApiReq,
} from './selector';

// form validation
const createCourierBody = Yup.object().shape({
  title: Yup.string()
    .required('Title is required')
    .test('Title', 'No special characters are allowed in Title', (title) => {
      const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
      return !specialChars.test(title);
    }),
  cpId: Yup.string()
    .required('CPID is required')
    .test('cpId', 'No special characters are allowed in CPID', (cpId) => {
      const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
      return !specialChars.test(cpId);
    }),
  allowTracking: Yup.boolean().required('Allow Tracking is required'),
});

const updateCourierBody = Yup.object().shape({
  title: Yup.string()
    .required('Title is required')
    .test('Title', 'No special characters are allowed in Title', (title) => {
      const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
      return !specialChars.test(title);
    }),
  cpId: Yup.string()
    .required('CPID is required')
    .test('cpId', 'No special characters are allowed in CPID', (cpId) => {
      const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
      return !specialChars.test(cpId);
    }),
});

export const validateAndGetFormattedCreateCourierParams = async (obj) => {
  await createCourierBody.validate(obj);
  return formattedCreateCourierApiReq(obj);
};

export const validateAndGetFormattedUpdateCourierParams = async (obj) => {
  await updateCourierBody.validate(obj);
  return formattedUpdateCourierApiReq(obj);
};
