import {
  GET_REWARD_LANDING,
  GET_REWARD_LANDING_ERROR,
  GET_REWARD_LANDING_SUCCESS,
  UPDATE_REWARD_LANDING,
  UPDATE_REWARD_LANDING_SUCCESS,
  UPDATE_REWARD_LANDING_ERROR,
  PUBLISH_REWARD_LANDING,
  PUBLISH_REWARD_LANDING_REQUEST,
  PUBLISH_REWARD_LANDING_SUCCESS,
  PUBLISH_REWARD_LANDING_ERROR,
} from 'redux/actions';

export const getRewardLanding = () => ({
  type: GET_REWARD_LANDING,
});

export const getRewardLandingError = (payload) => ({
  type: GET_REWARD_LANDING_ERROR,
  payload,
});

export const getRewardLandingSuccess = (payload) => ({
  type: GET_REWARD_LANDING_SUCCESS,
  payload,
});

// UPDATE_DOG_TREAT

export const updateRewardLanding = (payload) => ({
  type: UPDATE_REWARD_LANDING,
  payload,
});

export const updateRewardLandingSuccess = (payload) => ({
  type: UPDATE_REWARD_LANDING_SUCCESS,
  payload,
});

export const updateRewardLandingError = (payload) => ({
  type: UPDATE_REWARD_LANDING_ERROR,
  payload,
});

// PUBLISH_DOG_TREAT

export const publishRewardLanding = (payload) => ({
  type: PUBLISH_REWARD_LANDING,
  payload,
});

export const publishRewardLandingRequest = (payload) => ({
  type: PUBLISH_REWARD_LANDING_REQUEST,
  payload,
});

export const publishRewardLandingSuccess = (payload) => ({
  type: PUBLISH_REWARD_LANDING_SUCCESS,
  payload,
});

export const publishRewardLandingError = (payload) => ({
  type: PUBLISH_REWARD_LANDING_ERROR,
  payload,
});
