import {
  GET_SEARCH_DATA,
  GET_SEARCH_DATA_ERROR,
  GET_SEARCH_DATA_SUCCESS,
  PUBLISH_SEARCH_REQUEST,
  PUBLISH_SEARCH_DATA,
  PUBLISH_SEARCH_DATA_ERROR,
  PUBLISH_SEARCH_DATA_SUCCESS,
  SAVE_SEARCH_VERSION,
  UPDATE_SEARCH_DATA,
  UPDATE_SEARCH_DATA_ERROR,
  UPDATE_SEARCH_DATA_SUCCESS,
} from 'redux/actions';

// get search data api
export const getSearchData = () => ({
  type: GET_SEARCH_DATA,
});

export const getSearchDataError = (payload) => ({
  type: GET_SEARCH_DATA_ERROR,
  payload,
});

export const getSearchDataSuccess = (payload) => ({
  type: GET_SEARCH_DATA_SUCCESS,
  payload,
});

// update search data api

export const updateSearchData = (payload) => ({
  type: UPDATE_SEARCH_DATA,
  payload,
});

export const updateSearchDataSuccess = (payload) => ({
  type: UPDATE_SEARCH_DATA_SUCCESS,
  payload,
});

export const publishSearchRequest = (payload) => ({
  type: PUBLISH_SEARCH_REQUEST,
  payload,
});

export const updateSearchDataError = (payload) => ({
  type: UPDATE_SEARCH_DATA_ERROR,
  payload,
});

// add search data api

export const addSearchVersion = (payload) => ({
  type: SAVE_SEARCH_VERSION,
  payload,
});

// publish search data api

export const publishSearchData = (payload) => ({
  type: PUBLISH_SEARCH_DATA,
  payload,
});

export const publishSearchDataSuccess = (payload) => ({
  type: PUBLISH_SEARCH_DATA_SUCCESS,
  payload,
});

export const publishSearchDataError = (payload) => ({
  type: PUBLISH_SEARCH_DATA_ERROR,
  payload,
});
