// get api params

export const getDogTreatParams = () => ({
  params: {
    populate:
      'Silver.FrontImage,Silver.BackImage,Silver.ProgressBarImage,Silver.Link.Image,Bronze.FrontImage,Bronze.BackImage,Bronze.ProgressBarImage,Bronze.Link.Image,Gold.FrontImage,Gold.BackImage,Gold.ProgressBarImage,Gold.Link.Image,AdditionalInfo.Link',
  },
});
export const formatRewardDataForTable = (data = []) => {
  return {
    vuid: {
      vuid: data.vuid,
    },

    Silver: {
      id: data.Silver && data.Silver.id,
      title: data.Silver && data.Silver.Title,
      frontImage:
        data.Silver.FrontImage.data && data.Silver.FrontImage.data.attributes,
      backImage:
        data.Silver.BackImage.data && data.Silver.BackImage.data.attributes,
      progressBarImage:
        data.Silver.ProgressBarImage.data &&
        data.Silver.ProgressBarImage.data.attributes,

      links:
        data?.Silver?.Link &&
        data?.Silver?.Link.map((d) => ({
          id: d.id,
          title: d.Title,
          description: d.Description,
          imageData: d.Image.data,
          // createDate: d.attributes.createdAt,
          // updateDate: d.attributes.updatedAt,
          // state: d.attributes.publishedAt, // not sure for this value, in future we'll check & change it
        })),
    },
    Bronze: {
      id: data.Bronze && data.Bronze.id,
      title: data.Bronze && data.Bronze.Title,
      frontImage:
        data.Bronze.FrontImage.data && data.Bronze.FrontImage.data.attributes,
      backImage:
        data.Bronze.BackImage.data && data.Bronze.BackImage.data.attributes,
      progressBarImage:
        data.Bronze.ProgressBarImage.data &&
        data.Bronze.ProgressBarImage.data.attributes,
      links:
        data?.Bronze?.Link &&
        data?.Bronze?.Link.map((d) => ({
          id: d.id,
          title: d.Title,
          description: d.Description,
          imageData: d.Image.data,
        })),
    },
    Gold: {
      id: data.Gold && data.Gold.id,
      title: data.Gold && data.Gold.Title,
      frontImage:
        data.Gold.FrontImage.data && data.Gold.FrontImage.data.attributes,
      backImage:
        data.Gold.BackImage.data && data.Gold.BackImage.data.attributes,
      progressBarImage:
        data.Gold.ProgressBarImage.data &&
        data.Gold.ProgressBarImage.data.attributes,

      links:
        data?.Gold?.Link &&
        data?.Gold?.Link.map((d) => ({
          id: d.id,
          title: d.Title,
          description: d.Description,
          imageData: d.Image.data,
        })),
    },
    AdditionalInfo: {
      id: data.AdditionalInfo && data.AdditionalInfo.id,
      title: data.AdditionalInfo && data.AdditionalInfo.Title,
      buttonText: data.AdditionalInfo && data.AdditionalInfo.ButtonText,

      links:
        data?.AdditionalInfo &&
        data?.AdditionalInfo?.Link.map((d) => ({
          id: d.id,
          title: d.Title,
        })),
    },
  };
};

export const getFormattedDogUpdateParams = ({ data, dataKey }) => {
  switch (dataKey) {
    case 'Silver':
      return {
        Silver: {
          Id: data.id,
          Title: data.title,
          FrontImage: data.frontImage,
          BackImage: data.backImage,
          ProgressBarImage: data.progressBarImage,
          Link: data.links.map((lin) => ({
            Id: lin.id,
            Title: lin.title,
            Description: lin.description,
            Image: lin.imageData,
          })),
        },
      };
    case 'Bronze':
      return {
        Bronze: {
          Id: data.id,
          Title: data.title,
          FrontImage: data.frontImage,
          BackImage: data.backImage,
          ProgressBarImage: data.progressBarImage,
          Link: data.links.map((lin) => ({
            Id: lin.id,
            Title: lin.title,
            Description: lin.description,
            Image: lin.imageData,
          })),
        },
      };
    case 'Gold':
      return {
        Gold: {
          Id: data.id,
          Title: data.title,
          FrontImage: data.frontImage,
          BackImage: data.backImage,
          ProgressBarImage: data.progressBarImage,
          Link: data.links.map((lin) => ({
            Id: lin.id,
            Title: lin.title,
            Description: lin.description,
            Image: lin.imageData,
          })),
        },
      };
    case 'AdditionalInfo':
      return {
        AdditionalInfo: {
          Id: data.id,
          Title: data.title,
          ButtonText: data.buttonText,
          Link: data.links.map((lin) => ({
            Id: lin.id,
            Title: lin.title,
          })),
        },
      };

    default:
      return null;
  }
};
