import {
  CREATE_SHIPPING,
  CREATE_SHIPPING_ERROR,
  CREATE_SHIPPING_SUCCESS,
  GET_SHIPPING_DATA,
  GET_SHIPPING_DATA_ERROR,
  GET_SHIPPING_DATA_SUCCESS,
  PUBLISH_SHIPPING_DATA_ERROR,
  PUBLISH_SHIPPING_DATA_SUCCESS,
  PUBLISH_SHIPPING_REQUEST,
  SET_NEW_SHIPPING_FIELD,
  SET_SHIPPING_FIELD,
  SET_SHIPPING_MODAL,
  UPDATE_SHIPPING_DATA,
  UPDATE_SHIPPING_DATA_ERROR,
  UPDATE_SHIPPING_DATA_SUCCESS,
} from '../actions';

const INIT_STATE = {
  data: [],
  loading: false,
  error: '',
  showShippingModal: false,
  updateSuccess: false,
  isPublishing: false,
  newShipping: {
    CartValueFrom: '',
    CartValueTo: '',
    ShippingCost: '',
    PaymentMethod: '',
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    // GET_SHIPPING_DATA
    case GET_SHIPPING_DATA:
      return { ...state, loading: true };

    case GET_SHIPPING_DATA_ERROR:
      return { ...state, loading: false, error: action.payload };

    case GET_SHIPPING_DATA_SUCCESS:
      return { ...state, loading: false, ...action.payload };

    // SET_SHIPPING_MODAL
    case SET_SHIPPING_MODAL:
      return { ...state, showShippingModal: action.payload };

    // UPDATE_SHIPPING_DATA
    case UPDATE_SHIPPING_DATA:
      return { ...state, loading: true };

    case UPDATE_SHIPPING_DATA_SUCCESS:
      return { ...state, newShipping: {}, loading: false };

    case UPDATE_SHIPPING_DATA_ERROR:
      return { ...state, loading: false, error: action.payload };

    // PUBLISH_SHIPPING_REQUEST
    case PUBLISH_SHIPPING_REQUEST:
      return {
        ...state,
        isPublishing: true,
        error: false,
      };

    case PUBLISH_SHIPPING_DATA_SUCCESS:
      return {
        ...state,
        isPublishing: false,
        error: false,
      };

    case PUBLISH_SHIPPING_DATA_ERROR:
      return {
        ...state,
        isPublishing: false,
        error: true,
      };

    // SET_SHIPPING_FIELD

    case SET_SHIPPING_FIELD:
      return { ...state, ...action.payload };

    case SET_NEW_SHIPPING_FIELD:
      return {
        ...state,
        newShipping: { ...state.newShipping, ...action.payload },
      };

    // CREATE_SHIPPING
    case CREATE_SHIPPING:
      return { ...state, loading: true };

    case CREATE_SHIPPING_SUCCESS:
      return { ...state, newShipping: {}, loading: false };

    case CREATE_SHIPPING_ERROR:
      return { ...state, loading: false, error: action.payload };

    default:
      return { ...state };
  }
};
