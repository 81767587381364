import { NotificationManager } from 'components/common/react-notifications';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  CREATE_REWARD_CONDITION,
  DELETE_REWARD_CONDITION,
  GET_REWARD_CONDITION,
  UPDATE_REWARD_CONDITION,
  // PUBLISH_COURIER_DATA,
} from 'redux/actions';
import axios from 'utils/CmsApi';

import {
  // configuredAxios,
  // getAuthorizationHeader,
  accessRefreshToken,
} from 'utils/Api';
import {
  createRewardConditionError,
  createRewardConditionSuccess,
  deleteRewardConditionSuccess,
  deleteRewardConditionError,
  getRewardCondition,
  getRewardConditionError,
  getRewardConditionSuccess,
  setRewardConditionModal,
  updateRewardConditionError,
  updateRewardConditionSuccess,
} from './actions';
import { formatRewardConditionData } from './selector';
import {
  validateAndGetFormattedCreateUserParams,
  validateAndGetFormattedUpdateUserParams,
} from './validator';

/**
 * Calls the API to get reward condition request.
 *
 * @async
 * @function
 * @returns {Promise} The response from the API call.
 */
const getRewardConditionRequest = async () => {
  accessRefreshToken();
  const response = await axios.get('/reward-term-and-conditions');
  return response;
};

/**
 * Calls the API to get reward condition request.
 *
 * @async
 * @function
 * @returns {Promise} The response from the API call.
 */
// create new user
const createRewardConditionRequest = async (data) => {
  accessRefreshToken();
  const body = await validateAndGetFormattedCreateUserParams(data);
  const response = await axios.post('/reward-term-and-conditions', body);
  return response;
};

/**
 * Calls the API to delete reward condition request.
 *
 * @async
 * @function
 * @returns {Promise} The response from the API call.
 */
const deleteRewardConditionRequest = async (data) => {
  accessRefreshToken();
  const response = await axios.delete(`/reward-term-and-conditions/${data}`);
  return response;
};

/**
 * Calls the API to put reward condition request.
 *
 * @async
 * @function
 * @returns {Promise} The response from the API call.
 */
const updateRewardConditionRequest = async (data) => {
  accessRefreshToken();
  const body = await validateAndGetFormattedUpdateUserParams(data);
  const response = await axios.put(
    `/reward-term-and-conditions/${data?.id}`,
    body
  );
  return response;
};

/**
 * Calls the API to get reward condition request.
 *
 * @async
 * @function
 * @returns {Promise} The response from the API call.
 */
function* fetchRewardConditions() {
  try {
    const response = yield call(getRewardConditionRequest);
    if (response.data) {
      yield put(
        getRewardConditionSuccess(formatRewardConditionData(response.data.data))
      );
    } else {
      yield put(getRewardConditionError('no data received'));
    }
  } catch (error) {
    yield put(getRewardConditionError(error));
  }
}

/**
 * Calls the API to post reward condition request.
 *
 * @async
 * @function
 * @returns {Promise} The response from the API call.
 */

function* createRewardCondition(data) {
  try {
    const response = yield call(createRewardConditionRequest, data.payload);
    if (response.status === 200) {
      NotificationManager.success(
        'User created successfully',
        'Create User',
        3000,
        null,
        null,
        ''
      );
      yield put(getRewardCondition());
      yield put(setRewardConditionModal(false));
      yield put(createRewardConditionSuccess(response));
    }
  } catch (error) {
    if (error.name === 'ValidationError') {
      NotificationManager.error(error.message, 'Create User');
    } else if (error.data.message) {
      NotificationManager.error(
        error.data.message,
        'Create User',
        3000,
        null,
        null,
        ''
      );
    }
    yield put(createRewardConditionError(error));
  }
}

/**
 * Calls the API to delete reward condition request.
 *
 * @async
 * @function
 * @returns {Promise} The response from the API call.
 */
function* deleteRewardCondition(data) {
  try {
    const response = yield call(deleteRewardConditionRequest, data.payload);
    if (response.status === 200) {
      yield put(getRewardCondition());
      yield put(deleteRewardConditionSuccess());
      NotificationManager.success(
        'User deleted successfully',
        'Delete User',
        3000,
        null,
        null,
        ''
      );
    }
  } catch (error) {
    NotificationManager.error(
      error.data.message,
      'Delete User',
      3000,
      null,
      null,
      ''
    );
    yield put(deleteRewardConditionError(error));
  }
}

/**
 * Saga function to update reward condition data.
 *
 * @generator
 * @function
 * @param {Object} data - The data to be updated.
 */
function* updateRewardCondition(data) {
  try {
    const response = yield call(updateRewardConditionRequest, data.payload);
    if (response.status === 200) {
      NotificationManager.success(
        'User updated successfully',
        'Update User',
        3000,
        null,
        null,
        ''
      );
      yield put(getRewardCondition());
      yield put(setRewardConditionModal(false));
      yield put(updateRewardConditionSuccess(response));
    }
  } catch (error) {
    if (error.name === 'ValidationError') {
      NotificationManager.error(error.message, 'Create User');
    } else {
      NotificationManager.error(
        error.data.message,
        'Update User',
        3000,
        null,
        null,
        ''
      );
    }
    yield put(updateRewardConditionError(error));
  }
}

export function* watchGetRewardCondition() {
  yield takeLatest(GET_REWARD_CONDITION, fetchRewardConditions);
}

export function* watchCreateRewardCondition() {
  yield takeLatest(CREATE_REWARD_CONDITION, createRewardCondition);
}

export function* watchDeleteRewardCondition() {
  yield takeLatest(DELETE_REWARD_CONDITION, deleteRewardCondition);
}

export function* watchUpdateRewardCondition() {
  yield takeLatest(UPDATE_REWARD_CONDITION, updateRewardCondition);
}

export function* watchPublishCourierData() {
  // yield takeLatest(PUBLISH_COURIER_DATA, publishCourierData);
}

/**
 * Root Saga that combines all sagas.
 *
 * @generator
 * @function
 */
export default function* rootSaga() {
  yield all([
    fork(watchGetRewardCondition),
    fork(watchCreateRewardCondition),
    fork(watchDeleteRewardCondition),
    fork(watchUpdateRewardCondition),
    fork(watchPublishCourierData),
  ]);
}
