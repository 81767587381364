import { NotificationManager } from 'components/common/react-notifications';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { GET_UPLOADED_FILES, UPLOAD_IMAGE } from 'redux/actions';
import { accessRefreshToken } from 'utils/Api';
import cmsAxios from 'utils/CmsApi';
import {
  getUploadedFilesError,
  getUploadedFilesSuccess,
  uploadImageError,
  uploadImageSuccess,
} from './actions';
import { imageFormat } from './selector';

const uploadImageRequest = async (formData) => {
  accessRefreshToken();
  const response = await cmsAxios.post('/upload', formData);
  return response;
};

const getUploadedImageRequest = async () => {
  accessRefreshToken();
  const response = await cmsAxios.get('/upload/files');
  return response;
};

function* uploadImage(data) {
  try {
    const fileInfo = {
      name: data.payload.images[0].name,
      caption: data.payload.images[0].name,
      alternativeText: data.payload.images[0].name,
    };
    const formData = new FormData();
    formData.append('files', data.payload.images[0]);
    formData.append('fileInfo', JSON.stringify(fileInfo));
    const response = yield call(uploadImageRequest, formData);
    if (response) {
      NotificationManager.success('Upload Successfully', 'Upload');
      yield put(uploadImageSuccess(imageFormat(response.data)));
    } else {
      NotificationManager.error('Failed to upload data', 'Upload');
      yield put(uploadImageError('no response received'));
    }
  } catch (error) {
    NotificationManager.error('Failed to upload data', 'Upload');
    yield put(uploadImageError('no response received'));
  }
}

function* getUploadedFiles() {
  try {
    const response = yield call(getUploadedImageRequest);
    yield put(getUploadedFilesSuccess(imageFormat(response.data.reverse())));
  } catch (error) {
    yield put(getUploadedFilesError(error));
  }
}

export function* watchUploadImage() {
  yield takeLatest(UPLOAD_IMAGE, uploadImage);
}

export function* watchGetUploadedFiles() {
  yield takeLatest(GET_UPLOADED_FILES, getUploadedFiles);
}

export default function* rootSaga() {
  yield all([fork(watchUploadImage), fork(watchGetUploadedFiles)]);
}
