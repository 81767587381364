import * as Yup from 'yup';
import { getFormattedCatUpdateParams } from './selector';

// form validation
const linkValidateParamsBody = Yup.object().shape({
  title: Yup.string()
    .required('Title is required')
    .test(
      'title',
      'Title must be less then 50 character',
      (title) => title.length <= 50 && title.length > 0
    ),
  value: Yup.string()
    .required('Value is required')
    .test(
      'value',
      'Value must be less the 50 character',
      (value) => value.length <= 50 && value.length > 0
    ),
  objectType: Yup.string().required('Object type is reuqired'),
  imageData: Yup.object()
    .required('Image cannot be empty')
    .test('imageData', 'Image must be there', (imageData) => !!imageData?.id),
});

const swipeValidateParamsBody = Yup.object().shape({
  title: Yup.string()
    .required('Title is required')
    .test(
      'title',
      'Title must be less then 50 character',
      (title) => title.length <= 50 && title.length > 0
    ),
  subTitle: Yup.string()
    .required('SubTitle is required')
    .test(
      'subTitle',
      'Sub Title must be less then 50 character',
      (subTitle) => subTitle.length <= 50 && subTitle.length > 0
    ),
  links: Yup.array().of(
    Yup.object().shape({
      title: Yup.string()
        .required('Title is required')
        .test(
          'title',
          'Title must be less then 50 character',
          (title) => title.length <= 50 && title.length > 0
        ),
      value: Yup.string()
        .required('Value is required')
        .test(
          'value',
          'Value must be less the 50 character',
          (value) => value.length <= 50 && value.length > 0
        ),
      objectType: Yup.string().required('Object type is reuqired'),
      imageData: Yup.object()
        .required('Image cannot be empty')
        .test(
          'imageData',
          'Image must be there',
          (imageData) => !!imageData.id
        ),
    })
  ),
});

// eslint-disable-next-line import/prefer-default-export
export const validateAndGetFormattedCatUpdateParams = async (obj) => {
  switch (obj.dataKey) {
    case 'singleVerticleBanner':
      await linkValidateParamsBody.validate(obj.data);
      break;

    case 'carouselBannerImages':
      await swipeValidateParamsBody.validate(obj.data);
      break;

    case 'swipeSectionImages':
      await swipeValidateParamsBody.validate(obj.data);
      break;

    case 'dealsOfDay':
      await swipeValidateParamsBody.validate(obj.data);
      break;

    case 'subscriptions':
      await swipeValidateParamsBody.validate(obj.data);
      break;

    case 'bannerScrollSection':
      await swipeValidateParamsBody.validate(obj.data);
      break;

    case 'bankOffers':
      await swipeValidateParamsBody.validate(obj.data);
      break;

    case 'spaBookingBanner':
      await swipeValidateParamsBody.validate(obj.data);
      break;

    case 'brandCarousel':
      await swipeValidateParamsBody.validate(obj.data);
      break;

    case 'combosAndKits':
      await swipeValidateParamsBody.validate(obj.data);
      break;

    case 'ecoConscious':
      await swipeValidateParamsBody.validate(obj.data);
      break;

    case 'locateStore':
      await swipeValidateParamsBody.validate(obj.data);
      break;

    case 'foundationBanner':
      await swipeValidateParamsBody.validate(obj.data);
      break;

    case 'shopByConcern':
      await swipeValidateParamsBody.validate(obj.data);
      break;

    case 'bestFoodBrands':
      await swipeValidateParamsBody.validate(obj.data);
      break;

    case 'joinPuppyClub':
      await linkValidateParamsBody.validate(obj.data);
      break;

    case 'knowAboutYourPet':
      await swipeValidateParamsBody.validate(obj.data);
      break;

    case 'petBirthday':
      await linkValidateParamsBody.validate(obj.data);
      break;

    case 'giftCards':
      await swipeValidateParamsBody.validate(obj.data);
      break;

    default:
      break;
  }
  return getFormattedCatUpdateParams(obj);
};
