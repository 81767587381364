import * as Yup from 'yup';
import { getFormattedSearchUpdateParams } from './selector';

// form validation
const swipeValidateParamsBody = Yup.object().shape({
  title: Yup.string()
    .required('Title is required')
    .test(
      'title',
      'Title must be less then 50 character',
      (title) => title.length <= 50 && title.length > 0
    ),
  collectionHandle: Yup.string()
    .required('collectionHandle is required')
    .test(
      'collectionHandle',
      'collectionHandle must be less then 50 character',
      (collectionHandle) =>
        collectionHandle.length <= 50 && collectionHandle.length > 0
    ),
});

const linkValidateParamsBody = Yup.object().shape({
  title: Yup.string()
    .required('Title is required')
    .test(
      'title',
      'Title must be less then 50 character',
      (title) => title.length <= 50
    ),
  subTitle: Yup.string()
    .required('SubTitle is required')
    .test(
      'subTitle',
      'Title must be less then 50 character',
      (title) => title.length <= 50
    ),
  links: Yup.array().of(
    Yup.object().shape({
      title: Yup.string()
        .required('Title is required')
        .test(
          'title',
          'Title must be less then 50 character',
          (title) => title.length <= 50
        ),
      value: Yup.string()
        .required('Value is required')
        .test(
          'value',
          'Value must be less the 50 character',
          (value) => value.length <= 50
        ),
      objectType: Yup.string().required('Object type is reuqired'),
      imageData: Yup.object()
        .required('Image cannot be empty')
        .test(
          'imageData',
          'Image must be there',
          (imageData) => !!imageData?.id
        ),
    })
  ),
});

// eslint-disable-next-line import/prefer-default-export
export const validateAndGetFormattedSearchUpdateParams = async (obj) => {
  switch (obj.dataKey) {
    case 'featuredBrands':
      await linkValidateParamsBody.validate(obj.data);
      break;

    case 'exploreTopOffers':
      await swipeValidateParamsBody.validate(obj.data);
      break;

    default:
      break;
  }
  return getFormattedSearchUpdateParams(obj);
};
