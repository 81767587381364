import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { auth } from 'helpers/Firebase';
import { adminRoot, currentUser } from 'constants/defaultValues';
import { clearToken, setCurrentUser, setToken } from 'helpers/Utils';
import axios from 'utils/Api';
import { LOGIN_PATH } from 'utils/constants';
import { NotificationManager } from 'components/common/react-notifications';
import {
  LOGIN_USER,
  REGISTER_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  //   REFRESH_AUTHENTICATION,
} from '../actions';
import {
  loginUserSuccess,
  loginUserError,
  registerUserSuccess,
  registerUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
} from './actions';
import { formatCurrentUser } from './selector';

// call login api using axios
const loginWithEmailPasswordAsync = async (email, password) => {
  const response = await axios.post('/auth/login', { email, password });
  return response;
};

// call login api
function* loginWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  try {
    const loginUser = yield call(loginWithEmailPasswordAsync, email, password);
    setToken(loginUser.data.tokens);
    if (loginUser.data.user) {
      setCurrentUser(formatCurrentUser(loginUser.data.user));
      yield put(loginUserSuccess(loginUser.data.user));
      window.location.reload();
    } else {
      yield put(loginUserError('Something went wrong'));
    }
  } catch (error) {
    if (error?.status === 400) {
      yield put(loginUserError('User not found'));
    } else if (error?.status === 401) {
      yield put(loginUserError('User is Unauthorised'));
    } else {
      yield put(loginUserError(error?.message));
    }
  }
}

// call register api
const registerWithEmailPasswordAsync = async (email, password) => {
  const response = auth
    .createUserWithEmailAndPassword(email, password)
    .then((user) => user)
    .catch((error) => error);
  return response;
};

function* registerWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    const registerUser = yield call(
      registerWithEmailPasswordAsync,
      email,
      password
    );
    if (!registerUser.message) {
      const item = { uid: registerUser.user.uid, ...currentUser };
      setCurrentUser(item);
      yield put(registerUserSuccess(item));
      history.push(adminRoot);
    } else {
      yield put(registerUserError(registerUser.message));
    }
  } catch (error) {
    yield put(registerUserError(error));
  }
}

// logout function
const logoutAsync = async () => {
  await clearToken();
  window.location.reload();
};

// call logout api
function* logout({ payload }) {
  const { history } = payload;
  setCurrentUser();
  yield call(logoutAsync, history);
}

// call forgot api using axios
const forgotPasswordAsync = async (email) => {
  const response = await axios.post('/auth/forgot-password', { email });
  return response;
};

// call forgot api
function* forgotPassword({ payload }) {
  const { email } = payload.forgotUserMail;
  try {
    const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
    if (forgotPasswordStatus.code === 200) {
      localStorage.setItem('email', '');
      NotificationManager.success(
        forgotPasswordStatus?.message || 'Please check your mail',
        `Forgot Password Success ${email}`,
        3000,
        null,
        null,
        ''
      );
      yield put(forgotPasswordSuccess('success'));
    } else {
      localStorage.setItem('email', email);
      yield put(forgotPasswordError(forgotPasswordStatus.message));
      NotificationManager.warning(
        forgotPasswordStatus?.message || 'Failed to send mail',
        `Forgot Password Error`,
        3000,
        null,
        null,
        ''
      );
    }
  } catch (error) {
    localStorage.setItem('email', email);
    yield put(forgotPasswordError(error?.message));
    NotificationManager.warning(
      error?.message,
      `Forgot Password Error`,
      3000,
      null,
      null,
      ''
    );
  }
}

const resetPasswordAsync = async (resetPasswordCode, newPassword) => {
  const response = await axios.post(
    `/auth/reset-password?token=${resetPasswordCode}`,
    { password: newPassword }
  );
  return response;
};

// const refreshAuthAsync = async () => {
//   const token = getToken();
//   if (!isTokenTimeExpired(token?.refresh?.expires)) {
//     const response = await axios.post('/auth/refresh-tokens', {
//       refreshToken: token.refresh.token,
//     });
//     setToken(response.data);
//   } else {
//     clearToken();
//     window.location.reload();
//   }
// };

// call reset api
function* resetPassword({ payload }) {
  const { newPassword, resetPasswordCode, history } = payload;
  try {
    const resetPasswordStatus = yield call(
      resetPasswordAsync,
      resetPasswordCode,
      newPassword
    );
    if (resetPasswordStatus.code === 200) {
      yield put(resetPasswordSuccess('success'));
      NotificationManager.success(
        resetPasswordStatus?.message,
        'Update User',
        3000,
        null,
        null,
        ''
      );
    } else {
      NotificationManager.error(
        resetPasswordStatus?.message,
        'Update User',
        3000,
        null,
        null,
        ''
      );
      yield put(resetPasswordError(resetPasswordStatus.message));
    }
    history.push(LOGIN_PATH);
  } catch (error) {
    NotificationManager.error(
      error?.data?.message,
      'Update User',
      3000,
      null,
      null,
      ''
    );
    yield put(resetPasswordError(error?.data?.message));
  }
}

// function* refreshAuthentication() {
//   try {
//     yield call(refreshAuthAsync);
//   } catch (error) {
//     console.log(error);
//   }
// }

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}

export function* watchForgotPassword() {
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

export function* watchResetPassword() {
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

export function* watchRegisterUser() {
  yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

// export function* watchRefreshAuthentication() {
//   yield takeEvery(REFRESH_AUTHENTICATION, refreshAuthentication);
// }

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgotPassword),
    fork(watchResetPassword),
    // fork(watchRefreshAuthentication),
  ]);
}
