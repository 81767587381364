import axios from 'axios';
import { clearToken, getToken, setToken } from 'helpers/Utils';
import { isTokenTimeExpired } from './DateTime';

/**
 * The base URL for API requests.
 * @type {string}
 */
const { REACT_APP_APP_URL } = process.env;

export const getAuthorizationHeader = () =>
  `Bearer ${getToken()?.access?.token}`;

/**
 * Get the authorization header containing the bearer token.
 *
 * @returns {string} The authorization header.
 */
const configuredAxios = axios.create({
  baseURL: REACT_APP_APP_URL,
  headers: {
    Authorization: getAuthorizationHeader(),
    'Content-Type': 'application/json',
  },
});

configuredAxios.interceptors.request.use(
  async (config) => {
    const token = getToken();
    if (
      token &&
      config.url !== '/auth/refresh-tokens' &&
      isTokenTimeExpired(token?.access?.expires)
    ) {
      if (!isTokenTimeExpired(token?.refresh?.expires)) {
        const rs = await configuredAxios.post('/auth/refresh-tokens', {
          refreshToken: token.refresh.token,
        });
        // clearToken();
        setToken(rs.data);
        config.headers.Authorization = `Bearer ${rs?.data?.access?.token}`; // eslint-disable-line no-param-reassign
      } else {
        clearToken();
        window.location.reload();
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error.response);
  }
);

configuredAxios.interceptors.response.use(
  (res) => {
    return res.data;
  },
  async (err) => {
    const originalConfig = err.config;
    if (originalConfig.url === '/auth/refresh-tokens' && err.response) {
      if (err.response.status === 401) {
        clearToken();
        window.location.reload();
        return configuredAxios(originalConfig);
      }
    }
    return Promise.reject(err.response);
  }
);

/**
 * Access and refresh the token.
 *
 * @returns {void}
 */
export const accessRefreshToken = async () => {
  const token = JSON.parse(localStorage.getItem('token'));
  if (isTokenTimeExpired(token?.access?.expires)) {
    const rs = await configuredAxios.post('/auth/refresh-tokens', {
      refreshToken: token.refresh.token,
    });
    // clearToken();
    // console.log(rs, '/////////////rs');
    setToken(rs.data);
    // config.headers.Authorization = `Bearer ${rs?.data?.access?.token}`; // eslint-disable-line no-param-reassign
  }
  // console.log(token, '//////////token');
};

export default configuredAxios;
