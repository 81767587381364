import {
  GET_DOG_GROOMING,
  GET_DOG_GROOMING_ERROR,
  GET_DOG_GROOMING_SUCCESS,
  UPDATE_DOG_GROOMING,
  UPDATE_DOG_GROOMING_ERROR,
  UPDATE_DOG_GROOMING_SUCCESS,
  PUBLISH_DOG_GROOMING,
  PUBLISH_DOG_GROOMING_ERROR,
  PUBLISH_DOG_GROOMING_SUCCESS,
  PUBLISH_DOG_GROOMING_REQUEST,
} from 'redux/actions';

// GET_DOG_GROOMING
export const getDogGrooming = () => ({
  type: GET_DOG_GROOMING,
});

export const getDogGroomingError = (payload) => ({
  type: GET_DOG_GROOMING_ERROR,
  payload,
});

export const getDogGroomingSuccess = (payload) => ({
  type: GET_DOG_GROOMING_SUCCESS,
  payload,
});

// UPDATE_DOG_GROOMING
export const updateDogGrooming = (payload) => ({
  type: UPDATE_DOG_GROOMING,
  payload,
});

export const updateDogGroomingSuccess = (payload) => ({
  type: UPDATE_DOG_GROOMING_SUCCESS,
  payload,
});

export const updateDogGroomingError = (payload) => ({
  type: UPDATE_DOG_GROOMING_ERROR,
  payload,
});

// PUBLISH_DOG_GROOMING
export const publishDogGrooming = (payload) => ({
  type: PUBLISH_DOG_GROOMING,
  payload,
});

export const publishDogGroomingRequest = (payload) => ({
  type: PUBLISH_DOG_GROOMING_REQUEST,
  payload,
});

export const publishDogGroomingSuccess = (payload) => ({
  type: PUBLISH_DOG_GROOMING_SUCCESS,
  payload,
});

export const publishDogGroomingError = (payload) => ({
  type: PUBLISH_DOG_GROOMING_ERROR,
  payload,
});
