import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import authUser from './auth/reducer';
import user from './user/reducer';
import todoApp from './todo/reducer';
import chatApp from './chat/reducer';
import surveyListApp from './surveyList/reducer';
import surveyDetailApp from './surveyDetail/reducer';
import common from './common/reducer';
import cat from './cat/reducer';
import dog from './dog/reducer';
import dogTreat from './AllDogTreat/reducer';
import dogGroomingPage from './DogGrooming/reducer';

import offers from './offers/reducer';
import smallAnimal from './smallAnimal/reducer';
import search from './search/reducer';
import shippingRate from './shippingRate/reducer';
import courierDetails from './courierDetails/reducer';
import saraWholeSome from './saraWholesome/reducer';
import rewardDetails from './reward/reducer';
import rewardLevels from './rewardLevel/reducer';
import rewardLanding from './rewardLanding/reducer';
import rewardCondition from './rewardCondition/reducer';

const reducers = combineReducers({
  menu,
  user,
  common,
  settings,
  authUser,
  todoApp,
  chatApp,
  surveyListApp,
  surveyDetailApp,
  cat,
  dog,
  offers,
  search,
  smallAnimal,
  shippingRate,
  courierDetails,
  dogTreat,
  dogGroomingPage,
  saraWholeSome,
  rewardDetails,
  rewardLevels,
  rewardLanding,
  rewardCondition,
});

export default reducers;
