/* eslint-disable */
import * as Yup from 'yup';
import {
  formattedCreateUserApiReq,
  formattedUpdateUserApiReq,
} from './selector';

// form validation
const createUserBody = Yup.object().shape({
  firstName: Yup.string()
    .required('First Name is required')
    .test(
      'firstName',
      'No special characters are allowed in first name',
      (firstName) => {
        const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        return !specialChars.test(firstName);
      }
    ),
  lastName: Yup.string()
    .required('Last Name is required')
    .test(
      'lastName',
      'No special characters are allowed in last name',
      (lastName) => {
        const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        return !specialChars.test(lastName);
      }
    ),
  mobile: Yup.string()
    .required('Mobile is required')
    .test(
      'Mobile',
      'Mobile must at least 10 digits',
      (mobile) => mobile.length === 10
    ),
  email: Yup.string().required('Email is required'),
  role: Yup.string().required('Role is required'),
  password: Yup.string()
    .required('Password is required')
    .test(
      'password',
      'Password must be less then 50 character',
      (password) => password.length <= 50
    ),
});

const updateUserBody = Yup.object().shape({
  firstName: Yup.string()
    .required('First Name is required')
    .test(
      'firstName',
      'No special characters are allowed in first name',
      (firstName) => {
        const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        return !specialChars.test(firstName);
      }
    ),
  lastName: Yup.string()
    .required('Last Name is required')
    .test(
      'lastName',
      'No special characters are allowed in last name',
      (lastName) => {
        const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        return !specialChars.test(lastName);
      }
    ),
  mobile: Yup.string()
    .required('Mobile is required')
    .test(
      'Mobile',
      'Mobile must at least 10 digits',
      (mobile) => mobile.length === 10
    ),
  role: Yup.string().required('Role is required'),
});

export const validateAndGetFormattedCreateUserParams = async (obj) => {
  await createUserBody.validate(obj);
  return formattedCreateUserApiReq(obj);
};

export const validateAndGetFormattedUpdateUserParams = async (obj) => {
  await updateUserBody.validate(obj);
  return formattedUpdateUserApiReq(obj);
};
