import {
  CREATE_REWARD_CONDITION,
  CREATE_REWARD_CONDITION_ERROR,
  CREATE_REWARD_CONDITION_SUCCESS,
  DELETE_REWARD_CONDITION,
  DELETE_REWARD_CONDITION_ERROR,
  DELETE_REWARD_CONDITION_SUCCESS,
  SET_NEW_REWARD_CONDITION_FIELD,
  SET_REWARD_CONDITION_FIELD,
  SET_REWARD_CONDITION_MODAL,
  UPDATE_REWARD_CONDITION,
  UPDATE_REWARD_CONDITION_ERROR,
  UPDATE_REWARD_CONDITION_SUCCESS,
  GET_REWARD_CONDITION,
  GET_REWARD_CONDITION_ERROR,
  GET_REWARD_CONDITION_SUCCESS,
} from '../actions';

const INIT_STATE = {
  users: [],
  loading: false,
  showRewardConditionModal: false,
  newRewardCondition: {
    title: '',
  },
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    // USERS_GET_LIST
    case GET_REWARD_CONDITION:
      return { ...state, loading: true };

    case GET_REWARD_CONDITION_SUCCESS:
      return { ...state, loading: false, users: action.payload.data };

    case GET_REWARD_CONDITION_ERROR:
      return { ...state, loading: false, error: action.payload };

    // SET_USER_MODAL
    case SET_REWARD_CONDITION_MODAL:
      return { ...state, showRewardConditionModal: action.payload };

    case SET_NEW_REWARD_CONDITION_FIELD:
      return {
        ...state,
        newRewardCondition: { ...state.newRewardCondition, ...action.payload },
      };

    case SET_REWARD_CONDITION_FIELD:
      return { ...state, ...action.payload };

    // CREATE_USER
    case CREATE_REWARD_CONDITION:
      return { ...state, loading: true };

    case CREATE_REWARD_CONDITION_SUCCESS:
      return { ...state, newRewardCondition: {}, loading: false };

    case CREATE_REWARD_CONDITION_ERROR:
      return { ...state, loading: false, error: action.payload };

    // DELETE_USER
    case DELETE_REWARD_CONDITION:
      return { ...state, loading: true };

    case DELETE_REWARD_CONDITION_SUCCESS:
      return { ...state, loading: false };

    case DELETE_REWARD_CONDITION_ERROR:
      return { ...state, loading: false, error: action.payload };

    // UPDATE_USER
    case UPDATE_REWARD_CONDITION:
      return { ...state, loading: true };

    case UPDATE_REWARD_CONDITION_SUCCESS:
      return { ...state, loading: false, newRewardCondition: {} };

    case UPDATE_REWARD_CONDITION_ERROR:
      return { ...state, loading: false, error: action.payload };

    default:
      return { ...state };
  }
};
