// get api params
export const getOffersParams = () => ({
  params: {
    populate:
      'DealOfTheDay.Link.Image,PopularCategories.Link.Image,FeaturedBrands.Link.Image,ExclusivelyForYou.Link.Image,HuftClearance.HuftProduct,HuftClearance.Image,Banner.Link.Image,BankOffers.Link.Image',
  },
});

export const formatOffersData = (data) => {
  const ans = {
    vuid: {
      vuid: data.vuid,
    },
    banner: {
      id: data?.Banner?.Link && data?.Banner?.Link?.id,
      title: data?.Banner?.Link && data?.Banner?.Link?.Title,
      value: data?.Banner?.Link && data?.Banner?.Link?.Value,
      objectType: data?.Banner?.Link && data?.Banner?.Link?.ObjectType,
      imageData: data?.Banner?.Link && data?.Banner?.Link?.Image.data,
      ImageHeight: data?.Banner && data?.Banner.ImageHeight,
      ImageWidth: data?.Banner && data?.Banner.ImageWidth,
    },
    dealOfTheDay: {
      id: data?.DealOfTheDay?.Link && data?.DealOfTheDay?.Link?.id,
      title: data?.DealOfTheDay?.Link && data?.DealOfTheDay?.Link?.Title,
      value: data?.DealOfTheDay?.Link && data?.DealOfTheDay?.Link?.Value,
      objectType:
        data?.DealOfTheDay?.Link && data?.DealOfTheDay?.Link?.ObjectType,
      imageData:
        data?.DealOfTheDay?.Link && data?.DealOfTheDay?.Link?.Image.data,
    },
    bankOffers: {
      id: data?.BankOffers && data?.BankOffers?.id,
      title: data?.BankOffers && data?.BankOffers?.Title,
      subTitle: data?.BankOffers && data?.BankOffers?.SubTitle,
      ImageHeight: data?.BankOffers && data?.BankOffers.ImageHeight,
      ImageWidth: data?.BankOffers && data?.BankOffers.ImageWidth,
      links:
        data?.BankOffers &&
        data?.BankOffers?.Link?.map((lin) => ({
          imageData: lin.Image.data,
          objectType: lin.ObjectType,
          title: lin.Title,
          value: lin.Value,
          id: lin.id,
        })),
    },
    popularCategories: {
      id: data?.PopularCategories && data?.PopularCategories?.id,
      title: data?.PopularCategories && data?.PopularCategories?.Title,
      subTitle: data?.PopularCategories && data?.PopularCategories?.SubTitle,
      ImageHeight:
        data?.PopularCategories && data?.PopularCategories.ImageHeight,
      ImageWidth: data?.PopularCategories && data?.PopularCategories.ImageWidth,
      links:
        data?.PopularCategories &&
        data?.PopularCategories?.Link?.map((lin) => ({
          imageData: lin?.Image.data,
          objectType: lin?.ObjectType,
          title: lin?.Title,
          value: lin?.Value,
          id: lin?.id,
        })),
    },
    exclusivelyForYou: {
      id: data?.ExclusivelyForYou && data?.ExclusivelyForYou?.id,
      title: data?.ExclusivelyForYou && data?.ExclusivelyForYou?.Title,
      subTitle: data?.ExclusivelyForYou && data?.ExclusivelyForYou?.SubTitle,
      ImageHeight:
        data?.ExclusivelyForYou && data?.ExclusivelyForYou.ImageHeight,
      ImageWidth: data?.ExclusivelyForYou && data?.ExclusivelyForYou.ImageWidth,
      links:
        data?.ExclusivelyForYou &&
        data?.ExclusivelyForYou?.Link?.map((lin) => ({
          imageData: lin?.Image.data,
          objectType: lin?.ObjectType,
          title: lin?.Title,
          subTitle: lin?.SubTitle,
          value: lin?.Value,
          id: lin?.id,
        })),
    },
    featuredBrands: {
      id: data?.FeaturedBrands && data?.FeaturedBrands?.id,
      title: data?.FeaturedBrands && data?.FeaturedBrands?.Title,
      subTitle: data?.FeaturedBrands && data?.FeaturedBrands?.SubTitle,
      ImageHeight: data?.FeaturedBrands && data?.FeaturedBrands.ImageHeight,
      ImageWidth: data?.FeaturedBrands && data?.FeaturedBrands.ImageWidth,
      links:
        data?.FeaturedBrands &&
        data?.FeaturedBrands?.Link?.map((lin) => ({
          imageData: lin?.Image?.data,
          objectType: lin?.ObjectType,
          title: lin?.Title,
          value: lin?.Value,
          id: lin?.id,
        })),
    },
    huftClearance: {
      id: data?.HuftClearance && data?.HuftClearance?.id,
      imageData: data?.HuftClearance && data?.HuftClearance?.Image?.data,
      objectType: data?.HuftClearance && data?.HuftClearance?.ObjectType,
      value: data?.HuftClearance && data?.HuftClearance?.Value,
      ImageHeight: data?.HuftClearance && data?.HuftClearance.ImageHeight,
      ImageWidth: data?.HuftClearance && data?.HuftClearance.ImageWidth,
      links:
        data?.HuftClearance &&
        data?.HuftClearance?.HuftProduct?.map((lin) => ({
          title: lin?.ProductHandle,
          id: lin?.id,
        })),
    },
  };
  return ans;
};

// updated api params
export const getFormattedOffersUpdateParams = ({ data, dataKey }) => {
  switch (dataKey) {
    case 'popularCategories':
      return {
        PopularCategories: {
          Id: data?.id,
          Title: data?.title,
          SubTitle: data?.subTitle,
          ImageHeight: data.ImageHeight,
          ImageWidth: data.ImageWidth,
          Link: data?.links?.map((lin) => ({
            Image: lin?.imageData,
            ObjectType: lin?.objectType,
            Title: lin?.title,
            Value: lin?.value,
            Id: lin?.id,
          })),
        },
      };
    case 'featuredBrands':
      return {
        FeaturedBrands: {
          Id: data?.id,
          Title: data?.title,
          SubTitle: data?.subTitle,
          ImageHeight: data.ImageHeight,
          ImageWidth: data.ImageWidth,
          Link: data?.links?.map((lin) => ({
            Image: lin?.imageData,
            ObjectType: lin?.objectType,
            Title: lin?.title,
            Value: lin?.value,
            Id: lin?.id,
          })),
        },
      };
    case 'huftClearance':
      return {
        HuftClearance: {
          Id: data?.id,
          ObjectType: data?.objectType,
          Value: data?.value,
          ImageHeight: data.ImageHeight,
          ImageWidth: data.ImageWidth,
          Image: data?.imageData,
          HuftProduct: data?.links?.map((lin) => ({
            ProductHandle: lin?.title,
            id: lin?.id,
          })),
        },
      };

    case 'exclusivelyForYou':
      return {
        ExclusivelyForYou: {
          Id: data?.id,
          Title: data?.title,
          SubTitle: data?.subTitle,
          ImageHeight: data.ImageHeight,
          ImageWidth: data.ImageWidth,
          Link: data?.links?.map((lin) => ({
            Image: lin?.imageData,
            ObjectType: lin?.objectType,
            Title: lin?.title,
            SubTitle: lin?.subTitle,
            Id: lin?.id,
            Value: lin?.value,
          })),
        },
      };

    case 'banner':
      return {
        Banner: {
          Link: {
            Id: data?.id,
            Title: data?.title,
            Value: data?.value,
            ImageHeight: data.ImageHeight,
            ImageWidth: data.ImageWidth,
            ObjectType: data?.objectType,
            Image: data?.imageData,
          },
        },
      };

    case 'dealOfTheDay':
      return {
        DealOfTheDay: {
          Link: {
            Id: data?.id,
            Title: data?.title,
            Value: data?.value,
            ImageHeight: data.ImageHeight,
            ImageWidth: data.ImageWidth,
            ObjectType: data?.objectType,
            Image: data?.imageData,
          },
        },
      };

    case 'bankOffers':
      return {
        BankOffers: {
          Id: data.id,
          Title: data.title,
          SubTitle: data.subTitle,
          ImageHeight: data.ImageHeight,
          ImageWidth: data.ImageWidth,
          Link: data.links.map((lin) => ({
            Image: lin.imageData,
            ObjectType: lin.objectType,
            Title: lin.title,
            Value: lin.value,
            Id: lin.id,
          })),
        },
      };
    default:
      return null;
  }
};
