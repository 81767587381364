import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { configureStore } from './redux/store';
import reportWebVitals from './reportWebVitals';

const App = React.lazy(() => import(/* webpackChunkName: "App" */ './App'));

const { REACT_APP_SHOPIFY_BASE_URL, REACT_APP_SHOPIFY_API_TOKEN } = process.env;

const client = new ApolloClient({
  headers: {
    'X-Shopify-Storefront-Access-Token': REACT_APP_SHOPIFY_API_TOKEN,
  },
  uri: REACT_APP_SHOPIFY_BASE_URL,
  cache: new InMemoryCache(),
});

const store = configureStore();

const Main = () => {
  return (
    <Provider store={store}>
      <ApolloProvider client={client}>
        <Suspense fallback={<div className="loading" />}>
          <App />
        </Suspense>
      </ApolloProvider>
    </Provider>
  );
};

ReactDOM.render(<Main />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
