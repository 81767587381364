import {
  GET_COLLECTIONS,
  GET_COLLECTIONS_ERROR,
  GET_COLLECTIONS_SUCCESS,
  GET_UPLOADED_FILES,
  GET_UPLOADED_FILES_ERROR,
  GET_UPLOADED_FILES_SUCCESS,
  SET_CONFIRM_MODAL_FIELDS,
  SET_UPLOAD_MODAL_FIELDS,
  UPLOAD_IMAGE,
  UPLOAD_IMAGE_ERROR,
  UPLOAD_IMAGE_SUCCESS,
} from '../actions';

const INIT_STATE = {
  confirmModal: {},
  uploadModal: {},
  loading: false,
  error: false,
  files: [],
  file: {},
  collections: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_CONFIRM_MODAL_FIELDS:
      if (action.payload.reset)
        return {
          ...state,
          confirmModal: {},
        };

      return {
        ...state,
        confirmModal: {
          ...state.confirmModal,
          ...action.payload,
        },
      };

    case SET_UPLOAD_MODAL_FIELDS:
      if (action.payload.reset)
        return {
          ...state,
          uploadModal: {},
        };

      return {
        ...state,
        uploadModal: {
          ...state.uploadModal,
          ...action.payload,
        },
      };

    case UPLOAD_IMAGE:
      return { ...state, loading: true };

    case UPLOAD_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        file: action.payload,
        files: [...action.payload, ...state.files],
      };

    case UPLOAD_IMAGE_ERROR:
      return { ...state, loading: false, error: action.payload };

    case GET_UPLOADED_FILES:
      return { ...state, loading: true };

    case GET_UPLOADED_FILES_SUCCESS:
      return { ...state, loading: false, files: [...action.payload] };

    case GET_UPLOADED_FILES_ERROR:
      return { ...state, loading: false, error: true };

    case GET_COLLECTIONS:
      return { ...state, loading: true };

    case GET_COLLECTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        collections: [...action.payload],
      };

    case GET_COLLECTIONS_ERROR:
      return { ...state, loading: false, error: true };

    default:
      return { ...state };
  }
};
