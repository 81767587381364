import { NotificationManager } from 'components/common/react-notifications';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import configuredAxios, {
  accessRefreshToken,
  getAuthorizationHeader,
} from 'utils/Api';
import axios from 'utils/CmsApi';
import {
  GET_DOG_GROOMING,
  PUBLISH_DOG_GROOMING,
  UPDATE_DOG_GROOMING,
} from '../actions';
import {
  getDogGroomingError,
  getDogGroomingSuccess,
  publishDogGroomingRequest,
  publishDogGroomingSuccess,
  publishDogGroomingError,
  updateDogGroomingError,
  updateDogGroomingSuccess,
} from './action';
import { formatDogGrooming, getDogGroomingParams } from './selector';
import { validateAndGetFormattedDogUpdateParams } from './validator';

/**
 * Calls the API to getDogGroomingRequest request.
 *
 * @async
 * @function
 * @returns {Promise} The response from the API call.
 */
export const getDogGroomingRequest = async () => {
  accessRefreshToken();
  const response = await axios.get('/dog-treat', getDogGroomingParams());
  return response;
};

/**
 * Calls the API to updateDogGroomingRequest.
 *
 * @async
 * @function
 * @returns {Promise} The response from the API call.
 */
const updateDogGroomingRequest = async (data) => {
  accessRefreshToken();
  const body = await validateAndGetFormattedDogUpdateParams(data);
  const response = await axios.put('/dog-treat', {
    data: body,
  });
  return response;
};

/**
 * Calls the API to publishDogGroomingDataRequest.
 *
 * @async
 * @function
 * @returns {Promise} The response from the API call.
 */
const publishDogGroomingDataRequest = async (data) => {
  const response = await configuredAxios.post('/pet/DogGrooming', data, {
    headers: {
      Authorization: getAuthorizationHeader(),
    },
  });
  return response;
};

/**
 * Calls the API to fetchDogGrooming request.
 *
 * @generator
 * @function
 * @returns {Promise} The response from the API call.
 */

function* fetchDogGrooming() {
  try {
    const response = yield call(getDogGroomingRequest);
    if (response.data.data) {
      yield put(
        getDogGroomingSuccess(formatDogGrooming(response.data.data.attributes))
      );
    } else {
      yield put(getDogGroomingError('no data received'));
    }
  } catch (error) {
    yield put(getDogGroomingError(error));
  }
}

/**
 * Calls the API to publishDogGrooming request.
 *
 * @generator
 * @function
 * @returns {Promise} The response from the API call.
 */
function* publishDogGrooming(data) {
  yield put(publishDogGroomingRequest());
  try {
    const response = yield call(publishDogGroomingDataRequest, data.payload);
    if (response) {
      NotificationManager.success(
        'Data Published successfully',
        'Data Published'
      );
      yield put(publishDogGroomingSuccess(response));
    } else {
      NotificationManager.error('Failed to Publish data', 'Data Published');
      yield put(publishDogGroomingError('no response received'));
    }
  } catch (error) {
    if (error.name === 'ValidationError') {
      NotificationManager.error(error.message, 'Data Updated');
    } else {
      NotificationManager.error('Failed to Published data', 'Data Published');
    }
    yield put(publishDogGroomingError(error));
  }
}

/**
 * Calls the API to updateDogGrooming request.
 *
 * @generator
 * @function
 * @returns {Promise} The response from the API call.
 */
function* updateDogGrooming(data) {
  try {
    const response = yield call(updateDogGroomingRequest, data.payload);
    if (response) {
      NotificationManager.success('Data updated successfully', 'Data Updated');
      yield put(updateDogGroomingSuccess(response));
    } else {
      NotificationManager.error('Failed to update data', 'Data Updated');
      yield put(updateDogGroomingError('no response received'));
    }
  } catch (error) {
    if (error.name === 'ValidationError') {
      NotificationManager.error(error.message, 'Data Updated');
    } else {
      NotificationManager.error('Failed to update data', 'Data Updated');
    }
    yield put(updateDogGroomingError(error));
  }
}

export function* watchDogGrooming() {
  yield takeLatest(GET_DOG_GROOMING, fetchDogGrooming);
}

export function* watchUpdateDogGrooming() {
  yield takeLatest(UPDATE_DOG_GROOMING, updateDogGrooming);
}

export function* watchPublishDogGrooming() {
  yield takeLatest(PUBLISH_DOG_GROOMING, publishDogGrooming);
}

/**
 * Root Saga that combines all sagas.
 *
 * @generator
 * @function
 */
export default function* rootSaga() {
  yield all([
    fork(watchDogGrooming),
    fork(watchUpdateDogGrooming),
    fork(watchPublishDogGrooming),
  ]);
}
