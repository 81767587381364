import { NotificationManager } from 'components/common/react-notifications';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import configuredAxios, {
  accessRefreshToken,
  getAuthorizationHeader,
} from 'utils/Api';
import axios from 'utils/CmsApi';
import {
  GET_SMALL_ANIMAL_DATA,
  PUBLISH_SMALL_ANIMAL_DATA,
  UPDATE_SMALL_ANIMAL_DATA,
} from '../actions';
import {
  getSmallAnimalDataError,
  getSmallAnimalDataSuccess,
  publishSmallAnimalDataError,
  publishSmallAnimalDataSuccess,
  updateSmallAnimalDataError,
  updateSmallAnimalDataSuccess,
  publishSmallAnimalRequest,
} from './action';
import { formatSmallAnimalData, getSmallAnimalParams } from './selector';
import { validateAndGetFormattedSmallAnimalsUpdateParams } from './validator';

/**
 * Calls the API to getSmallAnimalListRequest request.
 *
 * @async
 * @function
 * @returns {Promise} The response from the API call.
 */
export const getSmallAnimalListRequest = async () => {
  accessRefreshToken();
  const response = await axios.get(
    '/home-page-small-animal',
    getSmallAnimalParams()
  );
  return response;
};

/**
 * Calls the API to publishSmallAnimalDataRequest request.
 *
 * @async
 * @function
 * @returns {Promise} The response from the API call.
 */
const publishSmallAnimalDataRequest = async (data) => {
  const response = await configuredAxios.post(
    '/pet/homePageSmallAnimal',
    data,
    {
      headers: {
        Authorization: getAuthorizationHeader(),
      },
    }
  );
  return response;
};

/**
 * Calls the API to updateSmallAnimalDataRequest request.
 *
 * @async
 * @function
 * @returns {Promise} The response from the API call.
 */
const updateSmallAnimalDataRequest = async (data) => {
  accessRefreshToken();
  const body = await validateAndGetFormattedSmallAnimalsUpdateParams(data);
  const response = await axios.put('/home-page-small-animal', {
    data: body,
  });
  return response;
};

/**
 * Calls the API to fetchSmallAnimalData request.
 *
 * @generator
 * @function
 * @returns {Promise} The response from the API call.
 */
function* fetchSmallAnimalData() {
  try {
    const response = yield call(getSmallAnimalListRequest);
    if (response.data.data) {
      yield put(
        getSmallAnimalDataSuccess(
          formatSmallAnimalData(response.data.data.attributes)
        )
      );
    } else {
      yield put(getSmallAnimalDataError('no data received'));
    }
  } catch (error) {
    yield put(getSmallAnimalDataError(error));
  }
}

/**
 * Calls the API to publishSmallAnimalData request.
 *
 * @generator
 * @function
 * @returns {Promise} The response from the API call.
 */
function* publishSmallAnimalData(data) {
  yield put(publishSmallAnimalRequest());
  try {
    const response = yield call(publishSmallAnimalDataRequest, data.payload);
    if (response) {
      NotificationManager.success(
        'Data Published successfully',
        'Data Published'
      );
      yield put(publishSmallAnimalDataSuccess(response));
    } else {
      NotificationManager.error('Failed to Publish data', 'Data Published');
      yield put(publishSmallAnimalDataError('no response received'));
    }
  } catch (error) {
    if (error.name === 'ValidationError') {
      NotificationManager.error(error.message, 'Data Updated');
    } else {
      NotificationManager.error('Failed to Published data', 'Data Published');
    }
    yield put(publishSmallAnimalDataError(error));
  }
}

// call update api
/**
 * Calls the API to getCatListRequest request.
 *
 * @generator
 * @function
 * @returns {Promise} The response from the API call.
 */
function* updateSmallAnimalData(data) {
  try {
    const response = yield call(updateSmallAnimalDataRequest, data.payload);
    if (response) {
      NotificationManager.success('Data updated successfully', 'Data Updated');
      yield put(updateSmallAnimalDataSuccess(response));
    } else {
      NotificationManager.error('Failed to update data', 'Data Updated');
      yield put(updateSmallAnimalDataError('no response received'));
    }
  } catch (error) {
    if (error.name === 'ValidationError') {
      NotificationManager.error(error.message, 'Data Updated');
    } else {
      NotificationManager.error('Failed to update data', 'Data Updated');
    }
    yield put(updateSmallAnimalDataError(error));
  }
}

export function* watchSmallAnimalData() {
  yield takeLatest(GET_SMALL_ANIMAL_DATA, fetchSmallAnimalData);
}

export function* watchUpdateSmallAnimalData() {
  yield takeLatest(UPDATE_SMALL_ANIMAL_DATA, updateSmallAnimalData);
}

export function* watchPublishSmallAnimalData() {
  yield takeLatest(PUBLISH_SMALL_ANIMAL_DATA, publishSmallAnimalData);
}

/**
 * Root Saga that combines all sagas.
 *
 * @generator
 * @function
 */

export default function* rootSaga() {
  yield all([
    fork(watchSmallAnimalData),
    fork(watchUpdateSmallAnimalData),
    fork(watchPublishSmallAnimalData),
  ]);
}
