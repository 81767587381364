// get api params
export const getDogGroomingParams = () => ({
  params: {
    populate:
      'Banner.Image,DogGrooming.CategoryLink.Image,DogGroomingSlider.Sliders.Image,Shampoos.ShampoosLink.Image,GroomingEssentialBanner.Links.Image,GroomingSlider.Link.Image,BestSeller.Link.Image,ShopByConcernBanner.Image,ShopByConcern.Link.Image,MostLovedBanner.Links.Image,MostLovedBrand.Link.Image,ProductsInFocus.Link.Image,DeliveryType.Link.Image',
  },
});

// format all get api data
export const formatDogGrooming = (data) => {
  return {
    vuid: {
      vuid: data.vuid,
    },

    Banner: {
      id: data.Banner && data.Banner.id,
      title: data.Banner && data.Banner.Title,
      value: data.Banner && data.Banner.Value,
      ImageHeight: data.Banner && data.Banner.ImageHeight,
      ImageWidth: data.Banner && data.Banner.ImageWidth,
      objectType: data.Banner && data.Banner.ObjectType,
      imageData: data.Banner && data.Banner.Image.data,
    },

    DogGrooming: {
      id: data.DogGrooming && data.DogGrooming.id,
      title: data.DogGrooming && data.DogGrooming.CategoryLink.Title,
      value: data.DogGrooming && data.DogGrooming.CategoryLink.Value,
      objectType: data.DogGrooming && data.DogGrooming.CategoryLink.ObjectType,
      imageData:
        data.DogGrooming.CategoryLink.Image.data &&
        data.DogGrooming.CategoryLink.Image.data[0],
      ImageHeight: data.DogGrooming && data.DogGrooming.ImageHeight,
      ImageWidth: data.DogGrooming && data.DogGrooming.ImageWidth,
    },
    DogGroomingSlider: {
      id: data.DogGroomingSlider && data.DogGroomingSlider.id,
      title: data.DogGroomingSlider && data.DogGroomingSlider.Title,
      subTitle: data.DogGroomingSlider && data.DogGroomingSlider.SubTitle,
      links:
        data.DogGroomingSlider.Sliders &&
        data.DogGroomingSlider.Sliders.map((lin) => ({
          imageData: lin.Image.data && lin.Image.data[0],
          objectType: lin.ObjectType,
          title: lin.Title,
          value: lin.Value,
          id: lin.id,
        })),
    },

    Shampoos: {
      id: data.Shampoos && data.Shampoos.id,
      title: data.Shampoos && data.Shampoos.Title,
      subTitle: data.Shampoos && data.Shampoos.SubTitle,
      ImageHeight: data.Shampoos && data.Shampoos.ImageHeight,
      ImageWidth: data.Shampoos && data.Shampoos.ImageWidth,
      links:
        data.Shampoos &&
        data.Shampoos.ShampoosLink.map((lin) => ({
          imageData: lin.Image.data && lin.Image.data[0],
          objectType: lin.ObjectType,
          title: lin.Title,
          value: lin.Value,
          id: lin.id,
        })),
    },
    GroomingEssentialBanner: {
      id: data.GroomingEssentialBanner && data.GroomingEssentialBanner.Links.id,
      title:
        data.GroomingEssentialBanner &&
        data.GroomingEssentialBanner.Links.Title,
      objectType:
        data.GroomingEssentialBanner &&
        data.GroomingEssentialBanner.Links.ObjectType,
      value:
        data.GroomingEssentialBanner &&
        data.GroomingEssentialBanner.Links.Value,
      imageData:
        data.GroomingEssentialBanner.Links.Image.data &&
        data.GroomingEssentialBanner.Links.Image.data[0],
      ImageHeight:
        data.GroomingEssentialBanner &&
        data.GroomingEssentialBanner.ImageHeight,
      ImageWidth:
        data.GroomingEssentialBanner && data.GroomingEssentialBanner.ImageWidth,
    },
    GroomingSlider: {
      id: data.GroomingSlider && data.GroomingSlider.id,
      title: data.GroomingSlider && data.GroomingSlider.Title,
      subTitle: data.GroomingSlider && data.GroomingSlider.SubTitle,
      links:
        data.GroomingSlider &&
        data.GroomingSlider.Link.map((lin) => ({
          imageData: lin.Image.data && lin.Image.data[0],
          objectType: lin.ObjectType,
          title: lin.Title,
          value: lin.Value,
          id: lin.id,
        })),
    },
    BestSeller: {
      ImageHeight: data.BestSeller && data.BestSeller.ImageHeight,
      ImageWidth: data.BestSeller && data.BestSeller.ImageWidth,
      title: data.BestSeller && data.BestSeller.Title,
      subTitle: data.BestSeller && data.BestSeller.SubTitle,
      links:
        data.BestSeller &&
        data.BestSeller.Link.map((lin) => ({
          imageData: lin.Image.data,
          objectType: lin.ObjectType,
          title: lin.Title,
          value: lin.Value,
          id: lin.id,
        })),
    },
    ShopByConcernBanner: {
      id: data.ShopByConcernBanner && data.ShopByConcernBanner.id,
      title: data.ShopByConcernBanner && data.ShopByConcernBanner.Title,
      value: data.ShopByConcernBanner && data.ShopByConcernBanner.Value,
      ImageHeight:
        data.ShopByConcernBanner && data.ShopByConcernBanner.ImageHeight,
      ImageWidth:
        data.ShopByConcernBanner && data.ShopByConcernBanner.ImageWidth,
      objectType:
        data.ShopByConcernBanner && data.ShopByConcernBanner.ObjectType,
      imageData:
        data.ShopByConcernBanner.Image.data &&
        data.ShopByConcernBanner.Image.data[0],
    },
    ShopByConcern: {
      id: data.ShopByConcern && data.ShopByConcern.id,
      title: data.ShopByConcern && data.ShopByConcern.Title,
      subTitle: data.ShopByConcern && data.ShopByConcern.SubTitle,
      links:
        data.ShopByConcern &&
        data.ShopByConcern.Link.map((lin) => ({
          title: lin.Title,
          description: lin.Description,
          id: lin.id,
        })),
    },
    MostLovedBanner: {
      id: data.MostLovedBanner && data.MostLovedBanner.Links.id,
      title: data.MostLovedBanner && data.MostLovedBanner.Links.Title,
      value: data.MostLovedBanner && data.MostLovedBanner.Links.Value,
      ImageHeight: data.MostLovedBanner && data.MostLovedBanner.ImageHeight,
      ImageWidth: data.MostLovedBanner && data.MostLovedBanner.ImageWidth,
      objectType: data.MostLovedBanner && data.MostLovedBanner.Links.ObjectType,
      imageData:
        data.MostLovedBanner.Links.Image.data &&
        data.MostLovedBanner.Links.Image.data[0],
    },
    MostLovedBrand: {
      id: data.MostLovedBrand && data.MostLovedBrand.id,
      title: data.MostLovedBrand && data.MostLovedBrand.Title,
      subTitle: data.MostLovedBrand && data.MostLovedBrand.SubTitle,
      // objectType:
      //   data.MostLovedBrand.Link && data.MostLovedBrand.Link.ObjectType,

      // imageData:
      //   data.MostLovedBrand.Link && data.MostLovedBrand.Link.Image.data[0],
      links:
        data.MostLovedBrand &&
        data.MostLovedBrand.Link.map((lin) => ({
          imageData: lin.Image.data && lin.Image.data[0],
          objectType: lin.ObjectType,
          title: lin.Title,
          value: lin.Value,
          id: lin.id,
        })),
    },
    ProductsInFocus: {
      id: data.ProductsInFocus && data.ProductsInFocus.id,
      title: data.ProductsInFocus && data.ProductsInFocus.Title,
      subTitle: data.ProductsInFocus && data.ProductsInFocus.SubTitle,

      links:
        data.ProductsInFocus &&
        data.ProductsInFocus.Link.map((lin) => ({
          imageData: lin.Image.data && lin.Image.data[0],
          objectType: lin.ObjectType,
          title: lin.Title,
          value: lin.Value,
          id: lin.id,
        })),
    },

    DeliveryType: {
      id: data.DeliveryType && data.DeliveryType.id,
      title: data.DeliveryType && data.DeliveryType.Title,
      subTitle: data.DeliveryType && data.DeliveryType.SubTitle,
      links:
        data.DeliveryType &&
        data.DeliveryType.Link.map((lin) => ({
          imageData: lin.Image.data,
          objectType: lin.ObjectType,
          title: lin.Title,
          value: lin.Value,
          id: lin.id,
        })),
    },
  };
};

export const getFormattedDogUpdateParams = ({ data, dataKey }) => {
  switch (dataKey) {
    case 'Banner':
      return {
        Banner: {
          id: data.id,
          ImageHeight: data.ImageHeight,
          ImageWidth: data.ImageWidth,
          Title: data.title,
          Value: data.value,
          ObjectType: data.objectType,
          Image: data.imageData,
        },
      };

    case 'DogGrooming':
      return {
        DogGrooming: {
          id: data.id,
          ImageHeight: data.ImageHeight,
          ImageWidth: data.ImageWidth,
          CategoryLink: {
            Id: data.id,
            Title: data.title,
            Value: data.value,
            ObjectType: data.objectType,
            Image: data.imageData,
          },
        },
      };

    case 'DogGroomingSlider':
      return {
        DogGroomingSlider: {
          Id: data.id,
          Title: data.title,
          SubTitle: data.subTitle,
          Sliders: data.links.map((lin) => ({
            ObjectType: lin.objectType,
            Title: lin.title,
            Value: lin.value,
            Id: lin.id,
            Image: lin.imageData,
          })),
        },
      };

    case 'Shampoos':
      return {
        Shampoos: {
          Id: data.id,
          Title: data.title,
          SubTitle: data.subTitle,
          ShampoosLink: data.links.map((lin) => ({
            ObjectType: lin.objectType,
            Title: lin.title,
            Value: lin.value,
            Id: lin.id,
            Image: lin.imageData,
          })),
        },
      };

    case 'GroomingEssentialBanner':
      return {
        GroomingEssentialBanner: {
          Id: data.id,
          ImageHeight: data.ImageHeight,
          ImageWidth: data.ImageWidth,
          Links: {
            Id: data.id,
            Title: data.title,
            Value: data.value,
            ObjectType: data.objectType,
            Image: data.imageData,
          },
        },
      };

    case 'GroomingSlider':
      return {
        GroomingSlider: {
          Id: data.id,
          SectionTitle: data.title,
          SectionSubTitle: data.subTitle,
          ImageHeight: data.ImageHeight,
          ImageWidth: data.ImageWidth,
          Link: data.links.map((lin) => ({
            Image: lin.imageData,
            ObjectType: lin.objectType,
            Title: lin.title,
            Value: lin.value,
            Id: lin.id,
          })),
        },
      };

    case 'BestSeller':
      return {
        BestSeller: {
          Id: data.id,
          SectionTitle: data.title,
          ImageHeight: data.ImageHeight,
          ImageWidth: data.ImageWidth,
          SubTitle: data.subTitle,
          Link: data.links.map((lin) => ({
            Image: lin.imageData,
            ObjectType: lin.objectType,
            Title: lin.title,
            Value: lin.value,
            Id: lin.id,
          })),
        },
      };

    case 'ShopByConcernBanner':
      return {
        ShopByConcernBanner: {
          id: data.id,
          ImageHeight: data.ImageHeight,
          ImageWidth: data.ImageWidth,
          Title: data.title,
          Value: data.value,
          ObjectType: data.objectType,
          Image: data.imageData,
        },
      };

    case 'ShopByConcern':
      return {
        ShopByConcern: {
          Id: data.id,
          Title: data.title,
          SubTitle: data.subTitle,
          Link: data.links.map((lin) => ({
            Title: lin.title,
            Description: lin.description,
            Id: lin.id,
          })),
        },
      };

    case 'MostLovedBanner':
      return {
        MostLovedBanner: {
          Id: data.id,
          ImageHeight: data.ImageHeight,
          ImageWidth: data.ImageWidth,
          Links: {
            Id: data.id,
            Title: data.title,
            Value: data.value,
            ObjectType: data.objectType,
            Image: data.imageData,
          },
        },
      };

    case 'MostLovedBrand':
      return {
        MostLovedBrand: {
          Id: data.id,
          Title: data.title,
          ImageHeight: data.ImageHeight,
          ImageWidth: data.ImageWidth,
          SubTitle: data.subTitle,
          Link: data.links.map((lin) => ({
            Image: lin.imageData,
            ObjectType: lin.objectType,
            Title: lin.title,
            Value: lin.value,
            Id: lin.id,
          })),
        },
      };

    case 'ProductsInFocus':
      return {
        ProductsInFocus: {
          Id: data.id,
          Title: data.title,
          ImageHeight: data.ImageHeight,
          ImageWidth: data.ImageWidth,
          SubTitle: data.subTitle,
          Link: data.links.map((lin) => ({
            Image: lin.imageData,
            ObjectType: lin.objectType,
            Title: lin.title,
            Value: lin.value,
            Id: lin.id,
          })),
        },
      };

    case 'DeliveryType':
      return {
        DeliveryType: {
          Id: data.id,
          Title: data.title,
          ImageHeight: data.ImageHeight,
          ImageWidth: data.ImageWidth,
          SubTitle: data.subTitle,
          Link: data.links.map((lin) => ({
            Image: lin.imageData,
            ObjectType: lin.objectType,
            Title: lin.title,
            Value: lin.value,
            Id: lin.id,
          })),
        },
      };

    default:
      return null;
  }
};
