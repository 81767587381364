// format current user
export const formatCurrentUser = (data) => ({
  email: data.email,
  id: data.id,
  name: data.name || `${data.firstName} ${data.lastName}`,
  pincode: data.pincode,
  role: data.role,
  uuid: data.uuid,
  countryCode: data.countryCode,
});

export const formatToken = (data) => ({
  data,
});
