import { NotificationManager } from 'components/common/react-notifications';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import configuredAxios, {
  accessRefreshToken,
  getAuthorizationHeader,
} from 'utils/Api';
import axios from 'utils/CmsApi';
import { GET_DOG_TREAT, PUBLISH_DOG_TREAT, UPDATE_DOG_TREAT } from '../actions';
import {
  getDogTreatError,
  getDogTreatSuccess,
  publishDogTreatRequest,
  publishDogTreatSuccess,
  publishDogTreatError,
  updateDogTreatError,
  updateDogTreatSuccess,
} from './action';
import { formatDogData, getDogTreatParams } from './selector';
import { validateAndGetFormattedDogUpdateParams } from './validator';

/**
 * Calls the API to get AllDogTreat request.
 *
 * @async
 * @function
 * @returns {Promise} The response from the API call.
 */
export const getDogTreatRequest = async () => {
  accessRefreshToken();
  const response = await axios.get('/all-dog-treat', getDogTreatParams());
  return response;
};

/**
 * Calls the API to update Dog Treat request.
 *
 * @async
 * @function
 * @param {Object} data - The data to be updated.
 * @returns {Promise} The response from the API call.
 */

const updateDogTreatRequest = async (data) => {
  accessRefreshToken();
  const body = await validateAndGetFormattedDogUpdateParams(data);
  const response = await axios.put('/all-dog-treat', {
    data: body,
  });
  return response;
};

/**
 * Saga function to publish Dog Treat.
 *
 * @generator
 * @function
 * @param {Object} data - The data to be published.
 */
const publishDogRequest = async (data) => {
  const response = await configuredAxios.post('/pet/AllDogTreats', data, {
    headers: {
      Authorization: getAuthorizationHeader(),
    },
  });
  return response;
};

/**
 * Calls the API to fetchDogTreat request.
 *
 * @generator
 * @function
 * @returns {Promise} The response from the API call.
 */

function* fetchDogTreat() {
  try {
    const response = yield call(getDogTreatRequest);
    if (response.data.data) {
      yield put(
        getDogTreatSuccess(formatDogData(response.data.data.attributes))
      );
    } else {
      yield put(getDogTreatError('no data received'));
    }
  } catch (error) {
    yield put(getDogTreatError(error));
  }
}

/**
 * Saga function to publish Dog Treat.
 *
 * @generator
 * @function
 * @param {Object} data - The data to be published.
 */
function* publishDogTreat(data) {
  yield put(publishDogTreatRequest());
  try {
    const response = yield call(publishDogRequest, data.payload);
    if (response) {
      NotificationManager.success(
        'Data Published successfully',
        'Data Published'
      );
      yield put(publishDogTreatSuccess(response));
    } else {
      NotificationManager.error('Failed to Publish data', 'Data Published');
      yield put(publishDogTreatError('no response received'));
    }
  } catch (error) {
    if (error.name === 'ValidationError') {
      NotificationManager.error(error.message, 'Data Updated');
    } else {
      NotificationManager.error('Failed to Published data', 'Data Published');
    }
    yield put(publishDogTreatError(error));
  }
}

/**
 * Saga function to update Dog Treat data.
 *
 * @generator
 * @function
 * @param {Object} data - The data to be updated.
 */
function* updateDogTreat(data) {
  try {
    const response = yield call(updateDogTreatRequest, data.payload);
    if (response) {
      NotificationManager.success('Data updated successfully', 'Data Updated');
      yield put(updateDogTreatSuccess(response));
    } else {
      NotificationManager.error('Failed to update data', 'Data Updated');
      yield put(updateDogTreatError('no response received'));
    }
  } catch (error) {
    if (error.name === 'ValidationError') {
      NotificationManager.error(error.message, 'Data Updated');
    } else {
      NotificationManager.error('Failed to update data', 'Data Updated');
    }
    yield put(updateDogTreatError(error));
  }
}

export function* watchDogTreat() {
  yield takeLatest(GET_DOG_TREAT, fetchDogTreat);
}

export function* watchUpdateDogTreat() {
  yield takeLatest(UPDATE_DOG_TREAT, updateDogTreat);
}

export function* watchPublishDogTreat() {
  yield takeLatest(PUBLISH_DOG_TREAT, publishDogTreat);
}

/**
 * Root Saga that combines all sagas.
 *
 * @generator
 * @function
 */
export default function* rootSaga() {
  yield all([
    fork(watchDogTreat),
    fork(watchUpdateDogTreat),
    fork(watchPublishDogTreat),
  ]);
}
