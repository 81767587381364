// format courier list data
export const formatCourierListData = (data = []) =>
  data.map((d) => ({
    title: d.attributes.title,
    cpId: d.attributes.cpId,
    allowTracking: d.attributes.allowTracking,
    id: d.id,
  }));

// format create courier data
export const formattedCreateCourierApiReq = (data) => ({
  data: {
    title: data.title,
    cpId: data.cpId,
    allowTracking: data.allowTracking,
  },
});

// format update courier data
export const formattedUpdateCourierApiReq = (data) => ({
  data: {
    title: data.title,
    cpId: data.cpId,
    allowTracking: data.allowTracking,
  },
});
