import {
  DELETE_COURIER,
  DELETE_COURIER_ERROR,
  DELETE_COURIER_SUCCESS,
  SET_NEW_COURIER_FIELD,
  SET_COURIER_FIELD,
  SET_COURIER_MODAL,
  UPDATE_COURIER,
  UPDATE_COURIER_ERROR,
  UPDATE_COURIER_SUCCESS,
  COURIER_GET_LIST,
  COURIER_GET_LIST_ERROR,
  COURIER_GET_LIST_SUCCESS,
  PUBLISH_COURIER_REQUEST,
  PUBLISH_COURIER_DATA_SUCCESS,
  PUBLISH_COURIER_DATA_ERROR,
  CREATE_COURIER,
  CREATE_COURIER_ERROR,
  CREATE_COURIER_SUCCESS,
} from '../actions';

const INIT_STATE = {
  users: [],
  loading: false,
  showCourierModal: false,
  newCourier: {
    title: '',
    cpId: '',
    allowTracking: false,
  },
  error: '',
  isPublishing: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    // COURIER_GET_LIST
    case COURIER_GET_LIST:
      return { ...state, loading: true };

    case COURIER_GET_LIST_SUCCESS:
      return { ...state, loading: false, users: action.payload };

    case COURIER_GET_LIST_ERROR:
      return { ...state, loading: false, error: action.payload };

    // SET_COURIER_MODAL data
    case SET_COURIER_MODAL:
      return { ...state, showCourierModal: action.payload };

    case SET_NEW_COURIER_FIELD:
      return {
        ...state,
        newCourier: { ...state.newCourier, ...action.payload },
      };

    case SET_COURIER_FIELD:
      return { ...state, ...action.payload };

    // DELETE COURIER DATA
    case DELETE_COURIER:
      return { ...state, loading: true };

    case DELETE_COURIER_SUCCESS:
      return { ...state, loading: false };

    case DELETE_COURIER_ERROR:
      return { ...state, loading: false, error: action.payload };

    // UPDATE_COURIER DATA
    case UPDATE_COURIER:
      return { ...state, loading: true };

    case UPDATE_COURIER_SUCCESS:
      return { ...state, loading: false, newCourier: {} };

    case UPDATE_COURIER_ERROR:
      return { ...state, loading: false, error: action.payload };

    // publish all data on app
    case PUBLISH_COURIER_REQUEST:
      return {
        ...state,
        isPublishing: true,
        error: false,
      };
    case PUBLISH_COURIER_DATA_SUCCESS:
      return {
        ...state,
        isPublishing: false,
        error: false,
      };

    case PUBLISH_COURIER_DATA_ERROR:
      return {
        ...state,
        isPublishing: false,
        error: true,
      };

    // create courier data
    case CREATE_COURIER:
      return { ...state, loading: true };

    case CREATE_COURIER_SUCCESS:
      return { ...state, newCourier: {}, loading: false };

    case CREATE_COURIER_ERROR:
      return { ...state, loading: false, error: action.payload };

    default:
      return { ...state };
  }
};
