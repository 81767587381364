import {
  GET_CAT_DATA,
  GET_CAT_DATA_ERROR,
  GET_CAT_DATA_SUCCESS,
  UPDATE_CAT_DATA,
  UPDATE_CAT_DATA_ERROR,
  UPDATE_CAT_DATA_SUCCESS,
  PUBLISH_CAT_DATA_ERROR,
  PUBLISH_CAT_DATA_SUCCESS,
  PUBLISH_CAT_REQUEST,
} from '../actions';

const INIT_STATE = {
  cats: {},
  versionData: {},
  singleVerticleBanner: {},
  carouselBannerImages: {},
  swipeSectionImages: {},
  dealsOfDay: {},
  subscriptions: {},
  bannerScrollSection: {},
  bankOffers: {},
  spaBookingBanner: {},
  brandCarousel: {},
  combosAndKits: {},
  ecoConscious: {},
  locateStore: {},
  foundationBanner: {},
  shopByConcern: {},
  bestFoodBrands: {},
  joinPuppyClub: {},
  knowAboutYourPet: {},
  petBirthday: {},
  giftCards: {},
  loading: false,
  error: '',
  updateSuccess: false,
  isPublishing: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    // GET_CAT_DATA
    case GET_CAT_DATA:
      return { ...state, loading: true, updateSuccess: false };

    case GET_CAT_DATA_ERROR:
      return { ...state, loading: false, error: action.payload };

    case GET_CAT_DATA_SUCCESS:
      return { ...state, ...action.payload };

    // UPDATE_CAT_DATA
    case UPDATE_CAT_DATA:
      return { ...state, loading: true, updateSuccess: false };

    case UPDATE_CAT_DATA_SUCCESS:
      return { ...state, loading: false, error: false, updateSuccess: true };

    case UPDATE_CAT_DATA_ERROR:
      return { ...state, loading: false, error: true, updateSuccess: false };

    // PUBLISH_CAT_REQUEST
    case PUBLISH_CAT_REQUEST:
      return {
        ...state,
        isPublishing: true,
        error: false,
      };

    case PUBLISH_CAT_DATA_SUCCESS:
      return {
        ...state,
        isPublishing: false,
        error: false,
      };

    case PUBLISH_CAT_DATA_ERROR:
      return {
        ...state,
        isPublishing: false,
        error: true,
      };

    default:
      return { ...state };
  }
};
