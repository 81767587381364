import {
  GET_REWARD_DATA,
  GET_REWARD_DATA_ERROR,
  GET_REWARD_DATA_SUCCESS,
  UPDATE_REWARD_DATA,
  UPDATE_REWARD_DATA_SUCCESS,
  UPDATE_REWARD_DATA_ERROR,
  PUBLISH_REWARD_DATA,
  PUBLISH_REWARD_DATA_REQUEST,
  PUBLISH_REWARD_DATA_SUCCESS,
  PUBLISH_REWARD_DATA_ERROR,
} from 'redux/actions';

export const getRewardData = () => ({
  type: GET_REWARD_DATA,
});

export const getRewardDataError = (payload) => ({
  type: GET_REWARD_DATA_ERROR,
  payload,
});

export const getRewardDataSuccess = (payload) => ({
  type: GET_REWARD_DATA_SUCCESS,
  payload,
});

// UPDATE_DOG_TREAT

export const updateRewardData = (payload) => ({
  type: UPDATE_REWARD_DATA,
  payload,
});

export const updateRewardDataSuccess = (payload) => ({
  type: UPDATE_REWARD_DATA_SUCCESS,
  payload,
});

export const updateRewardDataError = (payload) => ({
  type: UPDATE_REWARD_DATA_ERROR,
  payload,
});

// PUBLISH_DOG_TREAT

export const publishRewardData = (payload) => ({
  type: PUBLISH_REWARD_DATA,
  payload,
});

export const publishRewardDataRequest = (payload) => ({
  type: PUBLISH_REWARD_DATA_REQUEST,
  payload,
});

export const publishRewardDataSuccess = (payload) => ({
  type: PUBLISH_REWARD_DATA_SUCCESS,
  payload,
});

export const publishRewardDataError = (payload) => ({
  type: PUBLISH_REWARD_DATA_ERROR,
  payload,
});
