import {
  GET_OFFERS_DATA,
  GET_OFFERS_DATA_ERROR,
  GET_OFFERS_DATA_SUCCESS,
  UPDATE_OFFERS_DATA,
  UPDATE_OFFERS_DATA_ERROR,
  UPDATE_OFFERS_DATA_SUCCESS,
  PUBLISH_OFFERS_DATA,
  PUBLISH_OFFERS_DATA_SUCCESS,
  PUBLISH_OFFERS_DATA_ERROR,
  PUBLISH_OFFERS_REQUEST,
} from 'redux/actions';

// GET_OFFERS_DATA
export const getOffersData = () => {
  return {
    type: GET_OFFERS_DATA,
  };
};

export const getOffersDataError = (payload) => ({
  type: GET_OFFERS_DATA_ERROR,
  payload,
});

export const getOffersDataSuccess = (payload) => ({
  type: GET_OFFERS_DATA_SUCCESS,
  payload,
});

// UPDATE_OFFERS_DATA
export const updateOffersData = (payload) => ({
  type: UPDATE_OFFERS_DATA,
  payload,
});
export const updateOffersDataSuccess = (payload) => ({
  type: UPDATE_OFFERS_DATA_SUCCESS,
  payload,
});

export const updateOffersDataError = (payload) => ({
  type: UPDATE_OFFERS_DATA_ERROR,
  payload,
});

// PUBLISH OFFERS DATA
export const publishOffersRequest = (payload) => ({
  type: PUBLISH_OFFERS_REQUEST,
  payload,
});

export const publishOffersData = (payload) => ({
  type: PUBLISH_OFFERS_DATA,
  payload,
});

export const publishOffersDataSuccess = (payload) => ({
  type: PUBLISH_OFFERS_DATA_SUCCESS,
  payload,
});

export const publishOffersDataError = (payload) => ({
  type: PUBLISH_OFFERS_DATA_ERROR,
  payload,
});
